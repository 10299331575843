import request from '@/utils/request'

//首页基础概况产品库总量
export function ProductCount () {
    return request({
        url: '/product/product/selectProductCount',
        method: 'get'
    })
}

//一天产品量
export function CountToDay () {
    return request({
        url: '/product/product/selectCountToDay',
        method: 'get'
    })
}

//7天产品量
export function CountWeek () {
    return request({
        url: '/product/product/selectCountWeek',
        method: 'get'
    })
}

//当前用户总数
export function CountUser () {
    return request({
        url: '/product/product/selectCountUser',
        method: 'get'
    })
}

// 未授权
export function UnauthorizedCount () {
    return request({
        url: '/device/device/selectUnauthorizedCount',
        method: 'get'
    })
}

//已授权
export function AuthorizedCount () {
    return request({
        url: '/device/device/selectAuthorizedCount',
        method: 'get'
    })
}

//已停用
export function OffStreamCount () {
    return request({
        url: '/device/device/selectOffStreamCount',
        method: 'get'
    })
}

//本周产品量
export function CountThisWeek () {
    return request({
        url: '/product/product/selectCountThisWeek',
        method: 'get'
    })
}

//本月产品量
export function CountMonth () {
    return request({
        url: '/product/product/selectCountMonth',
        method: 'get'
    })
}

//本周产品每一天数据
export function CountThisWeekEveryDay () {
    return request({
        url: '/product/product/selectCountThisWeekEveryDay',
        method: 'get'
    })
}

//本月产品每一天数据
export function CountMonthEveryDay () {
    return request({
        url: '/product/product/selectCountMonthEveryDay',
        method: 'get'
    })
}

//近三十天新增产品数量
export function CountProThirtyDay (params) {
    return request({
        url: '/product/product/getCountProDay',
        method: 'get',
        data:params
    })
}

//已停用
export function occupiedCount () {
    return request({
        url: '/device/device/selectOccupiedCount',
        method: 'get'
    })
}

//工厂统计数据
export function getCountFactoryPro (params) {
    return request({
        url: '/factory/factory/getCountFactoryPro',
        method: 'get',
        data:params
    })
}

//人员统计数据
export function getCountPersonnel (params) {
    return request({
        url: '/factory/factory/getCountPersonnel',
        method: 'get',
        data:params
    })
}

/* eslint-disable vue/valid-v-bind */
<template>
  <div class="app-product-container">
    <div class="main">
      <div style="font-size:16px;color:#000000;padding:20px 15px 15px 35px;font-weight: 500;border-bottom:1px solid #F0F0F0;margin-bottom:15px">混粉组合需求</div>
      <div class="c_main" style="border:none">
        <el-form :inline="true" size="small" :rules="dataRule" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
          <el-form-item label="L*" class="lab" prop="lAve">
            <el-input v-model="dataForm.lAve" placeholder="L*" class="w100 mr2" size="small" clearable maxlength="30" oninput="value=value.replace(/[^\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-'); 
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"></el-input>
          </el-form-item>
          <el-form-item label="a*" class="lab" prop="aAve">
            <el-input v-model="dataForm.aAve" placeholder="a*" class="w100 mr2" size="small" clearable maxlength="30" oninput="value=value.replace(/[^\-\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-'); 
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"></el-input>
          </el-form-item>   
          <el-form-item label="b*" class="lab" prop="bAve">
            <el-input v-model="dataForm.bAve" placeholder="b*" class="w100 mr2" size="small" clearable maxlength="30" oninput="value=value.replace(/[^\-\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-'); 
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"></el-input>
          </el-form-item>         
          <el-form-item label="订单号" prop="sampleOrderNo">
            <el-input v-model="dataForm.sampleOrderNo" placeholder="订单号" size="small" clearable maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="光泽值" prop="glossValue">
            <el-input v-model="dataForm.glossValue" placeholder="光泽值" size="small" clearable maxlength="30" @input="selectGlossInfo()"></el-input>
          </el-form-item>
          <el-form-item label="需求量" prop="productNum">
            <el-input v-model="dataForm.productNum" placeholder="需求量（kg）" size="small" clearable maxlength="30" oninput="value=value.replace(/[^\d.]/g,'');if(value.startsWith('-00')){value = '-0'};if(value.startsWith('00')){value = 0} if(value.startsWith('-')){var a=value.split('-'); if(a.length>=2){value = '-' + a[1]}} else {var a=value.split('-'); 
              if(a.length>=2){value = a[0]}}; var a=value.split('.'); if(a[0]=='-'){value = '-'} else if(!a[0]){value = ''} else if(a.length>=2){value=a[0]+'.'+a[1]} else {value=a[0]}"></el-input>
          </el-form-item>
          <el-form-item label="产品工厂" prop="factoryId">
            <el-select v-model="dataForm.factoryId" size="small" placeholder="请选择工厂">
              <!-- <el-option label="全部" value="-1"></el-option> -->
              <el-option v-for="item in factoryList" :key="item.id" :label="item.factoryName" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品系列" prop="productSeries">
            <el-select v-model="dataForm.productSeries" size="small" placeholder="请选择系列">
              <!-- <el-option label="全部" value="-1"></el-option> -->
              <el-option v-for="item in productSeriesList" :key="item.id" :label="item.name" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品分类" prop="productCategory">
            <el-select v-model="dataForm.productCategory" size="small" placeholder="请选择产品分类">
              <!-- <el-option label="全部" value="-1"></el-option> -->
              <el-option v-for="item in productCategoryList" :key="item.id" :label="item.name" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品表面" prop="productSurface">
            <el-select v-model="dataForm.productSurface" size="small" placeholder="请选择产品表面">
              <!-- <el-option label="全部" value="-1"></el-option> -->
              <el-option v-for="item in productSurfaceList" :key="item.id" :label="item.name" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库存">
            <el-select v-model="dataForm.stoke" size="small" placeholder="请选择">
              <el-option label="全部" value="-1"></el-option>
              <el-option label="无" value="0"></el-option>
              <el-option label="有" value="1"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="产品行业" prop="productIndustry">
            <el-select v-model="dataForm.productIndustry" size="small" placeholder="请选择产品行业">
              <el-option label="全部" value="-1"></el-option>
              <el-option v-for="item in productIndustryList" :key="item.id" :label="item.name" :value="item.id" size="small"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button @click="restPage()" size="small" :disabled="dataListLoading">获取推荐</el-button>
            <el-button @click="reset('dataForm')"  size="small">清空</el-button>
            <el-button type="primary" @click="exportMixPowderHandle()" size="small">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <div style="font-size:16px;color:#000000;padding:20px 15px 15px 35px;font-weight: 500;border-bottom:1px solid #F0F0F0;margin-bottom:15px">混粉组合推荐{{':' + datasize}}</div>
      <div class="c_m_cards" v-loading="dataListLoading" v-if="dataList.length > 0" >
        <!-- <el-carousel arrow="always" :autoplay="false" indicator-position="outside" style="width:100%;height: 500px;">
          <el-carousel-item v-for="(items, indexs) in dataList" :key="indexs" style="width:100%;height: 450px;">
            <div class="c_m_cards"> -->
            <el-card :body-style="{ padding: '0px' }" v-for="(item, index) in dataList" :key="index">
              <div class="c_m_c_bot">
                <div class="c_m_c_b_txt">
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-top: 2px solid #D2D2D2;border-bottom: 2px solid #D2D2D2;height:34px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <!-- <p size="small">{{index+1+indexs*3}}</p> -->
                      <p size="small">{{index+1}}</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <div style="text-align:center;">
                        <div :style="{backgroundColor:(mixPowderProduct.hex),height:'30px',minWidth:'60px'}"></div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">产品号</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.productNo}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">L*</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.l}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">a*</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.a}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">b*</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.b}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">光泽</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.glossValue}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">产品表面</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.productSurface}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">库存</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                      <p size="small">{{mixPowderProduct.productNum + "KG"}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;background-color: #e1e8e5;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;height:30px;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;">
                      <p size="small" style="font-weight: bold;">比例</p>
                    </el-col>
                    <el-col v-for="(mixPowderProduct, mindex) in item.mixPowderProduct" :key="mindex" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;height:30px;font-size: 12px;color: #6F73FF;">
                      <p size="small" style="font-weight: bold;">{{mixPowderProduct.rate}}</p>
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;background-color: #e1e8e5;">
                    <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;height:30px;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;">
                      <p size="small" style="font-weight: bold;">色差</p>
                    </el-col>
                    <el-col :span="(3 == item.mixPowderProduct.length? 18 : 20)" style="border-right: 2px solid #D2D2D2;height:30px;font-size: 12px;color: #6F73FF;">
                      <p size="small" style="font-weight: bold;">{{item.del2000}}</p>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
            <!-- </div>
          </el-carousel-item>
        </el-carousel> -->
      </div>
      <div class="c_m_cards" v-if="dataList.length==0 && dataListLoading == false">
        <p style=" margin: 10px auto;font-size: 14px;color: #999;display: flex;align-items: center;">暂无数据，确认输入查询参数，获取更多数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import { factoryList } from '@/api/modules/factory';
import { getProductAttrValue } from '@/api/modules/productattrvalue';
import { getToken } from "@/utils/auth";
import {searchMixPowder} from '@/api/modules/mixpowder';
export default {

  data() {
    return {
      dataListLoading: false,
      dataForm: {
        brandId: "",
        factoryId: "",
        glossValue: "",
        productNum: "",
        sampleOrderNo: "",
        sampleOrderId: "",
        productSurface: "",
        productCategory: "",
        productSeries: "",
        productIndustry: "",
        stoke: "",
        lAve: "",
        aAve: "",
        bAve: "",
      },
      mixPowder: {},
      dataRule: {
          factoryId: [
            { required: true, message: '请选择工厂', trigger: 'blur' }
          ],
          glossValue: [
            { required: true, message: '请填写光泽值', trigger: 'blur' }
          ],
          sampleOrderNo: [
            { required: true, message: '请填写订单号', trigger: 'blur' }
          ],
          productSurface: [
            { required: true, message: '请选择表面', trigger: 'blur' }
          ],
          productCategory: [
            { required: true, message: '请选择分类', trigger: 'blur' }
          ],
          productSeries: [
            { required: true, message: '请选择系列', trigger: 'blur' }
          ],
          lAve: [
            { required: true, message: 'L不能为空', trigger: 'blur' }
          ],
          aAve: [
            { required: true, message: 'A不能为空', trigger: 'blur' }
          ],
          bAve: [
            { required: true, message: 'B不能为空', trigger: 'blur' }
          ]
        },
      factoryList: [],
      productSurfaceList: [],
      productCategoryList: [],
      productSeriesList: [],
      productIndustryList: [],
      dataList: [],
      datasize: ''
    };
  },
  components: {
  },
  computed:{
  },
  created() {
    this.getProductAttrValue();
    this.getFactoryList();
    // this.dataForm.factoryId = "e69ec4b75c5e440c89a30de9f6fcad62";
    // this.dataForm.productCategory = "53a6905853b54d549351b643840412e5";
    // this.dataForm.productSurface = "00e9abe968394e20bab0fb24c98f02fe";
    // this.dataForm.productSeries = "e8906dc4e71641d8aa991ca61542e393";
    // this.dataForm.productIndustry = "49cd8c3713754c248b695ef647c112e5";
    // this.dataForm.glossValue = "30";
    // this.dataForm.productNum = "10";
    // this.dataForm.sampleOrderNo = "6208806";
    // this.dataForm.lAve = "21.2";
    // this.dataForm.aAve = "2.2";
    // this.dataForm.bAve = "1.4";
    this.dataForm.factoryId = "";
    this.dataForm.productCategory = "";
    this.dataForm.productSurface = "";
    this.dataForm.productSeries = "";
    this.dataForm.productIndustry = "";
    this.dataForm.glossValue = "";
    this.dataForm.productNum = "";
    this.dataForm.sampleOrderNo = "";
    this.dataForm.lAve = "";
    this.dataForm.aAve = "";
    this.dataForm.bAve = "";
    this.dataForm.stoke = "-1";
    this.datasize = "0";
    this.dataList = [];
    this.mixPowderRoute();
  },
  activated () {
    this.getProductAttrValue();
    this.getFactoryList();
    this.initDom();
    this.dataForm.factoryId = "";
    this.dataForm.productCategory = "";
    this.dataForm.productSurface = "";
    this.dataForm.productSeries = "";
    this.dataForm.productIndustry = "";
    this.dataForm.glossValue = "";
    this.dataForm.productNum = "";
    this.dataForm.sampleOrderNo = "";
    this.dataForm.lAve = "";
    this.dataForm.aAve = "";
    this.dataForm.bAve = "";
    this.dataForm.stoke = "-1";
    this.datasize = "0";
    this.dataList = [];
    this.mixPowderRoute();
  },
  // mounted() {
  //   this.initDom();
  //   this.mixPowderRoute();
  // },
  methods: {
    initDom() {
      if (this.$refs.ccccc) {
        let $div = this.$refs.c_top
        let width = $div.style.width || $div.clientWidth || $div.offsetWidth || $div.scrollWid
        this.$refs.ccccc.style.width = (width / 4) + "px"
        if ((width / 4) < 300) {
          this.$refs.ccccc.style.width = "100px"
          this.$refs.ccccc.style.height = "47px"
        }
      }
    },
    getFactoryList() {
        let params = {
          brandId: this.dataForm.brandId,
        };
        factoryList(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.factoryList = data.data;
            //this.dataForm.factoryId = '-1';
            if(data.data.length == 1){
              this.dataForm.factoryId = data.data[0].id;
            }
          } else {
            this.factoryList = [];
          }
        });
    },
    //根据光泽测试值显示光泽范围名称
    selectGlossInfo() {
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g,'') // 清除“数字”和“.”以外的字符
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
      this.dataForm.glossValue = this.dataForm.glossValue.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      this.dataForm.glossValue = this.dataForm.glossValue.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3');//只能输入两个小数   
      if (this.dataForm.glossValue.indexOf(".") < 0 && this.dataForm.glossValue != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
        this.dataForm.glossValue = parseFloat(this.dataForm.glossValue);
      }
    },
    getProductAttrValue() {
      let params = {};
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {//表面
              this.productSurfaceList = data.list[i].productAttrValue;
            } else if (data.list[i].productAttrType === '3') {//系列
              this.productSeriesList = data.list[i].productAttrValue;
            } else if (data.list[i].productAttrType === '4') {//应用
              this.productIndustryList = data.list[i].productAttrValue;
            } else if (data.list[i].productAttrType === '5') {//分类
              this.productCategoryList = data.list[i].productAttrValue;
            }
          }
        }
      })
    },

    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.searchMixPowderList();
    },

    reset(dataForm) {
      this.$nextTick(()=>{
        this.$refs.dataForm.resetFields();
      })
      this.getProductAttrValue();
      this.getFactoryList();
      this.dataForm.factoryId = "";
      this.dataForm.productCategory = "";
      this.dataForm.productSurface = "";
      this.dataForm.productSeries = "";
      this.dataForm.productIndustry = "";
      this.dataForm.glossValue = "";
      this.dataForm.productNum = "";
      this.dataForm.sampleOrderNo = "";
      this.dataForm.lAve = "";
      this.dataForm.aAve = "";
      this.dataForm.bAve = "";
      this.dataForm.stoke = "-1";
      this.dataList = [];
    },
    // isProductCategory() {
    //   if (this.dataForm.productCategory == "" || this.dataForm.productCategory == null) {
    //     this.$message.error("请选择产品分类");
    //   } else {
    //     this.searchMixPowderList();
    //   }
    // },
    // 获取数据列表
    searchMixPowderList() {
      this.dataListLoading = true;
      this.dataList = [];

      var params = {
        factoryId: this.dataForm.factoryId,
        glossValue: this.dataForm.glossValue,
        productNum: this.dataForm.productNum,
        sampleOrderNo: this.dataForm.sampleOrderNo,
        sampleOrderId: this.dataForm.sampleOrderId,
        l: this.dataForm.lAve,
        a: this.dataForm.aAve,
        b: this.dataForm.bAve,
        productSeries: this.dataForm.productSeries,
        productIndustry: this.dataForm.productIndustry,
        productCategory: this.dataForm.productCategory,
        productSurface: this.dataForm.productSurface,
        stoke:this.dataForm.stoke,
      };
      // if (!(this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve)) {
      //   this.dataList = [];
      //   this.$message.error("请输入查询参数");
      //   this.dataListLoading = false;
      //   return;
      // }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {  
          searchMixPowder(params).then(({data}) => {
              if (data && data.code === 0) {
                this.datasize = data.mixPowder.mixPowderCombination.length;
                // var Width = document.body.clientWidth
                // console.log(Width)
                // if (Width >= 1600) {
                //   this.dataList = this.formatArray(data.mixPowder.mixPowderCombination, 3);
                // } else if (1200 <= Width < 1600) {
                //   this.dataList = this.formatArray(data.mixPowder.mixPowderCombination, 2);
                // } else {
                //   this.dataList = this.formatArray(data.mixPowder.mixPowderCombination, 1);
                // }
                this.dataList = data.mixPowder.mixPowderCombination;
                this.mixPowder = data.mixPowder;
              } else {
                if(null != data.msg){
                  this.$message.error(data.msg);
                } else {
                  this.$message.error("未获取到推荐数据");
                }
                
                this.dataList = [];
                this.datasize = "0";
              }
              this.dataListLoading = false;
          })
        } else {
          this.dataListLoading = false;
        }
      })
      
    },
    formatArray(data, num) {
      console.log(num)
      var dataList2 = []
      for (var i = 0, len = data.length; i < len; i += num) {
        dataList2.push(data.slice(i, i + num))
      }

      return dataList2
    },
    //混粉跳转
    mixPowderRoute() {
      //产品工厂；产品表面；产品分类；产品光泽；lab平均值
      if(this.$route.query.factoryId){
        this.dataForm.factoryId = this.$route.query.factoryId;
      }
      if(this.$route.query.glossValue){
        this.dataForm.glossValue = this.$route.query.glossValue;
      }
      if(this.$route.query.productNum){
        this.dataForm.productNum = this.$route.query.productNum;
      }
      if(this.$route.query.productSeries){
        this.dataForm.productSeries = this.$route.query.productSeries;
      }
      if(this.$route.query.productIndustry){
        this.dataForm.productIndustry = this.$route.query.productIndustry;
      }
      if(this.$route.query.productSurface){
        this.dataForm.productSurface = this.$route.query.productSurface;
      }
      if(this.$route.query.productCategory){
        this.dataForm.productCategory = this.$route.query.productCategory;
      }
      if(this.$route.query.sampleOrderNo){
        this.dataForm.sampleOrderNo = this.$route.query.sampleOrderNo;
      }
      if(this.$route.query.lAve){
        this.dataForm.lAve = this.$route.query.lAve;
      }
      if(this.$route.query.aAve){
        this.dataForm.aAve = this.$route.query.aAve;
      }
      if(this.$route.query.bAve){
        this.dataForm.bAve = this.$route.query.bAve;
      }
      if(this.$route.query.hasProductNum){
        if("1" == this.$route.query.hasProductNum){
          this.dataForm.stoke = "0";
        } else if("0" == this.$route.query.hasProductNum){
          this.dataForm.stoke = "1";
        } else {
          this.dataForm.stoke = "-1";
        }
      }
    },
    //导出比色结果excel
		exportMixPowderHandle(){
      if (null != this.dataList && this.dataList.length != 0) {  
        var url = `${process.env.VUE_APP_BASE_API}mixpowder/mixpowder/exportMixPowderInfo?token=${getToken()}`
          + `&powderId=`+ this.mixPowder.id;
        // 模拟创建元素实现
        var alink = document.createElement('a');
        alink.href = url;
        alink.style = 'display:none';
        alink.click();
      } else {
        this.$message.error("没有推荐数据导出");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-product-container {
  padding: 15px;
  background-color: #eaf1fc;
  position: relative;
}

.main {
  background: #fff;
}
.c_top {
  padding-bottom: 50px;
  border-bottom: 1px dotted #e0e3ec;
}
.c_main {
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
}

.content {
  background: #fff;
  padding: 5px;
}
.c_m_cards {
  justify-content: flex-start;
  // padding: 7.5px;
}
.c_m_c_img {
  padding: 10px;
  border-radius: 15px;
}
.c_t_i_l_top .el-input__inner {
  border: 0;
  border-radius: 0;
  min-width: 128px;
  height: 37px;
  background: rgba(245, 245, 245, 0.6);
  text-align: center;
}
.c_m_cards .el-card {
  width: 450px;
  /*height: 240px;*/
  margin-left: 19px;
  margin-bottom: 20px;
}
.c_top .el-color-picker__trigger .el-icon-arrow-down:before {
  content: url("../../../src/assets/img/seBan2.png") !important;
  font-size: 18px;
}
.c_top .el-icon-close:before {
  content: url("../../../src/assets/img/seBan2.png") !important;
  font-size: 18px;
}
.c_top .el-color-picker__trigger {
  border: none;
  padding: 0;
  margin: -10px;
  height: 40px;
  width: 38px;
  background-color: cornflowerblue;
}
.c_top .el-color-picker__color {
  border: none !important;
}
.el-color-dropdown__btns button {
  display: none !important;
}
.el-input-group__prepend {
  background-color: #e4e4e4;
  color: #303133;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 0px solid #dcdfe6;
  border-radius: 0px;
  padding: 0 20px;
  width: 0px;
  white-space: nowrap;
  height: 38px;
}
.w100 {
  width: 90px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.c_top {
  text-align: center;
}
.c_t_title {
  font-size: 28px;
  color: #666666;
  margin-bottom: 10px;
}
.c_t_title span {
  color: #009cff;
}

.c_t_i_l_top {
  display: flex;
  justify-content: center;
  background: rgba(245, 245, 245, 0.6);
  height: 40px;
  border: 1px #e5e5e5 solid;
  align-items: center;
}
.c_t_i_l_top .color {
  width: 23px;
  height: 23px;
}
.c_tips {
  color: #999999;
  font-size: 12px;
}
.btn {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}
.btn-input {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}
.c_t_colorcontent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  padding: 14px;
  margin: 10px 0 10px 0px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.c_t_colorcontent p {
  margin: 0;
}
.c_t_colorcontent img {
  width: 28px;
  height: 28px;
}
.c_m_c_img {
  width: 100%;
  height: 160px;
}
.c_m_c_img_{
  width: calc(100% - 20px);
  height: 140px;
  margin: 10px;
  border-radius: 5px;
}
.c_m_cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 350px;
}
.c_m_c_bot {
  color: #333333;
  font-size: 15px;
  padding: 10px 10px;
}
.c_m_c_b_txt {
  font-size: 12px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.c_m_c_b_txt span {
  color: #999999;
  margin-right: 17px;
  width: 60px;
  display: inline-block;
}
</style>
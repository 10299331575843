<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="混粉单id" prop="powderId">
        <el-input v-model="dataForm.powderId" placeholder="混粉单id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="混粉单编号" prop="powderNo">
        <el-input v-model="dataForm.powderNo" placeholder="混粉单编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="推荐组合id" prop="combinationId">
        <el-input v-model="dataForm.combinationId" placeholder="推荐组合id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="推荐组合编号" prop="combinationNo">
        <el-input v-model="dataForm.combinationNo" placeholder="推荐组合编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="组合产品编号" prop="productNo">
        <el-input v-model="dataForm.productNo" placeholder="组合产品编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="L" prop="l">
        <el-input v-model="dataForm.l" placeholder="L"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="A" prop="a">
        <el-input v-model="dataForm.a" placeholder="A"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="B" prop="b">
        <el-input v-model="dataForm.b" placeholder="B"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="光泽值" prop="glossValue">
        <el-input v-model="dataForm.glossValue" placeholder="光泽值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="工厂id" prop="factortId">
        <el-input v-model="dataForm.factortId" placeholder="工厂id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="表面" prop="productSurface">
        <el-input v-model="dataForm.productSurface" placeholder="表面"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="库存" prop="productIndustry">
        <el-input v-model="dataForm.productIndustry" placeholder="库存"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="比例" prop="rate">
        <el-input v-model="dataForm.rate" placeholder="比例"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否删除（0.未删除，1.删除）" prop="delFlag">
        <el-input v-model="dataForm.delFlag" placeholder="是否删除（0.未删除，1.删除）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateDate">
        <el-input v-model="dataForm.updateDate" placeholder=""  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/mixpowderproduct'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          powderId: '',
          powderNo: '',
          combinationId: '',
          combinationNo: '',
          productNo: '',
          l: '',
          a: '',
          b: '',
          glossValue: '',
          factortId: '',
          productSurface: '',
          productIndustry: '',
          rate: '',
          sort: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: ''
        },
        dataRule: {
          powderId: [
            { required: true, message: '混粉单id不能为空', trigger: 'blur' }
          ],
          powderNo: [
            { required: true, message: '混粉单编号不能为空', trigger: 'blur' }
          ],
          combinationId: [
            { required: true, message: '推荐组合id不能为空', trigger: 'blur' }
          ],
          combinationNo: [
            { required: true, message: '推荐组合编号不能为空', trigger: 'blur' }
          ],
          productNo: [
            { required: true, message: '组合产品编号不能为空', trigger: 'blur' }
          ],
          l: [
            { required: true, message: 'L不能为空', trigger: 'blur' }
          ],
          a: [
            { required: true, message: 'A不能为空', trigger: 'blur' }
          ],
          b: [
            { required: true, message: 'B不能为空', trigger: 'blur' }
          ],
          glossValue: [
            { required: true, message: '光泽值不能为空', trigger: 'blur' }
          ],
          factortId: [
            { required: true, message: '工厂id不能为空', trigger: 'blur' }
          ],
          productSurface: [
            { required: true, message: '表面不能为空', trigger: 'blur' }
          ],
          productIndustry: [
            { required: true, message: '库存不能为空', trigger: 'blur' }
          ],
          rate: [
            { required: true, message: '比例不能为空', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ],
          delFlag: [
            { required: true, message: '是否删除（0.未删除，1.删除）不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.powderId = data.mixpowderproduct.powderId
                this.dataForm.powderNo = data.mixpowderproduct.powderNo
                this.dataForm.combinationId = data.mixpowderproduct.combinationId
                this.dataForm.combinationNo = data.mixpowderproduct.combinationNo
                this.dataForm.productNo = data.mixpowderproduct.productNo
                this.dataForm.l = data.mixpowderproduct.l
                this.dataForm.a = data.mixpowderproduct.a
                this.dataForm.b = data.mixpowderproduct.b
                this.dataForm.glossValue = data.mixpowderproduct.glossValue
                this.dataForm.factortId = data.mixpowderproduct.factortId
                this.dataForm.productSurface = data.mixpowderproduct.productSurface
                this.dataForm.productIndustry = data.mixpowderproduct.productIndustry
                this.dataForm.rate = data.mixpowderproduct.rate
                this.dataForm.sort = data.mixpowderproduct.sort
                this.dataForm.delFlag = data.mixpowderproduct.delFlag
                this.dataForm.createBy = data.mixpowderproduct.createBy
                this.dataForm.createDate = data.mixpowderproduct.createDate
                this.dataForm.updateBy = data.mixpowderproduct.updateBy
                this.dataForm.updateDate = data.mixpowderproduct.updateDate
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'powderId': this.dataForm.powderId,
              'powderNo': this.dataForm.powderNo,
              'combinationId': this.dataForm.combinationId,
              'combinationNo': this.dataForm.combinationNo,
              'productNo': this.dataForm.productNo,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b,
              'glossValue': this.dataForm.glossValue,
              'factortId': this.dataForm.factortId,
              'productSurface': this.dataForm.productSurface,
              'productIndustry': this.dataForm.productIndustry,
              'rate': this.dataForm.rate,
              'sort': this.dataForm.sort,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

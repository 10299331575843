<template>
  <el-dialog
    :title="!dataForm.id ? '结单' : '结单'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @click="close()"
    append-to-body
  >
    <el-form :model="dataForm"   size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="200px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
        <!-- <el-form-item label="请输入产品号" prop="productNo">
            <el-input v-model="dataForm.productNo" placeholder="产品号" size="small"
                      style="width: 100%" maxlength="30"></el-input>
        </el-form-item> -->
      <el-form-item prop="sampleTypeList" v-for="item in dataForm.sampleTypeList">
            <h3>{{item.sampleType=='0'?'新品打样： '+item.sampleNo :(item.sampleType=='2' ? '底粉打样： '+item.sampleNo :'组份打样： '+item.sampleNo)}}</h3>
            <el-input v-model="item.productNo" placeholder="分配产品编号" size="small"
                style="width: 60%" maxlength="30"></el-input>
      </el-form-item>
    </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
       <!--<span slot="soltFooter" class="dialog-footer">
          <el-button @click="visible =false" size="small">取消</el-button>
          <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
       </span>-->

    </el-dialog>
</template>

<script>
  import {
    queryNoSeries,
    queryNoColor,
    getFactoryBrand,
    selectGloss,
    getDict,
    syncDevice,
    syncDeviceData,
  } from '@/api/modules/product'
  import {info,add,update,findSampleType,findSampleTypeCa} from '@/api/modules/sampleproduct'
  import Cookies from "js-cookie";
  import {getToken} from "@/utils/auth";
  import {sampleproductJD,choose} from '@/api/modules/sampleproduct'
  export default {
    data () {
      return {
          cartegryType:'',
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        loading: false,
        timer: null, //首先我在data函数里面进行定义定时器名称：
        timerNum: 5, // 设置定时器时间
        device:{
          deviceId:''
        },
          sampleTypeList:[],
        dataForm: {
          id: 0,
          productNo: '',
          sampleOrderId: '',
          sampleOrderNo: '',
          picUrl: '',
          qrUrl: '',
          ralColorNo: '',
          gloss: '',
          glossValue: '',
          surface: '',
          factoryId: '',
          userId: '',
          laboratory: '',
          description: '',
          l: '',
          a: '',
          b: '',
          state: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: '',
            sampleTypeList:[],
            sampleType:'',
            orderFactoryId:'',
        },
      }
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    methods: {
        close(){
            this.visible = false
            this.dataForm.sampleTypeList=[];
        },
      init(sampleOrderId,sampleOrderNo) {
          // if(cartegryNameS==='金属粉'){
          //     this.cartegryType='2';
          // }else{
          //     this.cartegryType='1';
          // }
          this.dataForm.sampleTypeList=[];
        this.getProductGlossList();
        this.dataForm.sampleOrderId = sampleOrderId
        this.dataForm.sampleOrderNo = sampleOrderNo
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
            if (this.dataForm.sampleOrderId) {
                this.findSampleType(sampleOrderId);
                sampleproductJD({sampleOrderId: this.dataForm.sampleOrderId}).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataForm.id = data.product.sampleId
                        this.dataForm.productId = data.product.id
                        this.dataForm.productNo = data.product.productNo
                        this.dataForm.productName = "Tiger_"+data.product.productNo
                        this.dataForm.makeFactory = data.product.makeFactory
                        this.dataForm.picUrl = data.product.picUrl
                        this.dataForm.qrUrl = data.product.qrUrl
                        this.dataForm.ralColorNo = data.product.ralColorNo
                        this.dataForm.gloss = data.product.gloss
                        this.dataForm.glossValue = data.product.glossValue
                        this.dataForm.surface = data.product.surface
                        this.dataForm.colorSeries = data.product.colorSeries
                        this.dataForm.productSeries = data.product.productSeries
                        this.dataForm.productPerformance = data.product.productPerformance
                        this.dataForm.otherPerformance = data.product.otherPerformance
                        this.dataForm.productCategory = data.product.productCategory
                        this.dataForm.productHierarchy = data.product.productHierarchy
                        this.dataForm.industryId = data.product.industryId
                        this.dataForm.factoryId = data.product.factoryId
                        this.dataForm.brandId = data.product.brandId
                        this.dataForm.description = data.product.description
                        this.dataForm.l1 = data.product.l1
                        this.dataForm.a1 = data.product.a1
                        this.dataForm.b1 = data.product.b1
                        this.dataForm.l2 = data.product.l2
                        this.dataForm.a2 = data.product.a2
                        this.dataForm.b2 = data.product.b2
                        this.dataForm.l3 = data.product.l3
                        this.dataForm.a3 = data.product.a3
                        this.dataForm.b3 = data.product.b3
                        this.dataForm.lAve = data.product.lAve
                        this.dataForm.aAve = data.product.aAve
                        this.dataForm.bAve = data.product.bAve
                        this.dataForm.test = data.product.test
                        this.dataForm.productOrder = data.product.productOrder
                        this.dataForm.sampleType=data.order.sampleType
                        this.dataForm.orderFactoryId=data.order.factoryId
                        this.dataForm.sampleOrderId=data.order.id
                        this.findSampleType(sampleOrderId,data.product.productNo);
                    }
                })
            }
        })
      },
        findSampleType(sampleOrderId,productNo){
            let params ={
                sampleOrderId:sampleOrderId,
                cartegryType:this.cartegryType,
                productNo:productNo
            }
            findSampleType(params).then(({data})=>{
                if (data && data.code === 0) {

                    this.dataForm.sampleTypeList=data.list;
                    console.log(this.dataForm.sampleTypeList)
                }else{
                    this.$message.error(data.msg);
                }
            })
        },
        // arrRepeat(arr){
        //   var arrStr = JSON.stringify(arr),str;
        //   for (var i = 0; i < arr.length; i++) {
        //     if (arrStr.indexOf(arr[i]) != arrStr.lastIndexOf(arr[i])){
        //       return true;
        //     }
        //   }
        //   return false;
        // },
        arrRepeat(arr) {
          var hash = {};
          for(var i in arr) {
            if(hash[arr[i]]) {
              return true;
            }
            // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
            hash[arr[i]] = true;
          }
          return false;
        },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            const ids = this.dataForm.sampleTypeList.map(value=>value.productNo);
            // console.log(ids,this.arrRepeat(ids))
            if(!this.arrRepeat(ids)){
              this.dataLoading = true;
              var params = {
                  'id': this.dataForm.id,
                  'sampleNo': this.dataForm.productNo,
                  'sampleOrderId': this.dataForm.sampleOrderId,
                  'orderSampleType':this.dataForm.sampleType,
                  'factoryId':this.dataForm.orderFactoryId,
                  'productState':'1',
                  'state':'3',
                  'cartegryType':this.cartegryType,
                  'sampleTypeList':JSON.stringify(this.dataForm.sampleTypeList),

              }
              var tick = !this.dataForm.productNo ? choose(params) : choose(params)
              tick.then(({data}) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                    this.dataLoading = false;
                      this.$emit('refreshDataList')
                    }
                  })
                } else {
                  this.$message.error(data.msg)
                  this.dataLoading = false;
                }
              })
            }else{
              this.$message.error('产品号重复')
            }
          }
        })
      },

      //设备同步
      syncDevice() {
        this.loading = true;
        this.device.deviceId = Cookies.get('deviceId')
        let params ={
          deviceId:this.device.deviceId
        }
        syncDevice(params).then(({data}) => {
          if (data && data.code === 0) {
            this.timer = setInterval(() => {  //创建定时器
              if (this.timerNum === 0) { // 设置的定时器时间为0后执行的操作
                this.clearTimer(); // 关闭定时器
                this.loading = false;
                this.timerNum = 5;
              } else {
                //查看设备状态
                this.syncDeviceTime();
              }
            }, 1000);
          } else {
            this.$message.error(data.msg);
            this.loading = false;
          }
        })
      },
      //获取同步数据
      syncDeviceTime() {
        let params = {
          deviceId: this.device.deviceId
        }
        syncDeviceData(params).then(({data}) => {
          if (data && data.code === 0) {
            //this.dataForm.productNo = data.product.productNo
            this.dataForm.picUrl = data.product.picUrl
            this.dataForm.l1 = data.product.l1
            this.dataForm.a1 = data.product.a1
            this.dataForm.b1 = data.product.b1
            this.dataForm.l2 = data.product.l2
            this.dataForm.a2 = data.product.a2
            this.dataForm.b2 = data.product.b2
            this.dataForm.l3 = data.product.l3
            this.dataForm.a3 = data.product.a3
            this.dataForm.b3 = data.product.b3
            this.dataForm.l = data.product.lAve
            this.dataForm.a = data.product.aAve
            this.dataForm.b = data.product.bAve
            this.timerNum = 0;
          } else {
            this.timerNum -= 1; // 定时器减1
          }
        })
      },
      clearTimer() {//清除定时器
        clearInterval(this.timer);
        this.timer = null;
      },

      //获取产品光泽选项
      getProductGlossList() {
        let params = {
          type: '3'
        }
        getDict(params).then(({data}) => {
          if (data && data.code === 0) {
            this.productGlossList = data.list
          } else {
            this.productGlossList = []
          }
        })
      },

//根据光泽测试值显示光泽范围名称
      getSelectGloss() {
        let params = {
          glossValue: this.dataForm.glossValue
        }
        selectGloss(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm.gloss = data.DictList.dictId;
          } else {
            this.dataForm.gloss = ''
          }
        })
      },
      //上传产品图处理
      handleUploadSuccess(res, file, type) {
        this.dataForm.picUrl = res.data.fileUrl;
      },
      //上传前对图片大小及格式进行判断
      beforeUpload(file) {
        const isJPG = (file.type === "image/jpeg" || file.type === "image/bmp" || file.type === "image/png");
        const isLt2M = file.size / 1024 / 1024 < 8;

        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },

    }
  }
</script>
<style lang="scss">
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-left: 20px;
    }
    .el-upload:hover {
      border-color: #409EFF;
    }
  }
</style>
<style lang="scss" scoped>
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 178px;
    text-align: center;
  }

  .ziti {
    font-size: 12px;
    margin: 0px;
    line-height: 25px;
  }

  .avatar {
    width: 178px;
    max-height: 178px;
    display: block;
  }

  .productLAB {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: -50px;
    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 10px;
      .title {
        display: inline-block;
        width: 100px;
        text-align: center;
        color: #606266;
        font-size: 700;
      }
    }

  }

  .w100 {
    width: 100px;
  }
  .w150 {
    width: 119px;
  }
  .mr10 {
    margin-right: 5px;
  }
  .collapse-title {
    flex: 1 0 90%;
    color: #4AB7BD;
    font-weight: bolder;
    text-align: center;
  }

  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 18px;
  }
</style>

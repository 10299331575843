<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="750px"
    @close="close()"
  >-->
    <sides-lip ref="SidesLip"
               @click="close()"
                >
        <div slot="soltForm">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="130px"
      v-loading="dataLoading"
      style="width:100%;"
      element-loading-text
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="选择产品" prop="productNo">
        <el-input
          style="width:500px"
          v-model="dataForm.productNo"
          placeholder="选择产品"
          size="small"
          @input="getProductInfo"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <!--<div class="productDirs" v-for="x in productInfo">-->
        <div v-show="productInfo!==undefined&&productInfo.length>0" class="productDirs" v-for="(x,index) in productInfo" :key="index">
        <div class="left">
          <p>
            产品名称：
            <span class="fontNormal">{{x.productName}}</span>
          </p>
          <p>
            产品编号：
            <span class="fontNormal">{{x.productNo}}</span>
          </p>
          <p>
            LAB值：
            <span class="fontNormal">{{x.aAve}} {{x.bAve}} {{x.lAve}}</span>
          </p>
        </div>
        <div class="right">
          <el-image v-if="x.picUrl" style="width: 180px; height: 100px" :src="x.picUrl" fit="contain"></el-image>
          <div v-else :style="{backgroundColor:x.hex,height:'100px',width:'100px'}"></div>
        </div>
      </div>

      <!-- 配方材料集合 -->
      <el-form-item label="添加材料" prop="selMaterial" class="mt15">
<div  style="display: flex;" >
  <div >
    <el-select
            v-model="dataForm.selMaterial"
            value-key="materialId"
            placeholder="请选择"
            size="small"
            class="mr10"
    >
      <el-option
              v-for="x in materialList"
              :key="x.materialId"
              :label="x.materialName"
              :value="x"
      ></el-option>
    </el-select>
    <el-button size="small" @click="addMaterial">添加</el-button>
  </div>
  <el-input v-model="dataForm.materialNo" placeholder="请输入材料编号" size="small" @blur="getMaterId()" style="width:40%;margin-left: 10px;" maxlength="30"></el-input>

</div>
        </el-form-item>
      <!--<div  class="materialInfo">-->
        <div v-show="dataForm.recipeMaterial!==undefined&&dataForm.recipeMaterial.length>0" class="materialInfo">
            <el-table :data="dataForm.recipeMaterial"
                      border
                      v-loading="dataListLoading"
                      style="width: 100%;">
                <el-table-column
                        prop="materialNo"
                        header-align="center"
                        align="center"
                        label="材料编号">
                     <template slot-scope="scope">
                        <el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="skipMaterial(scope.row.productId,scope.row.componentId)">{{scope.row.productNo}}{{scope.row.sampleNo}}</el-link>
                        <label v-if="scope.row.productId==null">{{scope.row.materialNo}}</label>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="materialName"
                        header-align="center"
                        align="center"
                        label="原料名称">
                </el-table-column>
                <el-table-column
                        prop="value"
                        header-align="center"
                        align="center"
                        label="计量值">
                  <template slot-scope="scope">
                    <el-input size="small"
                      placeholder="请输入"
                      controls-position="right" 
                      class="inputClass"
                      style="display: initial;width: 100px;"
                      v-model="scope.row.value"
                      maxlength="30"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                        prop="materialUnits"
                        header-align="center"
                        align="center"
                        label="单位">
                </el-table-column>
                <!--<el-table-column-->
                        <!--prop="rate"-->
                        <!--header-align="center"-->
                        <!--align="center"-->
                        <!--label="计量百分比">-->
                <!--</el-table-column>-->
                <el-table-column
                        width="150"
                        fixed="right"
                        header-align="center"
                        align="center"
                        label="操作">
                    <template>
                        <el-button type="text" size="small" @click="del(index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        <!--<ul>-->
          <!--<li v-for="(x,index) in dataForm.recipeMaterial">-->
              <!--<div class="materialName" v-if="x.materialName!==null">材料名称：{{x.materialName}}</div>-->
              <!--<div class="materialName" v-if="x.materialName==null&&x.productNo==null">材料名称：</div>-->
              <!--<div class="materialName" v-if="x.productNo!=null&&x.productNo!=undefined" @click="skipMaterial(x.productId)" >材料名称：<span style="color: #3A71A8">{{x.productNo}}</span></div>-->
            <!--<div>-->
              <!--<span class="mr10">计量：</span>-->
              <!--<el-input-->
                <!--size="small"-->
                <!--placeholder="请输入"-->
                <!--class="mr10"-->
                <!--style="display: initial;"-->
                <!--v-model="x.value"-->
                <!--maxlength="30"-->
              <!--&gt;</el-input>-->
              <!--<span class="mr50">{{x.materialUnits}}</span>-->
                <!--<span class="mr50">{{x.rate}}</span>-->
            <!--</div>-->
            <!--<div>-->
              <!--<el-button type="primary" size="small" class="mr10">称重</el-button>-->
              <!--<el-button size="small" @click="del(index)">删除</el-button>-->
            <!--</div>-->
          <!--</li>-->
        <!--</ul>-->
      </div>
      <el-form-item label="固化条件" prop="solidifyId" class="mt15">
        <el-select v-model="dataForm.solidifyId"  placeholder="请选择" @change="solidifyDD(dataForm.solidifyId)">
          <el-option
            v-for="item in solidifyList"
            :key="item.solidifyId"
            :label="item.solidifyName"
            :value="item.solidifyId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="固化温度" prop="temperature">
        <el-input v-model="dataForm.temperature" placeholder="固化温度" size="small" maxlength="5" style="width:500px">
            <template slot="append">℃</template>
        </el-input>
      </el-form-item>
      <el-form-item label="固化时间" prop="solTime">
         <el-input v-model="dataForm.solTime" placeholder="固化时间" size="small" maxlength="5" style="width:500px">
             <template slot="append">秒</template>
         </el-input>
    <!--</el-date-picker>-->
      </el-form-item>
      <el-form-item label="固化条件说明" prop="solidifyDesc">
        <el-input type="textarea" :rows="5" v-model="dataForm.solidifyDesc"   size="small" maxlength="100"  style="width:500px"></el-input>
      </el-form-item>
    </el-form>
        </div>
        <div slot="soltFooter">
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
    </span>
            <!--材料跳转-->
            <material-recipe ref="materialRecipe"></material-recipe>
        </div>
  <!--</el-dialog>-->

    </sides-lip>
</template>

<script>
import {
    getMaId,
    update,
    add,
    recipeMterialInfo,
    recipeInfo,
  queryMateriaInfo,
  querySolidify,
  queryProduct,
    getSolidifyInFo
} from "@/api/modules/recipe";
import SidesLip from "@/components/SidesLip";
import MaterialRecipe from './materialRecipe'
export default {
    components: {SidesLip,MaterialRecipe},
  data() {
    return {
        dataListLoading:false,
        materialRecipeVisible: false,
      visible: false,
      dataLoading: false,
      dataForm: {
          materialNo:"",
        id: 0,
        productNo: "",
        recipeNo: "",
        productId: "",
        recipeMaterial: [],
        solidifyId: "",
        solTime: "",
        temperature: "",
        solidifyDesc: "",
        selMaterial: {},
          materialId:''
      },
      productInfo: [],
      materialList: [],
      solidifyList: [],
      dataRule: {
          productNo: [
              {required: true, message: '产品编号不能为空', trigger: 'blur'}
          ],
          // solidifyId: [
          //     {required: true, message: '固化条件不能为空', trigger: 'blur'}
          // ],
          // recipeMaterial: [
          //     {required: true, message: '配方材料不能为空', trigger: 'blur'}
          // ],
          // temperature: [
          //     {required: true, message: '固化温度不能为空', trigger: 'blur'}
          // ],
          // solTime: [
          //     {required: true, message: '固化时间不能为空', trigger: 'blur'}
          // ],
          // solidifyDesc: [
          //     {required: true, message: '固化条件说明不能为空', trigger: 'blur'}
          // ],
      }
    };
  },

  mounted() {


  },
  methods: {
      //点击超链接文字，触发跳转页面
      skipMaterial(productId,componentId){
          this.materialRecipeVisible = true
          this.$nextTick(() => {
              this.$refs.materialRecipe.init(productId,componentId)
          })
      },
      solidifyDD(id){
          var params ={
              id:id
          }
          //固化
          getSolidifyInFo(params).then(({ data }) => {
              if (data.code == 0) {
                  this.dataForm.solidifyName = data.solidifyInFo.solidifyName;
                  this.dataForm.temperature = data.solidifyInFo.temperature;
                  this.dataForm.solTime = data.solidifyInFo.solTime;
                  this.dataForm.solidifyDesc = data.solidifyInFo.description;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      //根据编号获取id
      getMaterId(){
          let params = {
              materialNo:this.dataForm.materialNo
          }
          getMaId(params).then(({data}) => {
              if (data && data.code === 0) {
                  if(data.materialInfoVo.materialId){
                      this.dataForm.selMaterial = data.materialInfoVo;
                      this.dataForm.recipeMaterial.push(this.dataForm.selMaterial);
                  }else {
                      this.$message.error("请输入有效材料编号！");
                      return;
                  }

              } else {
                  this.dataForm.selMaterial = {}
              }
          })
      },
      //配方材料
      getQueryMateriaInfo(){
          //材料
          queryMateriaInfo().then(({ data }) => {
              if (data.code === 0) {
                  this.materialList = data.materialList;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      //固化
      getQuerySolidify(){
          //固化
          querySolidify().then(({ data }) => {
              if (data.code == 0) {
                  this.solidifyList = data.solidifyList;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      close(){
          this.productInfo=[];
          this.materialList = [];
          this.dataForm.materialNo = '';
          this.dataForm.selMaterial = {};
          this.dataForm.recipeMaterial = [];
          this.visible = false;
          this.$refs.SidesLip.sideClose();
      },
    init(id) {
        this.productInfo=[];
        this.materialList = [];
        this.dataForm.materialNo = '';
        this.dataForm.selMaterial = {};
        this.dataForm.recipeMaterial = [];
          this.getQueryMateriaInfo();
          this.getQuerySolidify();
          this.dataForm.id = id || 0;
         this.$refs.SidesLip.init(this.dataForm.id?"修改":"新增", "750px", "block");
         this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.$refs.dataForm.recipeMaterial =[];
        if (this.dataForm.id) {
            recipeInfo({ id: this.dataForm.id }).then(({ data }) => { //获去配方详情
                if (data && data.code === 0) {
                    this.dataForm.productNo = data.recipeInfo.productNo;
                    this.dataForm.productName = data.recipeInfo.productName;
                    this.dataForm.solidifyName = data.recipeInfo.solidifyName;
                    this.dataForm.solTime = data.recipeInfo.solTime;
                    this.dataForm.temperature = data.recipeInfo.temperature;
                    this.dataForm.solidifyDesc = data.recipeInfo.solidifyDesc;
                    this.dataForm.productId = data.recipeInfo.productId;
                    var obj={
                        lAve:data.recipeInfo.lAve,
                        aAve:data.recipeInfo.aAve,
                        bAve:data.recipeInfo.bAve,
                        picUrl:data.recipeInfo.picUrl,
                        hex:data.recipeInfo.hex,
                        productNo:data.recipeInfo.productNo,
                        productName:data.recipeInfo.productName,
                    }
                    this.productInfo.push(obj);
                    this.dataForm.lAve = data.recipeInfo.lAve;
                    this.dataForm.aAve = data.recipeInfo.aAve;
                    this.dataForm.bAve = data.recipeInfo.bAve;
                    this.dataForm.picUrl = data.recipeInfo.picUrl;
                    this.dataForm.hex = data.recipeInfo.hex;
                    this.dataForm.solidifyId = data.recipeInfo.solidifyId;
                }
            });
            recipeMterialInfo({ id: this.dataForm.id }).then(({ data }) => { //获取配方材料详情
            if (data && data.code === 0) {
                console.log(data)
              this.dataForm.recipeMaterial = data.recipeMaterialInfo;
              // this.dataForm.value = data.recipeMterialInfo.value;
              // this.dataForm.materialUnits = data.recipeMterialInfo.materialUnits;
            }
          });
        }
      });
    },
    getProductInfo(value) {
      queryProduct({ productNo: value }).then(({ data }) => {
        if (data.code == 0 && data.productList.length > 0) {
          this.dataForm.productId = data.productList[0].productId;
          this.productInfo = data.productList;
        }else{
            this.productInfo = data.productList;
            this.$message.error("查不到产品数据！");
            }
      });
    },
    addMaterial() {
      if (JSON.stringify(this.dataForm.selMaterial) == "{}") {
        this.$message.error("请先选择材料！");
        return;
      }
      let obj = {
        materialNo: this.dataForm.selMaterial.materialNo,
        materialName: this.dataForm.selMaterial.materialName,
        value:'',
        materialUnits: this.dataForm.selMaterial.materialUnits,
        //  productNo:
      };
      //判断配方材料添加重复
      let recipeMaterialList = this.dataForm.recipeMaterial.find(v=>{
          return this.dataForm.selMaterial.materialName == v.materialName
      })
    if (recipeMaterialList){
        this.$message.error("配方材料不能重复添加！");
        return;
    }
      this.dataForm.recipeMaterial.push(obj);
    },
    del(index) {
      this.dataForm.recipeMaterial.splice(index, 1);
    },
    // 表单提交
    dataFormSubmit() {
         // console.log(this.dataForm)
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.dataLoading = true;
          var tick = !this.dataForm.id ? add(this.dataForm) : update(this.dataForm);
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                 // this.visible = false;
                  this.dataLoading = false;
                    this.$refs.SidesLip.sideClose();
                  this.$emit("refreshDataList");
                }
              });
            } else {
              this.$message.error(data.msg);
              this.dataLoading = false;
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.materialInfo {
  .el-input {
    input {
      width: 100px;
    }
  }
}
.el-date-editor.el-input{
  width: 100%;
}
</style>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 30px;
  }
  .right {
    margin-right: 30px;
  }
}
.materialInfo {
  ul {
    margin: 0;
    padding: 10px 20px 0;
    border-bottom: 1px solid #d8d8d8;
  }
  ul li {
    list-style: none;
    margin-left: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .materialName {
      width: 200px;
    }
  }
}
.ml30 {
  margin-left: 30px;
}
.mr50 {
  margin-right: 50px;
}
.mr30 {
  margin-right: 30px;
}
.mr20 {
  margin-right: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mt15 {
  margin-top: 15px;
}
.fontNormal {
  font-weight: normal;
}
</style>

import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/sample/sampleproduct/list',
    method: 'get',
    params: params
  })
}

// 获取列表
export function listuser (params) {
  return request({
    url: '/sample/sampleproduct/listByUser',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/sample/sampleproduct/info',
    method: 'get',
    params: params
  })
}

export function sampleproduct (params) {
  return request({
    url: '/sample/sampleproduct/sampleproduct',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/sample/sampleproduct/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/sample/sampleproduct/update',
    method: 'post',
    data: params
  })
}

// 添加
export function addSample (params) {
  return request({
    url: '/sample/sampleproduct/addSample',
    method: 'post',
    data: params
  })
}

// 修改
export function updateSample (params) {
  return request({
    url: '/sample/sampleproduct/updateSample',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/sample/sampleproduct/delete',
    method: 'post',
    data: params
  })
}

// 转正
export function choose (params) {
  return request({
    url: '/sample/sampleproduct/choose',
    method: 'post',
    data: params
  })
}

// 生成产品打样单
export function saveSampleProductByProduct (params) {
  return request({
    url: '/sample/sampleproduct/saveSampleProductByProduct',
    method: 'post',
    data: params
  })
}

// 生成产品打样单
export function saveNewSample (params) {
  return request({
    url: '/sample/sampleproduct/saveNewSample',
    method: 'post',
    data: params
  })
}

// 获取列表
export function sampleProductList (params) {
  return request({
    url: '/sample/sampleproduct/sampleProductList',
    method: 'get',
    params: params
  })
}

// 获取列表
export function querySampleProductList (params) {
  return request({
    url: '/sample/sampleproduct/querySampleProductList',
    method: 'get',
    params: params
  })
}
//自检操作
export function selfInspection (params) {
  return request({
    url: '/sample/sampleproduct/selfInspection',
    method: 'post',
    data: params
  })
}
// 自检提交操作
export function updateState (ids) {
  return request({
    url: '/sample/sampleproduct/updateState',
    method: 'post',
    data: ids
  })
}

//验证驳回操作
export function updateSampleProoductState (params) {
  return request({
    url: '/sample/sampleproduct/updateSampleState',
    method: 'post',
    data: params
  })
}


export function sampleproductJD (params) {
  return request({
    url: '/sample/sampleproduct/sampleproductJD',
    method: 'get',
    params: params
  })
}

export function sampleproductInfo (params) {
  return request({
    url: '/sample/sampleproduct/sampleproductInfo',
    method: 'get',
    params: params
  })
}


//获取固化详情的操作
export function recipeInfo (params) {
  return request({
    url: '/sample/sampleproduct/recipeInfo',
    method: 'get',
    params: params
  })
}

//获取打样单头部信息
export function orderAndProductInfo (params) {
  return request({
    url: '/sample/sampleproduct/OrderAndProductInfo',
    method: 'get',
    params: params
  })
}

//获取色差值
export function getE00 (params) {
  return request({
    url: '/sample/sampleproduct/getE00',
    method: 'post',
    data: params
  })
}


//获取色差值
export function getE00Info (params) {
  return request({
    url: '/sample/sampleproduct/getE00Info',
    method: 'post',
    data: params
  })
}

//获取色差值
export function cancellation (params) {
  return request({
    url: '/sample/sampleproduct/cancellation',
    method: 'get',
    params: params
  })
}

//获取组份
export function findSampleType (params) {
    return request({
        url: '/sample/sampleproduct/findSampleType',
        method: 'get',
        params: params
    })
}

//获取底粉
export function findSampleTypeCa (params) {
    return request({
        url: '/sample/sampleproduct/findSampleTypeCa',
        method: 'get',
        params: params
    })
}
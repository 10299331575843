<template>
  <div class="app-container">
    <div class="card_div">
      <h3 style="text-align:center;width:100%;">产品数据完整一致性检查</h3>
      <div>
        <h4>产品概况</h4>
        <div class="flex">
          <div style="text-align:center;">
            <p>颜色管理系统产品数</p>
            <h3>{{Survey && (Survey.productCount || Survey.productCount === 0) ? Survey.productCount : '-'}}</h3>
          </div>
          <div style="text-align:center;">
            <p>云平台产品数</p>
            <h3>{{Survey && (Survey.cloudProductCount || Survey.cloudProductCount === 0) ? Survey.cloudProductCount : '-'}}</h3>
          </div>
          <div style="text-align:center;">
            <p>粉底数量</p>
            <h3>{{Survey && (Survey.baseProductCount || Survey.baseProductCount === 0) ? Survey.baseProductCount : '-'}}</h3>
          </div>
          <div style="text-align:center;">
            <p>属性有缺失产品数</p>
            <h3>{{Survey && (Survey.defectAttrProductCount || Survey.defectAttrProductCount === 0) ? Survey.defectAttrProductCount : '-'}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card_div">
      <h4> <span title="颜色管理系统产品属性数据完整性
1.正式产品（不含底粉）和实验室产品（不含底粉）可以同步到云平台，这类产品以及属性称为可同步产品或者可同步属性
2.属性完整度不是100 %的不建议作为小程序筛选条件，需补充缺失产品属性
3.字体黄色表示这个属性作为小程序的筛选条件，属性完整度小于100%
4.字体红色表示这个属性作为小程序的筛选条件，属性完整度小于95%" style="cursor:pointer">产品属性数据完整性<i class="el-icon-question" style="margin-left:5px;font-size:18px;"></i></span>
      <i class="el-icon-refresh-right" style="float:right;margin-right:30px;font-size:18px;" @click="getProductAttrSurvey"></i></h4>
      <el-table
        :data="dataList01"
        border
        size="small"
        v-loading="dataListLoading01"
        :header-cell-style="{background:'#efefef'}"
        :cell-style="timeStyle"
        style="width: 100%">
        <el-table-column
          prop="productAttrName"
          header-align="center"
          align="center"
          label="产品属性">
        </el-table-column>
        <el-table-column
          prop=""
          header-align="center"
          align="center"
          :render-header="renderHeader"
          label="属性数/产品数">
          <template slot-scope="scope">
            <div>{{scope.row.productAttrCount}}/{{scope.row.productCount}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          header-align="center"
          align="center"
          :render-header="renderHeader_"
          label="可同步属性数/可同步产品数">
          <template slot-scope="scope">
            <div>{{scope.row.canSynProductAttrCount}}/{{scope.row.canSynProductCount}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          header-align="center"
          align="center"
          :width="200"
          label="可同步属性完整度">
          <template slot-scope="scope">
            <!-- <div>{{ (scope.row.canSynProductAttrCount || scope.row.canSynProductAttrCount === 0) && (scope.row.canSynProductCount || scope.row.canSynProductCount === 0) ? ((scope.row.canSynProductAttrCount/scope.row.canSynProductCount)*100).toFixed(2) + '%' : '-'}}</div> -->
            <div style="margin:0 20px;">
              <el-progress :text-inside="true" :stroke-width="18" :color="scope.row.canSynProductAttrCount/scope.row.canSynProductCount > 0.95 && scope.row.canSynProductAttrCount/scope.row.canSynProductCount < 1 ? '#e6a23c' : scope.row.canSynProductAttrCount/scope.row.canSynProductCount < 0.95 ? '#f56c6c' :'#67c23a'" :percentage="(scope.row.canSynProductAttrCount || scope.row.canSynProductAttrCount === 0) && (scope.row.canSynProductCount || scope.row.canSynProductCount === 0) ? Math.round(((scope.row.canSynProductAttrCount/scope.row.canSynProductCount)*100)*100)/100 : 0"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="appletShow"
          header-align="center"
          align="center"
          label="是否小程序筛选条件">
          <template slot-scope="scope">
            <div>{{scope.row.appletShow == '1' ? '是' : '否'}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card_div">
      <h4>产品数据一致性
        <!-- <i class="el-icon-question" style="margin-left:5px;font-size:18px;"></i> -->
        <i class="el-icon-refresh-right" style="float:right;margin-right:30px;font-size:18px;" @click="getProductInfoSurvey"></i></h4>
      <el-table
        :data="dataList02"
        border
        size="small"
        v-loading="dataListLoading02"
        :header-cell-style="{background:'#efefef'}"
        style="width: 100%">
        <el-table-column
          prop="productAttrName"
          header-align="center"
          align="center"
          label="产品属性">
        </el-table-column>
        <el-table-column
          prop="productAttrCount"
          header-align="center"
          align="center"
          label="颜色管理系统属性数量">
        </el-table-column>
        <el-table-column
          prop="cloudProductAttrCount"
          header-align="center"
          align="center"
          label="云平台属性数量">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { selectProductInfoSurvey, selectProductAttrSurvey, selectProductSurvey } from "@/api/modules/datastatistics";
export default {
  data() {
    return {
      dataListLoading01: false,
      dataListLoading02: false,
      dataList01:[],
      dataList02:[],
      Survey:{},
    };
  },
  created() {
      this.getProductInfoSurvey();
      this.getProductAttrSurvey();
      this.getProductSurvey();
  },
  methods: {
      timeStyle(column) {
        if( 1 > column.row.canSynProductAttrCount/column.row.canSynProductCount && column.row.canSynProductAttrCount/column.row.canSynProductCount >= 0.95 && column.row.appletShow == '1') {
          return "color: #e6a23c"
        }else if (column.row.canSynProductAttrCount/column.row.canSynProductCount < 0.95 && column.row.appletShow == '1') {
          return "color: #f56c6c"
        }
      },
      getProductInfoSurvey(){
        this.dataListLoading02 = true
          selectProductInfoSurvey({}).then(({data}) => {
            if (data && data.code === 0) {
              this.dataList02 = data.productDataList
            } else {
              this.dataList02 = []
            }
            this.dataListLoading02 = false
          })
      },
      getProductAttrSurvey(){
        this.dataListLoading01 = true
          selectProductAttrSurvey({}).then(({data}) => {
            if (data && data.code === 0) {
              this.dataList01 = data.productDataList
            } else {
              this.dataList01 = []
            }
            this.dataListLoading01 = false
          })
      },
      getProductSurvey(){
          selectProductSurvey({}).then(({data}) => {
            if (data && data.code === 0) {
              this.Survey = data.productData
            } else {
              this.Survey = {}
            }
          })
      },
      renderHeader (h,{column}) { // h即为cerateElement的简写，具体可看vue官方文档
        return (
          <div>
            <span style="cursor:pointer" title='属性数，颜色管理系统中已填充某一属性的产品数\n产品数，颜色管理系统产品总数'>属性数/产品数<i class="el-icon-question" style="margin-left:5px;"></i></span>
          </div>
        );
      },
      renderHeader_ (h,{column}) { // h即为cerateElement的简写，具体可看vue官方文档
        return (
          <div>
            <span style="cursor:pointer" title='可同步属性数，颜色管理系统排除粉底、过程样板产品的属性数\n可同步产品数，颜色管理系统排除粉底、过程样板产品的产品总数'>可同步属性数/可同步产品数<i class="el-icon-question" style="margin-left:5px;"></i></span>
          </div>
        );
      }
  },
};
</script>
<style lang="scss" scoped>
.app-container{
  .card_div{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    box-shadow: 0px 2px 6px rgba(82, 82, 82, 0.16);
    padding: 10px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
<style lang="scss">
.app-container {
  .el-progress-bar__outer {
    background-color:rgba(82, 82, 82, 0.3) !important ;
  }
}
</style>

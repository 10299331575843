import request from '@/utils/request'
// 获取基础产品信息列表
export function list (params) {
    return request({
        url: '/ProductOrder/ProductOrder/list',
        method: 'post',
        data: params
    })
}


// 添加
export function add (params) {
    return request({
        url: '/ProductOrder/ProductOrder/save',
        method: 'post',
        data: params
    })
}

// 修改
export function update (params) {
    return request({
        url: '/ProductOrder/ProductOrder/update',
        method: 'post',
        data: params
    })
}
//转产功能
export function changePro(params){
    return request({
        url: '/ProductOrder/ProductOrder/changePro',
        method: 'get',
        params: params
    })
}
// 转产导入
export function importZSProductExcel(params) {
    return request({
        url: '/ProductOrder/ProductOrder/importZSProductExcel',
        headers: { 'Content-Type': 'application/json'},
        responseType:  'blob',
        method: 'post',
        data: params
    })
}

export function getFactory1 (params) {
    return request({
        url: '/product/product/getFactory1',
        method: 'get',
        params: params
    })
}

  // 导入实验产品
  export function importExcel(params) {
    return request({
        url: '/ProductOrder/ProductOrder/importExcel',
        method: 'post',
        data: params
    })
}
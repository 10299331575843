import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/sample/sampleconfig/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/sample/sampleconfig/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/sample/sampleconfig/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/sample/sampleconfig/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/sample/sampleconfig/delete',
    method: 'post',
    data: params
  })
}

// 获取打样流程配置
export function sampleConfig (params) {
  return request({
    url: '/sample/sampleconfig/sampleConfig',
    method: 'get',
    params: params
  })
}

// 配置打样流程
export function setBrandSample (params) {
  return request({
    url: '/sample/sampleconfig/setBrandSample',
    method: 'post',
    data: params
  })
}

// 获取打样类型
export function getSampleTypes (params) {
  return request({
    url: '/sample/sampleconfig/getSampleTypes',
    method: 'get',
    params: params
  })
}



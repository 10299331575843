<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @click="close()">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="色号类别" prop="type">
        <!-- <el-input v-model="dataForm.type" placeholder="类型"  size="small"></el-input>-->
        <el-select v-model="dataForm.type" size="small" placeholder="请选择色号类别"
        >
          <el-option
                  v-for="item in colorBookDropDownList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号" prop="colorNo">
        <el-input v-model="dataForm.colorNo" placeholder="色号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="颜色名称" prop="colorName">
        <el-input v-model="dataForm.colorName" placeholder="颜色名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="测量LAB值">
        <div>
          <el-button size="small" type="primary" class="mr10"  @click="getSetmessage" :loading="loading">数据同步</el-button>
        </div>
      </el-form-item>
      <el-form-item label="L值" prop="l">
        <el-input v-model="dataForm.l" placeholder="L值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="A值" prop="a">
        <el-input v-model="dataForm.a" placeholder="A值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="B值" prop="b">
        <el-input v-model="dataForm.b" placeholder="B值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-collapse >
          <el-collapse-item >
            <span class="collapse-title" slot="title">查看测量数据</span>
            <div>
              <ul class="productLAB">
                <li>
                  <span v-for="x in productLAB.title" class="title">{{x}}</span>
                </li>
                <li v-for="(x,index) in productLAB.data">
                  <span class="title">{{x}}</span>
                  <el-input v-model="dataForm[`l${index+1}`]" placeholder="L值" class="w100" disabled="disabled"></el-input>
                  <el-input v-model="dataForm[`a${index+1}`]" placeholder="A值" class="w100" disabled="disabled"></el-input>
                  <el-input v-model="dataForm[`b${index+1}`]" placeholder="B值" class="w100" disabled="disabled"></el-input>
                </li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import {
        syncDevice,
        searchDeviceData,
        gethex
    } from "@/api/modules/product";
  import {info,add,update,colorBookDropDown} from '@/api/modules/colorbook';
    import Cookies from "js-cookie";
  export default {
    data () {
      return {
          loading: false,
          timer: null, //首先我在data函数里面进行定义定时器名称：
          timerNum: 5, // 设置定时器时间
          productLAB: {
              title: ["角度", "L值", "A值", "B值"],
              data: ["25度", "45度", "75度"]
          },
        visible: false,
        dataLoading: false,
          colorBookDropDownList:[],
        dataForm: {
          id: 0,
          colorNo: '',
          colorName: '',
          type: '',
          library: '',
          l: '',
          a: '',
          b: '',
            l1: '',
            a1: '',
            b1: '',
            l2: '',
            a2: '',
            b2: '',
            l3: '',
            a3: '',
            b3: '',
          rgbR: '',
          rgbG: '',
          rgbB: '',
          hex: ''
        },
        dataRule: {
          colorNo: [
            { required: true, message: '色号不能为空', trigger: 'blur' }
          ],
          colorName: [
            { required: true, message: '颜色名称不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '类型不能为空', trigger: 'blur' }
          ],
          library: [
            { required: true, message: '色库不能为空', trigger: 'blur' }
          ],
          l: [
            { required: true, message: 'L值不能为空', trigger: 'blur' }
          ],
          a: [
            { required: true, message: 'A值不能为空', trigger: 'blur' }
          ],
          b: [
            { required: true, message: 'B值不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
        close() {
            this.visible = false
            //this.device = {};
            //清除缓存数据
            this.$refs['dataForm'].resetFields();
        },
        //色号类别下拉
        getDictList() {
            colorBookDropDown().then(({data}) => {
                if (data && data.code === 0) {
                    this.colorBookDropDownList = data.colorBookDropDown
                } else {
                    this.colorBookDropDownList = []
                }
            })
        },
      init (id) {
            this.getDictList();
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.colorNo = data.colorBook.colorNo
                this.dataForm.colorName = data.colorBook.colorName
                this.dataForm.type = data.colorBook.dictId
                this.dataForm.l = data.colorBook.l
                this.dataForm.a = data.colorBook.a
                this.dataForm.b = data.colorBook.b
                  this.dataForm.l1 = data.colorBook.l1
                  this.dataForm.a1 = data.colorBook.a1
                  this.dataForm.b1 = data.colorBook.b1
                  this.dataForm.l2 = data.colorBook.l2
                  this.dataForm.a2 = data.colorBook.a2
                  this.dataForm.b2 = data.colorBook.b2
                  this.dataForm.l3 = data.colorBook.l3
                  this.dataForm.a3 = data.colorBook.a3
                  this.dataForm.b3 = data.colorBook.b3
              }
            })
          }
        })
      },
        gethexvalue() {
            var params = {
                lab: this.a      };
            gethex(params).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data);
                    this.color1 = data.hex;
                }
            });
        },
        //获取设备
        getSetmessage() {
            let id = Cookies.get("deviceId");
            // console.log(id);
            syncDevice({ deviceId: id }).then(({ data }) => {
                this.loading = true;
                if (data && data.code === 0) {
                    this.inputType='0'
                    this.timer = setInterval(() => {
                        //创建定时器
                        if (this.timerNum === 0) {
                            // 设置的定时器时间为0后执行的操作
                            this.clearTimer(); // 关闭定时器
                            this.loading = false;
                            this.timerNum = 5;
                        } else {
                            //查看设备状态
                            this.syncDeviceTime(id, this.timerNum);
                        }
                    }, 1000);
                }
                this.loading = false;
            });
        },
        syncDeviceTime(id, timerNum) {
            searchDeviceData({ deviceId: id }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataForm.l = data.product.lAve;
                    this.dataForm.a = data.product.aAve;
                    this.dataForm.b = data.product.bAve;
                    this.dataForm.l1 = data.product.l1
                    this.dataForm.a1 = data.product.a1
                    this.dataForm.b1 = data.product.b1
                    this.dataForm.l2 = data.product.l2
                    this.dataForm.a2 = data.product.a2
                    this.dataForm.b2 = data.product.b2
                    this.dataForm.l3 = data.product.l3
                    this.dataForm.a3 = data.product.a3
                    this.dataForm.b3 = data.product.b3
                    this.gethexvalue();
                    this.getDataList();
                    this.clearTimer(); // 关闭定时器
                    this.loading = false;
                } else {
                    this.timerNum =timerNum - 1; // 定时器减1
                }
            });
        },
        clearTimer() {
            //清除定时器
            window.clearInterval(this.timer);
            this.timer = null;
            this.timerNum = 5;
        },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'colorNo': this.dataForm.colorNo,
              'colorName': this.dataForm.colorName,
              'type': this.dataForm.type,
              'library': this.dataForm.library,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b,
                'l1': this.dataForm.l1,
                'a1': this.dataForm.a1,
                'b1': this.dataForm.b1,
                'l2': this.dataForm.l2,
                'a2': this.dataForm.a2,
                'b2': this.dataForm.b2,
                'l3': this.dataForm.l3,
                'a3': this.dataForm.a3,
                'b3': this.dataForm.b3,
              'rgbR': this.dataForm.rgbR,
              'rgbG': this.dataForm.rgbG,
              'rgbB': this.dataForm.rgbB,
              'hex': this.dataForm.hex
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-left: 20px;
    }
    .el-upload:hover {
      border-color: #409EFF;
    }
  }
</style>
<style lang="scss" scoped>
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 178px;
    text-align: center;
  }

  .ziti {
    font-size: 12px;
    margin: 0px;
    line-height: 25px;
  }

  .avatar {
    width: 178px;
    max-height: 178px;
    display: block;
  }

  .productLAB {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: -50px;
    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 10px;
      .title {
        display: inline-block;
        width: 100px;
        text-align: center;
        color: #606266;
        font-size: 700;
      }
    }

  }

  .w100 {
    width: 100px;
  }
  .w150 {
    width: 119px;
  }
  .mr10 {
    margin-right: 5px;
  }
  .collapse-title {
    flex: 1 0 90%;
    color: #4AB7BD;
    font-weight: bolder;
    text-align: center;
  }

  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 18px;
  }
</style>

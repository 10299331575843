<template>
  <div class="app-container">
    <el-dialog
            :title="!dataForm.id ? '查看配方' : '查看配方'"
            :close-on-click-modal="false"
            :visible.sync="recipeListVisible"
            append-to-body>
      <div>
        <!--<el-tag v-for="(item,index) in matetail" :key="index" @click="init(item.componentId,item.productId)">{{item.materialName}}</el-tag>-->
        <!--<p v-for="(item,index) in matetail" :key="index">{{item}}</p>-->
        <el-table :data="dataList"
                  border
                  v-loading="dataListLoading"
                  style="width: 100%;">
          <el-table-column
                  prop="materialNo"
                  header-align="center"
                  align="center"
                  label="材料编号">
            <template slot-scope="scope">
              <el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="init(scope.row.productId,scope.row.componentId)">{{scope.row.productNo}}</el-link>
              <label v-if="scope.row.productId==null">{{scope.row.materialNo}}</label>
            </template>
          </el-table-column>
          <el-table-column
                  prop="materialName"
                  header-align="center"
                  align="center"
                  label="原料名称">
            <!--<template slot-scope="scope">-->
              <!--<el-link type="primary" v-if="scope.row.productId!=null" @click="init(scope.row.productId,scope.row.componentId)">{{scope.row.productNo}}</el-link>-->
              <!--<label v-if="scope.row.productId==null">{{scope.row.materialName}}</label>-->
            <!--</template>-->
          </el-table-column>
          <el-table-column
                  prop="value"
                  header-align="center"
                  align="center"
                  label="计量值">
            <template slot-scope="scope">
              <span>{{scope.row.value|numFilter}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="materialUnits"
                  header-align="center"
                  align="center"
                  label="单位">
          </el-table-column>
          <el-table-column
                  prop="rate"
                  header-align="center"
                  align="center"
                  label="计量百分比">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {list,listCipe,listAll,listAllCipe,productMateril} from '@/api/modules/samplerecipe'
  import {selectRecipe} from "@/api/modules/product";
  export default {
    data () {
      return {
        dataForm: {
          recipeOrderId: '',
          daterange:[],
          category:'',
            componentId:'',//组别id
            productId:''//产品id
        },
        dataList: [],
        dataCipeList:[],
          matetail:[],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        recipeListVisible: false
      }
    },
    components: {
    },
    //写个过滤器，保留小数点后两位
    filters: {
      numFilter (value) {
        let realVal = ''
        if (!isNaN(value) && value!== '') {
          // 截取当前数据到小数点后两位
          realVal = parseFloat(value).toFixed(2)
        } else {
          realVal = '--'
        }
        return realVal
      }
    },
    methods: {

        //配方材料数据接口
      init(productId,componentId){
          console.log(productId+"11111111"+componentId)
          this.recipeListVisible= true
          this.dataForm.componentId = componentId;
        this.dataForm.productId=productId;
          var params = {
              id: this.dataForm.productId,
              sampleProductId: this.dataForm.componentId,
          }
          if(componentId==null){
              selectRecipe(params).then(({data}) => {
                  if (data && data.code === 0) {
                      this.dataList = data.recipeList.recipeMaterial;
                  } else {
                      this.dataList = []
                      this.$message.error(data.msg);
                  }
                  this.dataListLoading = false
              })
          }
          if(productId==null){
              listAll(params).then(({data}) => {
                  if (data && data.code === 0) {
                      for(var i=0;i<data.page.length;i++){
                          data.page[i].materialUnits= data.page[i].units;
                      }

                      this.dataList = data.page
                  } else {
                      this.dataList = []
                      this.$message.error(data.msg);
                  }
                  this.dataListLoading = false
              })
          }

      },
    }
  }
</script>

<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <!-- <el-form-item prop="name">
        <el-input v-model="dataForm.name" placeholder="固化条件名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item> -->
      <el-form-item>
        <!-- <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button> -->
        <el-button v-if="isAuth('factory:source:add')" size="small" @click="addOrUpdateHandle()" type="primary">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!-- <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column> -->
      <el-table-column
        prop="tenantName"
        header-align="center"
        align="center"
        label="品牌名称">
      </el-table-column>
      <el-table-column
        v-if="isAuth('factory:source:isshow')"
        prop="datasourceUrl"
        header-align="center"
        align="center"
        width="500"
        label="数据源地址">
        <template  slot-scope="scope">
          <el-tooltip class="item" effect="light" :content="scope.row.datasourceUrl" placement="bottom">
            <div style="white-space: nowrap; text-overflow:ellipsis; width: 100%; overflow:hidden;">{{scope.row.datasourceUrl}}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isAuth('factory:source:isshow')"
        prop="datasourceUsername"
        header-align="center"
        align="center"
        label="账号名称">
      </el-table-column>
      <el-table-column
        v-if="isAuth('factory:source:isshow')"
        prop="datasourceDriver"
        header-align="center"
        align="center"
        label="数据源驱动">
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status=='0'">禁用</span>
          <span v-if="scope.row.status=='1'">启用</span>
          <span v-if="scope.row.status=='2'">连接异常</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        v-if="isAuth('factory:source:update') || isAuth('factory:source:del') || isAuth('factory:source:switch')"
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('factory:source:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.tenantId)">修改</el-button>
          <el-button v-if="isAuth('factory:source:del')" type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          <el-button v-if="isAuth('factory:source:switch')" type="text" size="small" @click="setStatus(scope.row)">{{scope.row.status == 0 ? '开启' : '关闭'}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import {list,update,del} from '@/api/modules/source'
  import AddOrUpdate from './add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          key: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate,
    },
    created () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            // name: this.dataForm.name
        }
        list(params).then(({data}) => {
          if (data && data.code == '10200') {
            this.dataList = data.data.records
            this.totalPage = data.data.total
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        console.log(id);
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      setStatus(data){
        console.log(data);
        var params = data
        params.status = ("1" == params.status) ? '0' : '1'
        update(params).then(({data}) => {
          if (data && data.code == '10200') {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
            })
            this.getDataList()
          } else {
            this.$message.error(data.message ? data.message : 'error')
          }
        })
      },
      // 删除
      deleteHandle (id) {
        this.$confirm(`是否确认删除该条数据?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del({id:id}).then(({data}) => {
            if (data && data.code === 10200) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.message ? data.message : 'error')
            }
          })
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.app-container {
}
</style>

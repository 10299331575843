<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item label="客户名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="客户名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="客户等级" prop="level">
        <el-select v-model="dataForm.level" size="small" placeholder="请选择客户等级">
          <el-option label="全部" value="-1"></el-option>
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
          <el-option label="D" value="D"></el-option>
          <el-option label="E" value="E"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人电话" prop="contactsPhone">
        <el-input v-model="dataForm.contactsPhone" placeholder="联系人电话" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('customer:customer:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        <el-button v-if="isAuth('customer:customer:import')" type="primary" @click="exportExcelHandle()"
                    size="small">下载批量导入模板
        </el-button>
        <el-button v-if="isAuth('customer:customer:import')" type="success" @click="uploadHandle()" :disabled="dataList.length < 0">导入</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="客户名称">
      </el-table-column>
      <el-table-column
        prop="level"
        header-align="center"
        align="center"
        label="客户等级">
      </el-table-column>
      <el-table-column
        prop="contactsName"
        header-align="center"
        align="center"
        label="联系人">
      </el-table-column>
      <el-table-column
        prop="contactsPhone"
        header-align="center"
        align="center"
        label="联系电话">
      </el-table-column>
      <el-table-column
        prop="contactsAddress"
        header-align="center"
        align="center"
        label="收件地址">
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="所属品牌">
      </el-table-column>
      <!-- <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建日期">
      </el-table-column> -->
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/customer'
  import AddOrUpdate from './add-or-update'
  import {getToken} from "@/utils/auth"
  import Upload from "./oss-upload"

  export default {
    data () {
      return {
        dataForm: {
          name: '',
          level: '-1',
          contactsPhone:''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        uploadVisible: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      Upload,
      AddOrUpdate
    },
    created () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.dataForm.level = '-1';
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.dataForm.name,
          level: this.dataForm.level,
          contactsPhone: this.dataForm.contactsPhone
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      //导出excel表格模板
      exportExcelHandle(){
        var url = `${process.env.VUE_APP_BASE_API}/customer/customer/exportExcel`+`?token=${getToken()}`
        // 模拟创建元素实现
        var alink = document.createElement('a');
        alink.href = url;
        alink.style = 'display:none';
        alink.click();
      },
      // 上传文件
      uploadHandle() {
        this.uploadVisible = true;
        this.$nextTick(() => {
          this.$refs.upload.init();
        });
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

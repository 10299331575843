<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="sampleOrderNo">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="订单号" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="company">
        <el-input v-model="dataForm.company" placeholder="客户" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="userName">
        <el-input v-model="dataForm.userName" placeholder="打样员" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="remarks">
        <el-input v-model="dataForm.remarks" placeholder="备注" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" placeholder="状态">
          <el-option label="全部" value="-1"></el-option>
          <el-option label="未处理" value="0"></el-option>
          <el-option label="完全接纳" value="1"></el-option>
          <el-option label="比例微调后接纳" value="2"></el-option>
          <el-option label="不采纳" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="block">
          <el-date-picker
            v-model="createDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="创建开始时间"
            end-placeholder="创建结束时间">
          </el-date-picker>  
        </div>
      </el-form-item>
      <el-form-item>
        <div class="block">
          <el-date-picker
            v-model="updateDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="更新开始时间"
            end-placeholder="更新结束时间">
          </el-date-picker> 
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button type="primary" @click="exportMixPowderHandle()" size="small" :loading="exportloading">导出</el-button>
        <!-- <el-button v-if="isAuth('mixpowder:mixpowder:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button> -->
        <!-- <el-button v-if="isAuth('mixpowder:mixpowder:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        label="订单号">
      </el-table-column>
      <el-table-column
        prop="company"
        header-align="center"
        align="center"
        label="客户">
      </el-table-column>
      <el-table-column
        prop="productSeries"
        header-align="center"
        align="center"
        label="系列">
      </el-table-column>
      <el-table-column
        prop="glossValue"
        header-align="center"
        align="center"
        label="光泽值">
      </el-table-column>
      <el-table-column
        prop="productNum"
        header-align="center"
        align="center"
        label="需求量">
      </el-table-column>
      <el-table-column
        prop="factoryName"
        header-align="center"
        align="center"
        label="产品工厂">
      </el-table-column>
      <el-table-column
        prop="l"
        header-align="center"
        align="center"
        label="L*">
      </el-table-column>
      <el-table-column
        prop="a"
        header-align="center"
        align="center"
        label="a*">
      </el-table-column>
      <el-table-column
        prop="b"
        header-align="center"
        align="center"
        label="b*">
      </el-table-column>
      <el-table-column
        prop="productCategory"
        header-align="center"
        align="center"
        label="产品分类">
      </el-table-column>
      <el-table-column
        prop="productSurface"
        header-align="center"
        align="center"
        label="产品表面">
      </el-table-column>
      <el-table-column
        prop="productIndustry"
        header-align="center"
        align="center"
        label="应用行业">
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="updateDate"
        header-align="center"
        align="center"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="打样员">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">未处理</span>
          <span v-if="scope.row.state=='1'">完全接纳</span>
          <span v-if="scope.row.state=='2'">比例微调后接纳</span>
          <span v-if="scope.row.state=='3'">不采纳</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button> -->
          <el-button v-if="scope.row.state!='3'" type="text" size="small" @click="setMixPowderStateHandle(scope.row.id)">处理</el-button>
          <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <el-dialog
      title=""
      class="loading_dialog"
      :visible.sync="exportloading"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeimport"
      width="500px"
      center>
      <div>
        <div style="color:#000000;margin-bottom:30px;margin-top:-30px;width:100%;text-align:center;font-size:16px;font-font-weight:blod;">{{importTitle}}</div>
        <div style="color:#999999;margin-bottom:30px;width:100%;text-align:center;" v-if="progress != 100 && importTitle != '导出失败！' && hideProgress">关闭弹窗后导出任务将会终止！</div>
        <el-progress v-if="hideProgress" :text-inside="true" :percentage="progress" :stroke-width="20" :indeterminate="true" :color="importTitle == '导出失败！' ? 'red' : '#5A5FFF'"></el-progress>
        <div style="margin-top:30px;margin-bottom:30px;width:100%;text-align:center;color:#337ab7;" v-if="progress == 100"><span style="color:#000000;">点击链接下载：</span><a :href="exportUrl">{{exportUrl}}</a></div>
      </div>
    </el-dialog>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <set-mix-powder-state v-if="setMixPowderStateVisible" ref="setMixPowderState" @refreshDataList="getDataList"></set-mix-powder-state>
  </div>
</template>

<script>
  import {list,del,exportMixPowder,getSpeedOfProgress} from '@/api/modules/mixpowder'
  import AddOrUpdate from './add-or-update'
  import SetMixPowderState from './set-mixpowder-state'
  export default {
    data () {
      return {
        dataForm: {
          sampleOrderNo: '',
          company: '',
          userName: '',
          remarks: '',
          state: ''
        },
        timer_: null,
        progress:0,
        importTitle:'正在导出中...',
        exportUrl:'',
        hideProgress:true,//控制导出进度条是否展示
        gettime:'',
        createDates:[],
        updateDates:[],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        exportloading: false,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        setMixPowderStateVisible: false
      }
    },
    components: {
      AddOrUpdate,
      SetMixPowderState
    },
    activated () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.createDates = [];
        this.updateDates = [];
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var createDates = this.createDates;
        var createStartDate = null;
        var createEndDate = null;
        if (createDates && createDates.length > 0) {
          createStartDate = createDates[0];
          createEndDate = createDates[1];
        }
        var updateDates = this.updateDates;
        var updateStartDate = null;
        var updateEndDate = null;
        if (updateDates && updateDates.length > 0) {
          updateStartDate = updateDates[0];
          updateEndDate = updateDates[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          sampleOrderNo: this.dataForm.sampleOrderNo,
          company: this.dataForm.company,
          userName: this.dataForm.userName,
          remarks: this.dataForm.remarks,
          state: this.dataForm.state,
          createStartDate: createStartDate,
          createEndDate: createEndDate,
          updateStartDate: updateStartDate,
          updateEndDate: updateEndDate
        }

        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 处理
      setMixPowderStateHandle (id) {
        this.setMixPowderStateVisible = true
        this.$nextTick(() => {
          this.$refs.setMixPowderState.init(id)
        })
      },
      //导出混粉单数据到excle
      exportMixPowderHandle() {
        this.hideProgress = true
        this.gettime = "mix_powder_task" + new Date().getTime();
        var createDates = this.createDates;
        var createStartDate = null;
        var createEndDate = null;
        if (createDates && createDates.length > 0) {
          createStartDate = createDates[0];
          createEndDate = createDates[1];
        }
        var updateDates = this.updateDates;
        var updateStartDate = null;
        var updateEndDate = null;
        if (updateDates && updateDates.length > 0) {
          updateStartDate = updateDates[0];
          updateEndDate = updateDates[1];
        }
        var params = {
          sampleOrderNo: this.dataForm.sampleOrderNo,
          company: this.dataForm.company,
          userName: this.dataForm.userName,
          remarks: this.dataForm.remarks,
          state: this.dataForm.state,
          createStartDate: createStartDate,
          createEndDate: createEndDate,
          updateStartDate: updateStartDate,
          updateEndDate: updateEndDate,
          taskNo: this.gettime
        }
        exportMixPowder(params).then(({data}) => { // 处理返回的文件流
          // console.log(data);
          if (data && data.code === 0) {
            // console.log(111);
            this.progress = 0
            this.exportloading = true;
            this.timer_ = setInterval(this.getprogress, 3000);
          }else{
            // console.log(222);
            this.$message.warning(data.msg);
          }
        })
      },
      getprogress(){
        var params_ = {
          taskNo: this.gettime
        }
        getSpeedOfProgress(params_).then(({data}) => {
          if (data && data.code === 0) {
            if (data.data.state != 0) {
              this.progress = data.data.speedOofProgress
              if (data.data.speedOofProgress == 100) {
                this.exportUrl = data.data.url.replace('http','https')
                this.importTitle = '导出成功！'
                window.clearInterval(this.timer_);
                this.timer_ = null
              }
            }else{
              this.importTitle = '导出失败！'
              window.clearInterval(this.timer_);
              this.timer_ = null
            }
          }else if (data && data.code === 10400) {
              this.importTitle = '没有可导出的混粉单数据！'
              window.clearInterval(this.timer_);
              this.timer_ = null
              this.hideProgress = false
          }else{
            this.importTitle = '导出失败！'
            window.clearInterval(this.timer_);
            this.timer_ = null
            this.gettime = ''
          }
        })
      },
      closeimport(){
        window.clearInterval(this.timer_);
        this.timer_ = null
        this.exportloading = false;
        this.progress = 0,
        setTimeout(() => {
          this.importTitle = '正在导出中...'
        }, 500);
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

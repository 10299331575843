import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/factory/brand/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/factory/brand/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/factory/brand/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/factory/brand/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/factory/brand/delete',
    method: 'post',
    data: params
  })
}

// 获取列表
export function brandList (params) {
  return request({
    url: '/factory/brand/brandList',
    method: 'get',
    params: params
  })
}

// 添加
export function savePrivatisation (params) {
  return request({
    url: '/factory/brand/savePrivatisation',
    method: 'post',
    data: params
  })
}


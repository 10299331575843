<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="产品id" prop="productId">
        <el-input v-model="dataForm.productId" placeholder="产品id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="L值" prop="l">
        <el-input v-model="dataForm.l" placeholder="L值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="A值" prop="a">
        <el-input v-model="dataForm.a" placeholder="A值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="B值" prop="b">
        <el-input v-model="dataForm.b" placeholder="B值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="RAL色号" prop="ralColorNo">
        <el-input v-model="dataForm.ralColorNo" placeholder="RAL色号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="光泽" prop="gloss">
        <el-input v-model="dataForm.gloss" placeholder="光泽"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="查询时间" prop="searchDate">
        <el-input v-model="dataForm.searchDate" placeholder="查询时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="设备号" prop="diviceNo">
        <el-input v-model="dataForm.diviceNo" placeholder="设备号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="操作" prop="operation">
        <el-input v-model="dataForm.operation" placeholder="操作"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="品牌id" prop="brandId">
        <el-input v-model="dataForm.brandId" placeholder="品牌id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="工厂id" prop="factoryId">
        <el-input v-model="dataForm.factoryId" placeholder="工厂id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品表面" prop="surface">
        <el-input v-model="dataForm.surface" placeholder="产品表面"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="productNo">
        <el-input v-model="dataForm.productNo" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品分类" prop="productCategory">
        <el-input v-model="dataForm.productCategory" placeholder="产品分类"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="产品状态" prop="state">
        <el-input v-model="dataForm.state" placeholder="产品状态"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/searchhistory'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          productId: '',
          l: '',
          a: '',
          b: '',
          ralColorNo: '',
          gloss: '',
          searchDate: '',
          diviceNo: '',
          operation: '',
          brandId: '',
          factoryId: '',
          surface: '',
          productNo: '',
          productCategory: '',
          state: ''
        },
        dataRule: {
          productId: [
            { required: true, message: '产品id不能为空', trigger: 'blur' }
          ],
          l: [
            { required: true, message: 'L值不能为空', trigger: 'blur' }
          ],
          a: [
            { required: true, message: 'A值不能为空', trigger: 'blur' }
          ],
          b: [
            { required: true, message: 'B值不能为空', trigger: 'blur' }
          ],
          ralColorNo: [
            { required: true, message: 'RAL色号不能为空', trigger: 'blur' }
          ],
          gloss: [
            { required: true, message: '光泽不能为空', trigger: 'blur' }
          ],
          searchDate: [
            { required: true, message: '查询时间不能为空', trigger: 'blur' }
          ],
          diviceNo: [
            { required: true, message: '设备号不能为空', trigger: 'blur' }
          ],
          operation: [
            { required: true, message: '操作不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌id不能为空', trigger: 'blur' }
          ],
          factoryId: [
            { required: true, message: '工厂id不能为空', trigger: 'blur' }
          ],
          surface: [
            { required: true, message: '产品表面不能为空', trigger: 'blur' }
          ],
          productNo: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          productCategory: [
            { required: true, message: '产品分类不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '产品状态不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.productId = data.searchhistory.productId
                this.dataForm.l = data.searchhistory.l
                this.dataForm.a = data.searchhistory.a
                this.dataForm.b = data.searchhistory.b
                this.dataForm.ralColorNo = data.searchhistory.ralColorNo
                this.dataForm.gloss = data.searchhistory.gloss
                this.dataForm.searchDate = data.searchhistory.searchDate
                this.dataForm.diviceNo = data.searchhistory.diviceNo
                this.dataForm.operation = data.searchhistory.operation
                this.dataForm.brandId = data.searchhistory.brandId
                this.dataForm.factoryId = data.searchhistory.factoryId
                this.dataForm.surface = data.searchhistory.surface
                this.dataForm.productNo = data.searchhistory.productNo
                this.dataForm.productCategory = data.searchhistory.productCategory
                this.dataForm.state = data.searchhistory.state
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'productId': this.dataForm.productId,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b,
              'ralColorNo': this.dataForm.ralColorNo,
              'gloss': this.dataForm.gloss,
              'searchDate': this.dataForm.searchDate,
              'diviceNo': this.dataForm.diviceNo,
              'operation': this.dataForm.operation,
              'brandId': this.dataForm.brandId,
              'factoryId': this.dataForm.factoryId,
              'surface': this.dataForm.surface,
              'productNo': this.dataForm.productNo,
              'productCategory': this.dataForm.productCategory,
              'state': this.dataForm.state
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="categoryName">
        <el-select v-model="dataForm.categoryId" placeholder="类别">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in MaterialCategoryInfo"
                  :key="item.categoryName"
                  :label="item.categoryName"
                  :value="item.categoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="materialNo">
        <el-input v-model="dataForm.materialNo" placeholder="物料号" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="materialName">
        <el-input v-model="dataForm.materialName" placeholder="名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDates">
        <div class="block">
          <el-date-picker
            v-model="dataForm.createDates"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('material:material:save')" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>

        <el-button v-if="isAuth('material:material:import')" type="success" @click="uploadHandle()" :disabled="dataList.length < 0">Excel导入</el-button>
        <el-button v-if="isAuth('material:material:import')" type="primary" @click="exportExcelHandle()"
                    size="small">导入模板
        </el-button>
        <el-button v-if="isAuth('material:material:upload')" type="primary" @click="exportExcelProduct()"
                    size="small">导出
        </el-button>
        <el-button v-if="isAuth('material:material:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>
        <el-button v-if="isAuth('material:material:upload')" type="danger" @click="printPreviewHandle()" :disabled="dataListSelections.length <= 0" size="small">批量打印</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="materialNo"
        header-align="center"
        align="center"
        label="物料号">
      </el-table-column>
      <el-table-column
        prop="materialName"
        header-align="center"
        align="center"
        label="名称">
      </el-table-column>
      <el-table-column
        prop="materialCategoryName"
        header-align="center"
        align="center"
        label="类别">
      </el-table-column>
      <el-table-column
        prop="units"
        header-align="center"
        align="center"
        label="计量单位">
      </el-table-column>
      <el-table-column
        prop="qrUrl"
        header-align="center"
        align="center"
        label="二维码">
        <!-- 图片的显示 -->
        <template   slot-scope="scope">
          <img :src="scope.row.qrUrl"  min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" style="color: green" @click="printPreviewHandle(scope.row.id)">打印</el-button>
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <print-preview v-if="printPreviewVisible" ref="printPreviewMaterial" @refreshDataList="getDataList"></print-preview>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
  </div>
</template>

<script>
  import {list,del,MaterialCategoryInfo,importExcel} from '@/api/modules/material'
  import AddOrUpdate from './add-or-update'
  import {getToken} from "@/utils/auth";
  import PrintPreview from './printingPreviewMaterial'
  import Upload from "./oss-upload";
  export default {
    data () {
      return {
        dataForm: {
            materialName: '',
            materialNo:'',
         //   categoryName:'',
            categoryId:'',
            createDate:'',
            daterange:[]
        },
          MaterialCategoryInfo:[],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
          uploadVisible : false,
        addOrUpdateVisible: false,
        printPreviewVisible: false,
      }
    },
    components: {
      AddOrUpdate,
      PrintPreview,
        Upload
    },
    created () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
       	this.dataForm.categoryId = '';
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
          this.getMaterialCategoryInfo();
        this.dataListLoading = true
        var startDate = null;
        var endDate = null;
        if (this.dataForm.createDates && this.dataForm.createDates.length > 0) {
          startDate = this.dataForm.createDates[0];
          endDate = this.dataForm.createDates[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          materialName: this.dataForm.materialName,
          materialNo:this.dataForm.materialNo,
          categoryId:this.dataForm.categoryId,
          startDate: startDate ? startDate + ' 00:00:00' : '',
          endDate: endDate ? endDate + ' 23:59:59' : '',
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
              if(this.pageIndex>1&&data.page.list.length==0){
                  this.pageIndex=this.pageIndex-1;
                  this.getDataList();
              }
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      //打印
      printPreviewHandle(id){
        this.printPreviewVisible = true;
        console.log(id)
        let ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$nextTick(() => {
          this.$refs.printPreviewMaterial.init(ids)
        })
      },
        //导出excel表格模板
        exportExcelHandle(){
            var params = {}
            var url = `${process.env.VUE_APP_BASE_API}/material/material/exportExcel`+`?token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
        //pdf
        exportExcelProduct(){
            var params = {
                categoryId: this.dataForm.categoryId,
                materialName: this.dataForm.materialName,
                materialNo: this.dataForm.materialNo,
                createDate:this.dataForm.createDate,
            }
            var url =`${process.env.VUE_APP_BASE_API}/material/material/getPDF?createDate=`+params.createDate+'&categoryId='+params.categoryId+
                '&materialName='+params.materialName+
                '&materialNo='+params.materialNo+ `&token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
        // 上传文件
        uploadHandle() {
            this.uploadVisible = true;
            this.$nextTick(() => {
                this.$refs.upload.init();
            });
        },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
        //获取类别名称下拉
        getMaterialCategoryInfo(){
            MaterialCategoryInfo().then(({ data }) => {
                if (data && data.code === 0) {
                    this.MaterialCategoryInfo = data.MaterialCategoryInfo;
                  //  this.MaterialCategoryInfo = data.roleName;
                }
            });
        },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`是否确认删除物料?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

<template>
  <el-dialog
    title="混粉订单处理"
    :close-on-click-modal="false"
    :visible.sync="visible" width="1500px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <div style="text-align:center;font-size:16px;color:#000000;padding:20px 15px 15px 35px;font-weight: 500;border-bottom:1px solid #F0F0F0;margin-bottom:15px">混粉需求</div>
      <el-table
      :data="dataLists"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%;">
        <el-table-column
          prop="sampleOrderNo"
          header-align="center"
          align="center"
          label="订单号">
        </el-table-column>
        <el-table-column prop="hex" header-align="center" align="center" label="样图">
          <template slot-scope="scope">
            <div :style="{backgroundColor:(scope.row.hex || scope.row.hex),height:'70px',minWidth:'70px'}"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="company"
          header-align="center"
          align="center"
          label="客户">
        </el-table-column>
        <el-table-column
          prop="productSeries"
          header-align="center"
          align="center"
          label="系列">
        </el-table-column>
        <el-table-column
          prop="glossValue"
          header-align="center"
          align="center"
          label="光泽值">
        </el-table-column>
        <el-table-column
          prop="productNum"
          header-align="center"
          align="center"
          label="需求量">
        </el-table-column>
        <el-table-column
          prop="factoryName"
          header-align="center"
          align="center"
          label="产品工厂">
        </el-table-column>
        <el-table-column
          prop="l"
          header-align="center"
          align="center"
          label="L*">
        </el-table-column>
        <el-table-column
          prop="a"
          header-align="center"
          align="center"
          label="a*">
        </el-table-column>
        <el-table-column
          prop="b"
          header-align="center"
          align="center"
          label="b*">
        </el-table-column>
        <el-table-column
          prop="productCategory"
          header-align="center"
          align="center"
          label="产品分类">
        </el-table-column>
        <el-table-column
          prop="productSurface"
          header-align="center"
          align="center"
          label="产品表面">
        </el-table-column>
        <el-table-column
          prop="productIndustry"
          header-align="center"
          align="center"
          label="应用行业">
        </el-table-column>
        <el-table-column
          prop="createDate"
          header-align="center"
          align="center"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="updateDate"
          header-align="center"
          align="center"
          label="更新时间">
        </el-table-column>
        <el-table-column
          prop="userName"
          header-align="center"
          align="center"
          label="打样员">
        </el-table-column>
        <el-table-column
          prop="state"
          header-align="center"
          align="center"
          label="状态">
          <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">未处理</span>
          <span v-if="scope.row.state=='1'">完全接纳</span>
          <span v-if="scope.row.state=='2'">比例微调后接纳</span>
          <span v-if="scope.row.state=='3'">不采纳</span>
        </template>
        </el-table-column>
      </el-table>
      <div class="content">
        <div style="text-align:center;font-size:16px;color:#000000;padding:10px 10px 10px 30px;font-weight: 500;border-bottom:1px solid #F0F0F0;margin-bottom:15px">混粉组合推荐{{':' + datasize}}</div>
        <div class="c_m_cards" v-loading="dataListLoading">
          <el-carousel arrow="always" :autoplay="false" indicator-position="outside" :v-if="dataList.length > 0" style="width:100%;height: 380px;">
            <el-carousel-item v-for="(items, indexs) in dataList" :key="indexs" style="width:100%;height: 380px;">
              <div class="c_m_cards">
              <el-card :body-style="{ padding: '0px' }" v-for="(item, index) in items" :key="index">
                <div class="c_m_c_bot">
                  <div class="c_m_c_b_txt">
                    <el-row style="text-align:center;border-top: 2px solid #D2D2D2;border-bottom: 2px solid #D2D2D2;height:34px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{index+1+indexs*3}}</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex1) in item.mixPowderProduct" :key="mindex1" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <div style="text-align:center;">
                          <div :style="{backgroundColor:(mixPowderProduct.hex),height:'30px',minWidth:'60px'}"></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">产品号</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex2) in item.mixPowderProduct" :key="mindex2" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.productNo}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">L*</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex3) in item.mixPowderProduct" :key="mindex3" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.l}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">a*</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex4) in item.mixPowderProduct" :key="mindex4" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.a}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">b*</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex5) in item.mixPowderProduct" :key="mindex5" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.b}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">光泽</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex6) in item.mixPowderProduct" :key="mindex6" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.glossValue}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">产品表面</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex7) in item.mixPowderProduct" :key="mindex7" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.productSurface}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">库存</p>
                      </el-col>
                      <el-col v-for="(mixPowderProduct, mindex8) in item.mixPowderProduct" :key="mindex8" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.productNum + "KG"}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;background-color: #e1e8e5;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">比例</p>
                      </el-col>
                      <el-col v-show="dataForm.combinationId!=item.id || dataForm.state !='2'" v-for="(mixPowderProduct, mindex9) in item.mixPowderProduct" :key="mindex9" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">{{mixPowderProduct.rate}}</p>
                      </el-col>
                      <el-col v-show="dataForm.combinationId==item.id && dataForm.state =='2'" v-for="(mixPowderProduct, mindex9) in item.mixPowderProduct" :key="mindex9" :span="(3 == item.mixPowderProduct.length?6 : 20/item.mixPowderProduct.length)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <el-input v-model="mixPowderProduct.modifyRate" placeholder="调整比例" size="small" @change="changeRate(item.mixPowderProduct)" style="padding: 1px;"></el-input>
                      </el-col>
                    </el-row>
                    <el-row type="flex" class="row-bg" justify="start" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;background-color: #e1e8e5;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;height:30px;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;">
                        <p size="small" style="font-weight: bold;">色差</p>
                      </el-col>
                      <el-col :span="(3 == item.mixPowderProduct.length? 18 : 20)" style="border-right: 2px solid #D2D2D2;height:30px;font-size: 12px;color: #6F73FF;">
                        <p size="small" style="font-weight: bold;">{{item.del2000}}</p>
                      </el-col>
                    </el-row>
                    <el-row style="text-align:center;border-bottom: 2px solid #D2D2D2;height:32px;vertical-align: middle;background-color: #e1e8e5;">
                      <el-col :span="(3 == item.mixPowderProduct.length? 6 : 4)" style="border-left: 2px solid #D2D2D2;border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <p size="small">采纳</p>
                      </el-col>
                      <el-col :span="(3 == item.mixPowderProduct.length? 18 : 20)" style="border-right: 2px solid #D2D2D2;font-size: 12px;color: #6F73FF;height:30px;">
                        <el-radio v-model="dataForm.combinationId" size="medium" @change="checkSelect(index+1+indexs*3, item.id)" style="margin-top: 8px;" :label="item.id">{{item.powderNo}}</el-radio>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 30%;">
          <el-form-item prop="state" label="采纳">
            <el-select v-model="dataForm.state" placeholder="请选择" @change="changeState()">
              <el-option label="请选择" value=""></el-option>
              <el-option label="完全接纳" value="1"></el-option>
              <el-option label="比例微调后接纳" value="2"></el-option>
              <el-option label="不采纳" value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="width: 70%;">
          <el-form-item prop="remarks" label="备注">
            <el-input v-model="dataForm.remarks" placeholder="备注" size="small" style="width: 90%;"></el-input>
          </el-form-item>           
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/mixpowder'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataList: [],
        dataLists: [],
        datasize: '',
        dataListLoading: false,
        dataListSelections: [],
        selectrow : "",
        dataForm: {
          id: 0,
          remarks: '',
          state: '',
          combinationId: '',
          mixPowderProduct:[]
        },
        mixPowderCombination:[],
        dataRule: {
          state: [
            { required: true, message: '请选择接纳处理', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true;
        this.datasize = '';
        this.dataList = [];
        this.dataLists = [];
        this.dataForm.state = '';
        this.dataForm.remarks = '';
        this.dataForm.combinationId = '';
        this.dataForm.mixPowderProduct = [];
        this.mixPowderCombination = [];
        this.$nextTick(() => {
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code == 0) {
                this.datasize = data.mixPowder.mixPowderCombination.length;
                this.mixPowderCombination = data.mixPowder.mixPowderCombination;
                this.dataList = this.formatArray(this.mixPowderCombination, 3);
                this.dataLists.push(data.mixPowder);
                if(data.mixPowder.state != 0){
                  this.dataForm.state = data.mixPowder.state;
                }
                
                this.dataForm.remarks = data.mixPowder.remarks;
                if(null != data.mixPowder.selectCombination && data.mixPowder.state != '0' && data.mixPowder.state != '3'){
                  this.dataForm.combinationId = data.mixPowder.selectCombination;
                }
              }
            })
          }
        })
      },
      formatArray(data, num) {
        var dataList2 = []
        for (var i = 0, len = data.length; i < len; i += num) {
          dataList2.push(data.slice(i, i + num))
        }
        return dataList2
      },
      checkSelect(index, combinationId){
        this.selectrow = index;
        this.dataForm.combinationId = combinationId;
      },
      changeRate(mixPowderProduct){
        this.dataForm.mixPowderProduct=mixPowderProduct;
      },
      changeState(){
        if(this.dataForm.state == '3'){
          this.selectrow = '';
          this.dataForm.combinationId = '';
          this.dataForm.mixPowderProduct = [];
        }
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            //判断微调比例和为1
            var products = this.dataForm.mixPowderProduct;
            if(null != products && products.length > 0 && this.dataForm.state == '2'){
              var allRate = 0;
              for(var i = 0;i<products.length;i++){
                allRate = (parseFloat(allRate) + parseFloat(products[i].modifyRate)).toFixed(2);
              }
              console.log(allRate);
              if(allRate != 1){
                this.$message.error("微调比例和要为1")
                this.dataLoading = false;
                return;
              }
            }
            if(''==this.dataForm.state || this.dataForm.state=='0'){
              this.$message.error("请选择采纳类型")
              this.dataLoading = false;
              return;
            }

            if('1' == this.dataForm.state && ('' == this.dataForm.combinationId || null == this.dataForm.combinationId)){
              this.$message.error("请选择采纳产品")
              this.dataLoading = false;
              return;
            }
            var params = {
              'id': this.dataForm.id || undefined,
              'remarks': this.dataForm.remarks,
              'state': this.dataForm.state,
              'selectCombination': this.dataForm.combinationId,
              'selectMixPowderProduct': this.dataForm.mixPowderProduct
            }
            update(params).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                	  this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .app-product-container {
    padding: 15px;
    background-color: #eaf1fc;
    position: relative;
  }

  .main {
    background: #fff;
  }
  .c_top {
    padding-bottom: 50px;
    border-bottom: 1px dotted #e0e3ec;
  }
  .c_main {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;
  }

  .content {
    background: #fff;
    padding: 5px;
  }
  .c_m_cards {
    justify-content: flex-start;
    // padding: 7.5px;
  }
  .c_m_c_img {
    padding: 10px;
    border-radius: 15px;
  }
  .c_t_i_l_top .el-input__inner {
    border: 0;
    border-radius: 0;
    min-width: 128px;
    height: 37px;
    background: rgba(245, 245, 245, 0.6);
    text-align: center;
  }
  .c_m_cards .el-card {
    width: 450px;
    /*height: 240px;*/
    margin-left: 19px;
    margin-bottom: 20px;
  }
  .c_top .el-color-picker__trigger .el-icon-arrow-down:before {
    content: url("../../../src/assets/img/seBan2.png") !important;
    font-size: 18px;
  }
  .c_top .el-icon-close:before {
    content: url("../../../src/assets/img/seBan2.png") !important;
    font-size: 18px;
  }
  .c_top .el-color-picker__trigger {
    border: none;
    padding: 0;
    margin: -10px;
    height: 40px;
    width: 38px;
    background-color: cornflowerblue;
  }
  .c_top .el-color-picker__color {
    border: none !important;
  }
  .el-color-dropdown__btns button {
    display: none !important;
  }
  .el-input-group__prepend {
    background-color: #e4e4e4;
    color: #303133;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 0px solid #dcdfe6;
    border-radius: 0px;
    padding: 0 20px;
    width: 0px;
    white-space: nowrap;
    height: 38px;
  }
  .w100 {
    width: 100px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .c_top {
    text-align: center;
  }
  .c_t_title {
    font-size: 28px;
    color: #666666;
    margin-bottom: 10px;
  }
  .c_t_title span {
    color: #009cff;
  }

  .c_t_i_l_top {
    display: flex;
    justify-content: center;
    background: rgba(245, 245, 245, 0.6);
    height: 40px;
    border: 1px #e5e5e5 solid;
    align-items: center;
  }
  .c_t_i_l_top .color {
    width: 23px;
    height: 23px;
  }
  .c_tips {
    color: #999999;
    font-size: 12px;
  }
  .btn {
    height: 40px;
    border-radius: 2px;
    margin-left: 10px;
  }
  .btn-input {
    height: 40px;
    border-radius: 2px;
    margin-left: 10px;
  }
  .c_t_colorcontent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: self-start;
    color: #ffffff;
    font-size: 18px;
    padding: 14px;
    margin: 10px 0 10px 0px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .c_t_colorcontent p {
    margin: 0;
  }
  .c_t_colorcontent img {
    width: 28px;
    height: 28px;
  }
  .c_m_c_img {
    width: 100%;
    height: 160px;
  }
  .c_m_c_img_{
    width: calc(100% - 20px);
    height: 140px;
    margin: 10px;
    border-radius: 5px;
  }
  .c_m_cards {
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
  }
  .c_m_c_bot {
    color: #333333;
    font-size: 15px;
    padding: 10px 10px;
  }
  .c_m_c_b_txt {
    font-size: 12px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .c_m_c_b_txt span {
    color: #999999;
    margin-right: 17px;
    width: 60px;
    display: inline-block;
  }
</style>
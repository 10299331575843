import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/device/deviceuser/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/device/deviceuser/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/device/deviceuser/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/device/deviceuser/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/device/deviceuser/delete',
    method: 'post',
    data: params
  })
}

// 用户类型下拉列表
export function roleTypeList (params) {
  return request({
    url: '/device/deviceuser/roleTypeList',
    method: 'post',
    data: params
  })
}

// 用户下拉列表
export function UserList (params) {
  return request({
    url: '/device/deviceuser/UserList',
    method: 'post',
    params: params
  })
}

//设备授权的设备信息
export function deviceInfo (params) {
  return request({
    url: '/device/deviceuser/deviceInfo',
    method: 'get',
    params: params
  })
}

//设备详细信息
export function device (params) {
  return request({
    url: '/device/device/deviceInfo',
    method: 'get',
    params: params
  })
}

//撤销授权
export function delDeviceUser (params) {
  return request({
    url: '/device/deviceuser/delete',
    method: 'post',
    params: params
  })
}
import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/mixpowder/mixpowder/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/mixpowder/mixpowder/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/mixpowder/mixpowder/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/mixpowder/mixpowder/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/mixpowder/mixpowder/delete',
    method: 'post',
    data: params
  })
}

//获取混粉推荐组合
export function searchMixPowder (params) {
  return request({
    url: '/mixpowder/mixpowder/searchMixPowder',
    method: 'post',
    data: params
  })
}

//导出混粉推荐组合
export function exportMixPowder (params) {
  return request({
    url: '/mixpowder/mixpowder/exportMixPowder',
    method: 'post',
    data: params
  })
}

//导出产品数据是获取导出时间，制作进度条
export function getSpeedOfProgress(params){
  return request({
      url: '/mixpowder/mixpowder/getSpeedOfProgress',
      method: 'get',
      params: params
    })
}



<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
// import {BarChart} from 'echarts/charts'
// require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";

const animationDuration = 6000;
import { selectCustomStatistics } from '@/api/modules/record'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "410px",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.selectCustomStatistics()
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart(data) {
      this.chart = echarts.init(this.$el, "macarons");

      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.map(item => item.userName),//["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              interval: 0,
              formatter:function(value){
                var ret = "";//拼接加\n返回的类目项 
                var maxLength = 8;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1){
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }else {
                  return value;
                }
              }
            },
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: "#000000",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            axisLine: {
              lineStyle: {
                color: "#000000",
                width: 0, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            stack: "vistors",
            barWidth: "60%",
            data: data.map(item => item.proNum),//[79, 52, 200, 334, 390, 330, 220],
            animationDuration,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#8BC7FF'
                }, {
                  offset: 1,
                  color: '#55ACFC'
                }]),
              }
            },
          },
        ],
      });
    },
    async selectCustomStatistics(value = ['', '']) {
      const params = {
        startTime: value[0] || '',
        endTime: value[1] || '',
        limit: 10
      }
      const { data } = await selectCustomStatistics(params)
      if (data && data.code === 0) {
        this.$nextTick(() => {
          this.initChart(data.data);
        });
        this.$emit('barchartemit', data.data)
      }
    }
  },
};
</script>

<template>
  <div class="app-container">
    <el-card class="box-pro">
      <div slot="header" class="clearfix">
        <span>标签筛选</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="labelNo" label="">
        <el-input v-model="dataForm.labelNo" placeholder="标签编号"  size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="labelName" label="">
        <el-input v-model="dataForm.labelName" placeholder="标签名称"  size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="labelEname" label="">
        <el-input v-model="dataForm.labelEname" placeholder="标签英文名"  size="small" clearable></el-input>
      </el-form-item>
      <el-form-item  prop="brandId" label="">
        <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌">
          <el-option label="全部" value=""></el-option>
          <el-option label="适用全部" value="0"></el-option>
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="isShow" label="">
        <el-select v-model="dataForm.isShow" size="small" placeholder="显示状态"
        >
          <el-option
                  v-for="item in  isShow"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('product:productlabel:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>
      </el-form-item>
    </el-form>
    </el-card>
    <el-card style="margin-top:30px">
    <div slot="header" class="clearfix">
      <span>标签列表</span>
      <div style="float: right; padding: 3px 0">
        <el-button v-if="isAuth('product:productlabel:save')" type="primary" @click="addOrUpdateHandle()" size="small">添加标签</el-button>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="labelNo"
        header-align="center"
        align="center"
        label="标签编号">
      </el-table-column>
      <el-table-column
        prop="labelName"
        header-align="center"
        align="center"
        label="标签名称">
      </el-table-column>
      <el-table-column
        prop="labelEname"
        header-align="center"
        align="center"
        label="标签英文名称">
      </el-table-column>
      <el-table-column
        prop="labelAlias"
        header-align="center"
        align="center"
        label="标签别名">
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="关联品牌">
      </el-table-column>
      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="标签说明">
      </el-table-column>
      <el-table-column
              prop="currentSort"
              header-align="center"
              align="center"
              label="当前排序">
      </el-table-column>
      <el-table-column
              prop="isShow"
              header-align="center"
              align="center"
              label="是否展示">
      </el-table-column>
      <el-table-column
              prop="createTime"
              header-align="center"
              align="center"
              label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.productLabelId)">编辑</el-button>
          <el-button type="text" size="small" v-if="scope.row.isShow=='隐藏'" @click="dataFormSubmit(scope.row.productLabelId,'0',scope.row.currentSort,scope.row.description)">展示</el-button>
          <el-button type="text" size="small" v-if="scope.row.isShow=='展示'" @click="dataFormSubmit(scope.row.productLabelId,'1',scope.row.currentSort,scope.row.description)">隐藏</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.productLabelId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-card>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import {list,del,add,update} from '@/api/modules/productlabel'
  import AddOrUpdate from './add-or-update'
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        dataForm: {
          key: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        brandList: [],
        isShow: [
          {
            value: '',
            label: '全部'
          },
          {
            value: '1',
            label: '隐藏'
          },
          {
            value: '0',
            label: '显示'
          }
        ],
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      this.getDataList()
      this.getBrandList();
    },
    mounted() {
      this.getDataList()
      this.getBrandList();
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
        this.getBrandList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      // 表单提交
      dataFormSubmit (id,isShow,currentSort,description) {
        console.log("id:"+id)
        console.log("isEnable:"+isShow)
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'productLabelId': id || undefined,
              'currentSort': currentSort,
              'isShow':isShow,
              'description':description
            }
            var tick = !id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                })
                this.getDataList();
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          labelNo: this.dataForm.labelNo,
          labelName: this.dataForm.labelName,
          labelEname: this.dataForm.labelEname,
          brandId: this.dataForm.brandId,
          isShow: this.dataForm.isShow,
          currency : this.dataForm.currency
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.productLabelId
        })
        this.$confirm(`是否删除当前标签`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

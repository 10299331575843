import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/customer/customer/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/customer/customer/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/customer/customer/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/customer/customer/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/customer/customer/delete',
    method: 'post',
    data: params
  })
}

// 导入客户信息
export function importExcel(params) {
  return request({
      url: '/customer/customer/importExcel',
      method: 'post',
      data: params
  })
}

// 获取列表
export function getcustomerList (params) {
  return request({
    url: '/customer/customer/getcustomerList',
    method: 'get',
    params: params
  })
}



<template>
    <sides-lip ref="SidesLip"
               @click="close()"
    >
        <div slot="soltForm">
        <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm"
                 label-width="120px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
            <div style="display: flex">
                <div style="width: 60%;">
                    <el-form-item label="实验订单号" prop="sampleOrderNo">
                        <el-input v-model="dataForm.productOrder" placeholder="实验订单号" size="small" style="width: 100%" maxlength="30" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="产品编号" prop="productNo">
                        <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" @input="getNoSeries()"
                                  style="width: 100%" maxlength="30" disabled></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="测量LAB值">
                        <div>
                            <el-button size="small" type="primary" class="mr10" @click="syncDevice()" :loading="loading">数据同步</el-button>
                        </div>
                    </el-form-item> -->
                   <el-form-item>
                       <span slot="label"><!-- <label style="color:#FA3D1E">*</label>--> LAB值</span> 
                        <el-row>
                            <el-col :span="8">
                        <el-form-item prop="lAve">
                        <el-input v-model="dataForm.lAve" placeholder="L值" class="w150 mr10" size="small"
                                  clearable maxlength="30" disabled></el-input>
                        </el-form-item>
                            </el-col>
                            <el-col :span="8">
                        <el-form-item prop="aAve">
                        <el-input v-model="dataForm.aAve" placeholder="A值" class="w150 mr10" size="small"
                                  clearable maxlength="30" disabled></el-input>
                        </el-form-item>
                            </el-col>
                            <el-col :span="8">
                        <el-form-item prop="bAve">
                        <el-input v-model="dataForm.bAve" placeholder="B值" class="w150 mr10" size="small"
                                  clearable maxlength="30" disabled></el-input>
                        </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="产品名称" prop="productName">
                        <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" style="width: 100%" maxlength="30" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-image style="width: 180px; height: 100px" :src="dataForm.picUrl" fit="contain"></el-image>
                    </el-form-item>
                </div>
            </div>
            <el-form-item label="">
                <el-collapse  @change="handleChange">
                    <el-collapse-item >
                        <span class="collapse-title" slot="title">查看测量数据</span>
                        <div>
                            <ul class="productLAB">
                                <li>
                                    <span v-for="x in productLAB.title" class="title">{{x}}</span>
                                </li>
                                <li v-for="(x,index) in productLAB.data">
                                    <span class="title">{{x}}</span>
                                    <el-input v-model="dataForm[`l${index+1}`]" placeholder="L值" class="w100" disabled="disabled"></el-input>
                                    <el-input v-model="dataForm[`a${index+1}`]" placeholder="A值" class="w100" disabled="disabled"></el-input>
                                    <el-input v-model="dataForm[`b${index+1}`]" placeholder="B值" class="w100" disabled="disabled"></el-input>
                                </li>
                            </ul>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-form-item>
            <el-form-item label="色差值">
                <el-input v-model="dataForm.e00Value" placeholder="色差值" size="small" style="width: 40%" disabled></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-collapse  @change="handleChange">
                    <el-collapse-item>
                        <span class="collapse-title" slot="title">查看参考标准</span>
                        <div style="display: flex" >
                            <div style="width: 60%">
                                <p class="ziti">小于0.3</p>
                                <p class="ziti">小于0.5</p>
                                <p class="ziti">大于2.0</p>
                            </div>
                            <div style="width: 40%">
                                <p class="ziti">小于1.0</p>
                                <p class="ziti">小于2.0</p>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-form-item>
            <div style="display: flex">
                    <div style="width: 70%;">
                        <el-form-item label="光泽值" prop="glossValue">
                            <el-input v-model="dataForm.glossValue" placeholder="光泽值" size="small"
                                    @input="selectGlossInfo()" style="width: 100%" disabled></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 30%;">
                        <el-form-item label="" prop="gloss" label-width="20px">
                            <el-select v-model="dataForm.gloss" size="small" placeholder=""
                                    style="width: 100%" disabled="disabled">
                                <el-option
                                        v-for="item in productGlossList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                        size="small"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item label="">
                    <el-collapse  @change="handleChange">
                        <el-collapse-item>
                        <span class="collapse-title" slot="title">查看参考标准</span>
                        <div style="display: flex" >
                            <div style="width: 60%">
                            <template v-for="(item,index) in glossList">
                                <p v-if="index%2==0" class="ziti">{{item.label}}</p>
                            </template>
                            </div>
                            <div style="width: 60%">
                            <template v-for="(item,index) in glossList">
                                <p v-if="index%2==1" class="ziti">{{item.label}}</p>
                            </template>
                            </div>
                        </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <div class="ces-main">
                    <search-form
                        ref="form"
                        size='small'
                        labelWidth = '120px'
                        :searchData = "searchData"
                        :searchForm = "searchForm"
                        :loading="attrLoading"
                    ></search-form>
                </div>
                <div style="display: flex">
                    <div style="width: 50%;">
                        <el-form-item label="标准色号" prop="ralColorNo">
                            <el-input v-model="dataForm.ralColorNo" placeholder="产品标准色号" size="small"
                                style="width: 100%" maxlength="30" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="固化温度" prop="temperature">
                            <el-input v-model="dataForm.temperature" placeholder="固化温度" size="small" maxlength="5" style="width:100%" disabled>
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%;">
                        <el-form-item label="固化条件" prop="solidifyId">
                            <el-select v-model="dataForm.solidifyId"  placeholder="请选择" @change="solidifyDD(dataForm.solidifyId)" style="width: 100%" disabled>
                                <el-option
                                        v-for="item in solidifyList"
                                        :key="item.solidifyId"
                                        :label="item.solidifyName"
                                        :value="item.solidifyId"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item label="固化时间" prop="solTime">
                            <el-input v-model="dataForm.solTime" placeholder="固化时间" size="small" maxlength="5"  style="width:100%" disabled>
                                <template slot="append">秒</template>
                            </el-input>
                            <!--</el-date-picker>-->
                        </el-form-item>
                    </div>
                </div>
            <div style="border: solid 1px silver;padding: 15px 15px;margin-bottom: 15px;">
                <span style="font-size: 18px;font-weight:bold;">性能测试数据</span>
                <el-divider style="width: 100px"></el-divider>
                <el-form-item label="性能组" prop="productPerformanceS">
                    <el-select v-model="dataForm.productPerformanceS" size="small" placeholder="请选择性能组" style="width: 40%" @change="getPerformance(dataForm.productPerformanceS)" disabled>
                        <el-option
                                v-for="item in performanceSList"
                                :key="item.id"
                                :label="item.performanceName"
                                :value="item.id"
                                size="small"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <div style="display: flex">
                    <div style="width: 50%">
                        <el-form-item label="膜厚" prop="temperature">
                            <el-input v-model="dataForm.thickness" placeholder="膜厚" size="small" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="弯曲" prop="solTime">
                            <el-input v-model="dataForm.bend" placeholder="弯曲" size="small" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="冲击" prop="solidifyDesc">
                            <el-input  v-model="dataForm.attack"  placeholder="冲击" size="small" disabled></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%">
                        <el-form-item label="划格" prop="temperature">
                            <el-input v-model="dataForm.delimit" placeholder="划格" size="small" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="杯突" prop="solTime">
                            <el-input v-model="dataForm.cupular" placeholder="杯突" size="small" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="其他" prop="solidifyDesc">
                            <el-input  v-model="dataForm.other" placeholder="其他"  size="small" disabled></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>
        </div>
        <div slot="soltFooter">
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">取消</el-button>
            <!--<el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>-->
        </span>
        </div>
    <!--</el-dialog>-->
    </sides-lip>
</template>

<script>
    import {
        queryNoSeries,
        queryNoColor,
        getFactoryBrand,
        selectGloss,
        syncDevice,
        syncDeviceData,
        info,
        add,
        update,
        getProductPerformance,
        getIndustryList,
        getDict,
        getColorSeriesList,
        getProductHierarchy,
        getProductSeries,
        getProductCategory,
        getFactory,
        getBrand,
        selectDevice,
        validateProductNo
    } from '@/api/modules/product'
    import {getProductPerformanceS} from '@/api/modules/sampleorder'
    import Tinymce from '@/components/Tinymce'
    import {getToken} from "@/utils/auth";
    import SidesLip from "@/components/SidesLip";
    import SearchForm from '@/components/formDisable.vue';
    import Cookies from 'js-cookie'
    import {getAttrAndValueListInfo,getGlossInfo,selectGlossInfo}from '@/api/modules/productattr'
    import {querySolidify,getSolidifyInFo} from "@/api/modules/recipe";
    import {recipeInfo,getE00Info} from "../../api/modules/sampleproduct";
    import {
        getProductAttrValue} from '@/api/modules/productattrvalue'
    import ElFormItem from "../../../node_modules/element-ui/packages/form/src/form-item.vue";
    export default {
        components: {
            ElFormItem,
            Tinymce, 
            SearchForm,
            SidesLip},
        data() {
            var validateProductNoOnly = (rule, value, callback) => {
                //校验产品编号唯一
                if (this.dataForm.productNo != "0" && this.dataForm.productNo != "") {
                    let params = {
                        productNo: this.dataForm.productNo,
                        id: this.dataForm.id,
                    }
                    validateProductNo(params).then(({ data }) => {
                        if (data && data.code === 0) {
                            if (data.merchant != null) {
                                return callback(new Error("商品编号已经存在，请重新填写"));
                            } else {
                                return callback();
                            }
                        } else {
                            return callback(new Error(data.msg));
                        }
                    });
                } else {
                    return callback();
                }
            };
            return {
                searchForm:[],
                searchData:{},
                productAttrId:'',
                attrValue:'',
                attrLoading:false,
                glossList: [],
                biaoZhun:false,
                showLAB:false,
                loading: false,
                device: {
                    deviceId:"",
                },
                action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
                visible: false,
                dataLoading: false,
                timer: null, //首先我在data函数里面进行定义定时器名称：
                timerNum: 5, // 设置定时器时间
                productLAB: {
                    title: ["角度", "L值", "A值", "B值"],
                    data: ["25度", "45度", "75度"]
                },
                FactoryBrand: {},
                dataForm: {
                    e00Value:'',
                    id: 0,
                    productOrder:'',
                    productNo: '',
                    productName: '',
                    makeFactory:'',
                    picUrl: '',
                    qrUrl: '',
                    ralColorNo: '',
                    gloss: '',
                    glossValue: '',
                    surface: '',
                    colorSeries: '',
                    productSeries: '',
                    productPerformance: '',
                    otherPerformance: '',
                    productCategory: '',
                    productHierarchy: '',
                    industryId: '',
                    factoryId: '',
                    brandId: '',
                    description: '',
                    l1: '',
                    a1: '',
                    b1: '',
                    l2: '',
                    a2: '',
                    b2: '',
                    l3: '',
                    a3: '',
                    b3: '',
                    lAve: '',
                    aAve: '',
                    bAve: '',
                    isOpen: '',
                    delFlag: '',
                    createBy: '',
                    createDate: '',
                    updateBy: '',
                    updateDate: '',
                    remarks: '',
                    solidifyId:'',
                    solTime: "",
                    temperature: "",
                    solidifyDesc: "",
                    thickness:'',
                    bend:'',
                    attack:'',
                    delimit:'',
                    cupular:'',
                    other:''
                },
                solidifyList: [],
                deviceList: [],
                performanceList: [],
                performanceSList: [],
                industryList: [],
                colorSeriesList: [],
                productCategoryList: [],
                otherPerformanceList: [],
                productSeriesList: [],
                productGlossList: [],
                productSurfaceList: [],
                productHierarchyList: [],
                factoryList: [],
                brandList: [],
                dataRule: {
                    productNo: [
                        {required: true, message: '产品编号不能为空', trigger: 'blur'},
                        { validator: validateProductNoOnly, trigger: "blur" }
                    ],
                    lAve: [
                        {required: true, message: 'L平均值不能为空', trigger: 'blur'}
                    ],
                     aAve: [
                        {required: true, message: 'A平均值不能为空', trigger: 'blur'}
                     ],
                    bAve: [
                         {required: true, message: 'B平均值不能为空', trigger: 'blur'}
                     ]
                }
            }
        },
        created() {
        },
        beforeDestroy() {
            window.clearInterval(this.timer)
            this.timer = null
            this.timerNum = 5
        },
        methods: {

            getProductAttrValue(){
                let params = {};
                getProductAttrValue(params).then(({data}) => {
                    if (data && data.code === 0) {
                        for(var i=0;i<data.list.length;i++){
                            if(data.list[i].productAttrType==='1'){//表面
                                this.productSurfaceList=data.list[i].productAttrValue;
                            }else if(data.list[i].productAttrType==='3'){//系列
                                this.productSeriesList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='6'){//主题
                                this.productHierarchyList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='2'){//色系
                                this.colorSeriesList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='7'){//性能
                                this.performanceList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='4'){//应用
                                this.industryList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='8'){//功能
                                this.otherPerformanceList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='5'){//分类
                                this.productCategoryList=data.list[i].productAttrValue;
                            } else if(data.list[i].productAttrType==='9'){//光泽
                                this.productGlossList=data.list[i].productAttrValue;
                            }

                        }
                    } else {

                    }
                })
            },

            getAttrAndValueListInfo: function () {
                this.searchData = {}
                this.searchForm = []
                this.tableCols = []
                var params = {
                    "type":"detail",
                    isUniversal:'0'
                }
                getAttrAndValueListInfo(params).then(({data}) => {
                    if (data && data.code === 0) {
                        data.data.forEach(e => {
                            var obj = {type:'',label:'', width:'180px'}
                            if(e.type == "1"){
                                // 如果是单选
                                obj.type = "Select"
                                obj.options = e.options
                                obj.prop = e.type+"-"+e.productAttrId
                                obj.label = e.value
                            }
                            if(e.type == "2"){
                                obj.type = "SelectMultiple"
                                obj.options = e.options
                                obj.prop = e.type+"-"+e.productAttrId
                                obj.value = e.value
                            }
                            if(e.type == "3"){
                                obj.type = "Cascader"
                                obj.options = e.options
                                obj.prop = e.type+"-"+e.productAttrId
                                obj.value = e.value
                            }
                            obj.label = e.label
                            this.$set(this.searchData,e.productAttrId,e.value)
                            this.searchForm.push(obj);
                        })
                    } else {
                        this.$message.error(data.msg);
                        this.loading2 = false;
                    }
                })
            },

            //色差值
            getE00Info(){
                let params ={
                    'lAve':this.dataForm.lAve,
                    'aAve':this.dataForm.aAve,
                    'bAve':this.dataForm.bAve,
                    'productId':this.dataForm.id
                }
                getE00Info(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataForm.e00 = data.e00;
                    } else {
                        this.dataForm.e00 = ''
                    }
                })
            },
            //性能组
            getPerformance(productPerformanceS){
                for(var x in this.performanceSList){
                    if(this.performanceSList[x].id == productPerformanceS){
                        this.dataForm.thickness = this.performanceSList[x].thickness
                        this.dataForm.bend = this.performanceSList[x].bend;
                        this.dataForm.attack = this.performanceSList[x].attack;
                        this.dataForm.delimit = this.performanceSList[x].delimit;
                        this.dataForm.cupular = this.performanceSList[x].cupular;
                        this.dataForm.other = this.performanceSList[x].other;
                    }
                }
            },
            solidifyDD(id){
                var params ={
                    id:id
                }
                //固化
                getSolidifyInFo(params).then(({ data }) => {
                    if (data.code == 0) {
                        this.dataForm.solidifyName = data.solidifyInFo.solidifyName;
                        this.dataForm.temperature = data.solidifyInFo.temperature;
                        this.dataForm.solTime = data.solidifyInFo.solTime;
                        this.dataForm.solidifyDesc = data.solidifyInFo.description;
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            },
            handleChange(val) {
                console.log(val);
            },
            //LAB值按钮隐藏显示
            isShowLAB(){
                this.showLAB = !this.showLAB;
            },
            isBiaoZhun(){
                this.biaoZhun = !this.biaoZhun;
            },
            //根据光泽测试值显示光泽范围名称
            getSelectGloss() {
                let params = {
                glossValue: this.dataForm.glossValue
                }
                selectGloss(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.dataForm.gloss = data.DictList.dictId;
                } else {
                    this.dataForm.gloss = ''
                }
                })
            },
            //根据光泽测试值显示光泽范围名称
            selectGlossInfo() {
                this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g,'') // 清除“数字”和“.”以外的字符
                this.dataForm.glossValue = this.dataForm.glossValue.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
                this.dataForm.glossValue = this.dataForm.glossValue.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                this.dataForm.glossValue = this.dataForm.glossValue.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3');//只能输入两个小数   
                if (this.dataForm.glossValue.indexOf(".") < 0 && this.dataForm.glossValue != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
                    this.dataForm.glossValue = parseFloat(this.dataForm.glossValue);
                }
                this.$set(this.searchData,'1-'+this.productAttrId,'')
                let params = {
                    glossValue: this.dataForm.glossValue ? parseInt(this.dataForm.glossValue) : ''
                }
                selectGlossInfo(params).then(({data}) => {
                    if (data && data.code === 0) {
                    console.log("name:"+data.name)
                    this.dataForm.gloss = data.data.name;
                    this.productAttrId = data.data.productAttrId;
                    this.attrValue = data.data.id;
                    this.$set(this.searchData,'1-'+this.productAttrId,this.attrValue);
                    } else {
                    this.dataForm.gloss = ''
                    }
                })
            },      
            // 查询光泽值范围
            getGlossInfo() {
                let params = {}
                getGlossInfo(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.glossList = data.data[0].options;
                } else {
                    this.glossList = ''
                }
                })
            },
            //固化
            getQuerySolidify(){
                //固化
                querySolidify().then(({ data }) => {
                    if (data.code == 0) {
                        this.solidifyList = data.solidifyList;
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            },
            //根据产品编号匹配产品系列
            getNoSeries() {
                this.dataForm.productName="TIGER_"+this.dataForm.productNo;
                if (this.dataForm.productNo.length > 4) {
                    return false;
                }

                let params = {
                    productNo: this.dataForm.productNo
                }

                queryNoSeries(params).then(({data}) => {
                    if (data && data.code === 0 && data.serHierIdVo) {
                        this.dataForm.productSeries = data.serHierIdVo.seriesId;
                        this.dataForm.industryId = data.serHierIdVo.industryId;
                    } else {
                        this.dataForm.productSeries = ''
                        this.dataForm.industryId = ''
                    }
                }),
                    queryNoColor(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.dataForm.colorSeries = data.colorId;
                        } else {
                            this.dataForm.colorSeries = ''
                        }
                    })
            },
            //根据产品编号匹配色系
            getNoColor() {
            },
            //查找设备
           /* queryDevice() {
                this.getselectDevice();
            },*/
            //设备同步
            syncDevice() {
                this.loading = true;
                this.device.deviceId = Cookies.get('deviceId')
                let params ={
                    deviceId:this.device.deviceId
                }
                syncDevice(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.timer = window.setInterval(() => {  //创建定时器
                            if (this.timerNum === 0) { // 设置的定时器时间为0后执行的操作
                                this.clearTimer(); // 关闭定时器
                                this.loading = false;
                                this.timerNum = 5;
                            } else {
                                //查看设备状态
                                this.syncDeviceTime(this.timerNum);
                            }
                        }, 1000);
                    } else {
                        this.$message.error(data.msg);
                        this.loading = false;
                    }
                })
            },

            //获取同步数据
            syncDeviceTime(timerNum) {
                let params = {
                    deviceId: this.device.deviceId
                }
                syncDeviceData(params).then(({data}) => {
                    if (data && data.code === 0) {
                        //this.dataForm.productNo = data.product.productNo
                        this.dataForm.picUrl = data.product.picUrl
                        this.dataForm.l1 = data.product.l1
                        this.dataForm.a1 = data.product.a1
                        this.dataForm.b1 = data.product.b1
                        this.dataForm.l2 = data.product.l2
                        this.dataForm.a2 = data.product.a2
                        this.dataForm.b2 = data.product.b2
                        this.dataForm.l3 = data.product.l3
                        this.dataForm.a3 = data.product.a3
                        this.dataForm.b3 = data.product.b3
                        this.dataForm.lAve = data.product.lAve
                        this.dataForm.aAve = data.product.aAve
                        this.dataForm.bAve = data.product.bAve
                        this.clearTimer(); // 关闭定时器
                        this.loading = false;
                    } else {
                        this.timerNum = timerNum - 1; // 定时器减1
                    }
                })
            },
            clearTimer() {//清除定时器
                window.clearInterval(this.timer);
                this.timer = null;
                this.timerNum = 5;
            },
            //选择设备显示在页面
            handleCommand(command) {
                //  console.log(command);
                //this.$message('click on item ' + command);
                this.device = command;
            },
            init(id,e00Value,sampleOrderNo) {
                console.log(id)
                console.log(e00Value)
                this.getAttrAndValueListInfo();
                this.getGlossInfo();
                //进来就调用获取所有下拉列表
                this.dataForm.id = id || 0;
                this.dataForm.e00Value = e00Value;
                this.$refs.SidesLip.init(this.dataForm.id?"详情":"详情", "800px", "block");
                this.getFactoryBrandList();
                this.getQuerySolidify();
                this.getProductPerformanceSList();
                this.getProductAttrValue();
                this.getFactoryList();
                this.getBrandList();
                this.loading = false;
                this.visible = true
                this.$nextTick(() => {
                    //清除缓存数据
                    this.$refs['dataForm'].resetFields();
                    this.dataForm.lAve = '';
                    this.dataForm.aAve = '';
                    this.dataForm.bAve = '';
                    this.dataForm.l1 = '';
                    this.dataForm.a1 = '';
                    this.dataForm.b1 = '';
                    this.dataForm.l2 = '';
                    this.dataForm.a2 = '';
                    this.dataForm.b2 = '';
                    this.dataForm.l3 = '';
                    this.dataForm.a3 = '';
                    this.dataForm.b3 = '';
                    if (this.dataForm.id) {
                        recipeInfo({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.solidifyId =data.recipe.solidifyId
                                this.dataForm.solTime =data.recipe.solTime
                                this.dataForm.temperature =data.recipe.temperature
                                this.dataForm.solidifyDesc =data.recipe.solidifyDesc
                            }
                        })
                        info({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.productOrder = sampleOrderNo
                                this.dataForm.productNo = data.product.productNo
                                this.dataForm.productName = data.product.productName
                                this.dataForm.makeFactory = data.product.makeFactory
                                this.dataForm.picUrl = data.product.picUrl
                                this.dataForm.qrUrl = data.product.qrUrl
                                this.dataForm.ralColorNo = data.product.ralColorNo
                                this.dataForm.gloss = data.product.gloss
                                this.dataForm.glossValue = data.product.glossValue
                                // this.dataForm.surface = data.product.surface
                                // this.dataForm.colorSeries = data.product.colorSeries
                                // this.dataForm.productSeries = data.product.productSeries
                                // this.dataForm.productPerformance = data.product.productPerformance
                                // this.dataForm.otherPerformance = data.product.otherPerformance
                                // this.dataForm.productCategory = data.product.productCategory
                                // this.dataForm.productHierarchy = data.product.productHierarchy
                                // this.dataForm.industryId = data.product.industryId
                                this.dataForm.factoryId = data.product.factoryId
                                this.dataForm.brandId = data.product.brandId
                                this.dataForm.description = data.product.description
                                this.dataForm.l1 = data.product.l1
                                this.dataForm.a1 = data.product.a1
                                this.dataForm.b1 = data.product.b1
                                this.dataForm.l2 = data.product.l2
                                this.dataForm.a2 = data.product.a2
                                this.dataForm.b2 = data.product.b2
                                this.dataForm.l3 = data.product.l3
                                this.dataForm.a3 = data.product.a3
                                this.dataForm.b3 = data.product.b3
                                this.dataForm.lAve = data.product.lAve
                                this.dataForm.aAve = data.product.aAve
                                this.dataForm.bAve = data.product.bAve

                                // 获取属性
                                this.searchData = data.resultMap
                                if(data.product.performanceVo){
                                    this.dataForm.productPerformanceS = data.product.performanceVo.performanceId
                                    this.dataForm.thickness = data.product.performanceVo.thickness
                                    this.dataForm.attack = data.product.performanceVo.attack
                                    this.dataForm.bend = data.product.performanceVo.bend
                                    this.dataForm.cupular = data.product.performanceVo.cupular
                                    this.dataForm.delimit = data.product.performanceVo.delimit
                                    this.dataForm.other = data.product.performanceVo.other
                                }
                                
                                //this.getNoSeries();
                            }
                         // this.getSelectGloss();
                        })
                    }
                })
            },
            close() {
                this.device = {};
               // this.visible = false;
                this.performanceList = [];
                // this.dataForm.lAve ='';
                // this.dataForm.aAve ='';
                // this.dataForm.bAve ='';
                //this.dataForm = {};
                //清除缓存数据
                this.$refs['dataForm'].resetFields();
                this.$refs.SidesLip.sideClose();
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.dataForm.id || undefined,
                            'productNo': this.dataForm.productNo,
                            'productName': this.dataForm.productName,
                            'makeFactory':this.dataForm.makeFactory,
                            'picUrl': this.dataForm.picUrl,
                            'qrUrl': this.dataForm.qrUrl,
                            'ralColorNo': this.dataForm.ralColorNo,
                            'gloss': this.dataForm.gloss,
                            'glossValue': this.dataForm.glossValue,
                            'surface': this.dataForm.surface,
                            'colorSeries': this.dataForm.colorSeries,
                            'productSeries': this.dataForm.productSeries,
                            'poductPerformance': this.dataForm.productPerformance,
                            'otherPerformance': this.dataForm.otherPerformance,
                            'productCategory': this.dataForm.productCategory,
                            'productHierarchy': this.dataForm.productHierarchy,
                            'industryId': this.dataForm.industryId,
                            'factoryId': '',
                            'brandId': '',
                            'description': this.dataForm.description,
                            'l1': this.dataForm.l1,
                            'a1': this.dataForm.a1,
                            'b1': this.dataForm.b1,
                            'l2': this.dataForm.l2,
                            'a2': this.dataForm.a2,
                            'b2': this.dataForm.b2,
                            'l3': this.dataForm.l3,
                            'a3': this.dataForm.a3,
                            'b3': this.dataForm.b3,
                            'lAve': this.dataForm.lAve,
                            'aAve': this.dataForm.aAve,
                            'bAve': this.dataForm.bAve,

                        }
                       // console.log(this.dataForm.id)
                        var tick = !this.dataForm.id ? add(params) : update(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.device.deviceNo = '';
                                       // this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                        this.$refs.SidesLip.sideClose();
                                    }
                                })
                            } else {
                                this.device.deviceNo = '';
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            },
            //上传产品图处理
            handleUploadSuccess(res, file, type) {
                this.dataForm.picUrl = res.data.fileUrl;
            },
            //上传前对图片大小及格式进行判断
            beforeUpload(file) {
                const isJPG = (file.type === "image/jpeg" || file.type === "image/bmp" || file.type === "image/png");
                const isLt2M = file.size / 1024 / 1024 < 8;

                if (!isJPG) {
                    this.$message.error("上传图片格式不支持!");
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 8MB!");
                }
                return isJPG && isLt2M;
            },
            //获取已授权设备
            getselectDevice() {
                let params = {}
                selectDevice(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.deviceList = data.deviceList;
                        this.device.deviceId = data.deviceList.length > 0 ? data.deviceList[0].deviceId : [];
                    } else {
                        this.deviceList = []
                    }
                })
            },
            //获取产品性能组选项
            getProductPerformanceSList() {
                let params = {}
                getProductPerformance(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.performanceSList = data.performanceList
                    } else {
                        this.performanceSList = []
                    }
                })
            },
            //获取产品性能选项
            getProductPerformanceList() {
                let params = {}
                getProductPerformanceS(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.performanceList = data.performanceList
                    } else {
                        this.performanceList = []
                    }
                })
            },
            //获取行业选择表
            getIndustry() {
                let params = {}
                getIndustryList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.industryList = data.list
                    } else {
                        this.industryList = []
                    }
                })
            },
            //获取色系选择表
            getColorSeries() {
                let params = {}
                getColorSeriesList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.colorSeriesList = data.list
                    } else {
                        this.colorSeriesList = []
                    }
                })
            },
            //获取产品分类列表
            getProductCategoryList() {
                let params = {}
                getProductCategory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productCategoryList = data.list
                    } else {
                        this.productCategoryList = []
                    }
                })
            },
            //获取其他性能
            getDictList() {
                let params = {
                    type: '1'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.otherPerformanceList = data.list
                    } else {
                        this.otherPerformanceList = []
                    }
                })
            },
            //获取产品系列表
            getProductSeriesList() {
                let params = {}
                getProductSeries(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSeriesList = data.list
                    } else {
                        this.productSeriesList = []
                    }
                })
            },

            //获取产品表面选项
            getProductSurfaceList() {
                let params = {
                    type: '2'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSurfaceList = data.list
                    } else {
                        this.productSurfaceList = []
                    }
                })
            },
            //获取产品光泽选项
            getProductGlossList() {
                let params = {
                    type: '3'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productGlossList = data.list
                    } else {
                        this.productGlossList = []
                    }
                })
            },
            //获取产品体系
            getProductHierarchyList() {
                let params = {}
                getProductHierarchy(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productHierarchyList = data.list
                    } else {
                        this.productHierarchyList = []
                    }
                })
            },
            //获取工厂和品牌
            getFactoryBrandList() {
                let params = {}
                getFactoryBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.FactoryBrand = data.factoryBrand
                    } else {
                        this.FactoryBrand = {}
                    }
                })
            },
            //获取工厂列表
            getFactoryList() {
                let params = {}
                getFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.factoryList = data.list
                    } else {
                        this.factoryList = []
                    }
                })
            },
            //获取品牌列表
            getBrandList() {
                let params = {}
                getBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.brandList = data.list
                    } else {
                        this.brandList = []
                    }
                })
            },

        }
    }

</script>
<style lang="scss">
    .avatar-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            margin-left: 20px;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
    }
</style>
<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 178px;
        text-align: center;
    }

    .ziti {
        font-size: 12px;
        margin: 0px;
        line-height: 25px;
    }

    .avatar {
        width: 178px;
        max-height: 178px;
        display: block;
    }

    .productLAB {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        margin-left: -50px;
        li {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 10px;
            .title {
                display: inline-block;
                width: 100px;
                text-align: center;
                color: #606266;
                font-size: 700;
            }
        }

    }

    .w100 {
        width: 100px;
    }
    .w150 {
        width: 90px;
    }
    .mr10 {
        margin-right: 5px;
    }
    .collapse-title {
        flex: 1 0 90%;
        color: #4AB7BD;
        font-weight: bolder;
        text-align: center;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 18px;
    }
</style>

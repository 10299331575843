<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="类别编号" prop="no">
        <el-input v-model="dataForm.no" placeholder="类别编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="类别名称" prop="label">
        <el-input v-model="dataForm.label" placeholder="类别名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="类别说明" prop="remarks">
        <el-input v-model="dataForm.remarks" placeholder="类别说明"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,colorBooksave,colorBookupdate} from '@/api/modules/dict'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          no: '',
          label: '',
          value: '',
          type: '',
          brandId: '',
          sort: '',
          state: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: ''
        },
        dataRule: {
          no: [
            { required: true, message: '类别编号不能为空', trigger: 'blur' }
          ],
          label: [
            { required: true, message: '类别名称不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.no = data.dict.no
                this.dataForm.label = data.dict.label
                this.dataForm.value = data.dict.value
                this.dataForm.type = data.dict.type
                this.dataForm.brandId = data.dict.brandId
                this.dataForm.sort = data.dict.sort
                this.dataForm.state = data.dict.state
                this.dataForm.delFlag = data.dict.delFlag
                this.dataForm.createBy = data.dict.createBy
                this.dataForm.createDate = data.dict.createDate
                this.dataForm.updateBy = data.dict.updateBy
                this.dataForm.updateDate = data.dict.updateDate
                this.dataForm.remarks = data.dict.remarks
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'no': this.dataForm.no,
              'label': this.dataForm.label,
              'value': this.dataForm.value,
              'type': this.dataForm.type,
              'brandId': this.dataForm.brandId,
              'sort': this.dataForm.sort,
              'state': this.dataForm.state,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remark': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? colorBooksave(params) : colorBookupdate(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

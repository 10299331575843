<template>
  <el-dialog
    :title="!dataForm.productNoRuleId ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="125px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="规则名称" prop="ruleName">
        <el-input v-model="dataForm.ruleName" placeholder="规则名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="品牌"  prop="brandId" >
        <!--<el-input v-model="dataForm.brandId" placeholder="品牌"  size="small"></el-input>-->
        <!--品牌选择标签-->
        <el-select v-model="dataForm.brandId" size="small" :disabled="dataForm.productNoRuleId?true:false"  style="width: 80%" @change="getChoose()" placeholder="请选择品牌" >
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联类型" prop="relationType">
        <span slot="label">
            <span class="span-box" title="设置当前规则是属于属性规则或标签规则" style="cursor:pointer">
                <span>关联类型</span>
                <i class="el-icon-question" style="margin-left:3px;"></i>
            </span>
        </span>
        <el-select v-model="dataForm.relationType" :disabled="dataForm.productNoRuleId?true:false"  @change="setType(dataForm.relationType)" size="small" style="width: 80%" placeholder="请选择关联类型" >
          <el-option
                  v-for="item in  relationType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="this.chooseBrand =='1'" label="关联属性" prop="productAttrId">
        <el-select v-model="dataForm.productAttrId" :disabled="dataForm.productNoRuleId?true:false"  size="small" style="width: 80%" @change="selectAttrValueList(dataForm.productAttrId)" placeholder="请选择关联属性" >
          <el-option
                  v-for="item in  productAttrList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="匹配开始位置" prop="matchStart">
        <span slot="label">
            <span class="span-box" title="设置当前规则获取产品编号的开始位置" style="cursor:pointer">
                <span>匹配开始位置</span>
                <i class="el-icon-question" style="margin-left:3px;"></i>
            </span>
        </span>
        <el-input v-model="dataForm.matchStart" oninput="value=value.replace(/[^\-\d.]/g,'')" placeholder="匹配开始位置" size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="关联位数" prop="matchLength">
        <span slot="label">
            <span class="span-box" title="设置当前规则获取产品编号的个数" style="cursor:pointer">
                <span>关联位数</span>
                <i class="el-icon-question" style="margin-left:3px;"></i>
            </span>
        </span>
        <el-input v-model="dataForm.matchLength" oninput="value=value.replace(/[^\-\d.]/g,'')" placeholder="关联位数" size="small" style="width: 80%"></el-input>
      </el-form-item>

      <el-table v-if="this.chooseBrand == '1'"
              :data="dataList"
              border
              size="small"
              v-loading="dataListLoading"
              style="width: 100%;">
        <el-table-column
                type="input"
                prop="relationRole"
                header-align="center"
                align="center"
                label="关联规则">
              <template scope="scope" >
                <el-input size="small" v-model="scope.row.relationRole" placeholder="请输入内容"></el-input>
              </template>
        </el-table-column>
        <el-table-column
                prop="valueName"
                header-align="center"
                align="center"
                label="属性值名称">
        </el-table-column>
        <el-table-column
                prop="valueNo"
                header-align="center"
                align="center"
                label="属性值编号">
        </el-table-column>
        <el-table-column
                prop="attrValueLevel"
                header-align="center"
                align="center"
                label="属性值级别">
        </el-table-column>
      </el-table>
      <el-table v-if="this.chooseBrand == '0'"
              :data="labelDataList"
              border
              size="small"
              v-loading="dataListLoading"
              style="width: 100%;">
        <el-table-column
                type="input"
                prop="relationRole"
                header-align="center"
                align="center"
                label="关联规则">
              <template scope="scope" >
                <el-input size="small" v-model="scope.row.relationRole" placeholder="请输入内容"></el-input>
              </template>
        </el-table-column>
        <el-table-column
                prop="labelName"
                header-align="center"
                align="center"
                label="标签名称">
        </el-table-column>
        <el-table-column
                prop="labelNo"
                header-align="center"
                align="center"
                label="标签编号">
        </el-table-column>
      </el-table>

    </el-form>
    <span slot="footer" class="dialog-footer" >
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,updateNoRuleAndAttrValue} from '@/api/modules/productnorule'
  import {getBrand} from '@/api/modules/product'
  import {list} from '@/api/modules/productlabel'
  import {updateAttrValue,getproductAttrInfo,selectAttrValueList} from '@/api/modules/productattrvalue'
  export default {
    data () {

      return {
        dataList: [],
        labelDataList: [],

        // 新增表单的验证规则
        rules: {
          fieldSort: [
            { required: true, message: "请输入排序", trigger: "change" },
            {
              validator: (rule, value, callback) => {
                if (value < 0) {
                  callback(new Error("必须大于0"));
                } else if (value.length > 5) {
                  callback(new Error("不超过5位数字"));
                } else if (!value) {
                  callback(new Error("排序不能为空"));
                } else {
                  callback();
                }
              },
              trigger: "change"
            }
          ]
        },
        visible: false,
        dataLoading: false,
        updateLoading:false,
        dataListLoading:false,
        productAttrList:[],
        dataForm: {
          formList: [
            {
              relationRole: "",
              productNoRuleValueId: "",
              productNoRuleId:'',
              relationValueId:'',
              relationValueName:''
            }
          ],
          productNoRole:[],
          productNoRoleValue:{
            relationRole:'',
            productAttrValueId:''
          },
          productAttrId: '',
          productNoRuleId: 0,
          ruleName: '',
          brandId: '',
          relationType: '',
          relationAttrId: '',
          matchStart: '',
          matchLength: '',
          isEnable: '',
          createBy: '',
          createTime: '',
        },
        chooseBrand:'',

        relationType: [],
        relationTypeInfo: [
          {
            value: '1',
            label: '属性'
          },
          {
            value: '2',
            label: '标签'
          }
        ],

        brandList: [],
        dataRule: {
          ruleName: [
            { required: true, message: '规则名称不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌id不能为空', trigger: 'blur' }
          ],
          relationType: [
            { required: true, message: '关联类型不能为空', trigger: 'blur' }
          ],
          relationId: [
            { required: true, message: '关联属性id不能为空', trigger: 'blur' }
          ],
          matchStart: [
            { required: true, message: '匹配开始位置不能为空', trigger: 'blur' }
          ],
          matchLength: [
            { required: true, message: '关联位数不能为空', trigger: 'blur' }
          ],
          isEnable: [
            { required: true, message: '是否启用（0：关闭，1：启用）不能为空', trigger: 'blur' }
          ],
          currentSort: [
            { required: true, message: '当前排序不能为空', trigger: 'blur' }
          ],
          isDelete: [
            { required: true, message: '是否删除（0：否，1：是）不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createTime: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateTime: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    created(){
      this.getBrandList();
      this.getproductAttrInfo(this.dataForm.brandId);
    },
    methods: {
      getChoose(){
        this.dataForm.productAttrId = ''
        this.productAttrList = []
        this.dataList = []
        this.labelDataList = []
        this.dataForm.relationType = ''
        this.relationType = this.relationTypeInfo;
      },
      //产品属性值列表
      selectAttrValueList(){
        // this.productAttrId = null;
        this.dataListLoading = true
        var params = {
          productAttrId: this.dataForm.productAttrId,
          fatherId : '1',
          brandId : this.dataForm.brandId
        }
        selectAttrValueList(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 获取数据列表
      getLabelDataList (param) {
        this.dataListLoading = true
        var params = {
          isShow : '0',
          brandId: param
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.labelDataList = data.page.list
          } else {
            this.dataList = []
          }
          this.dataListLoading = false
        })
      },

      setType(params){
        // 属性
        if(params === '1'){
          this.chooseBrand = '1'
          this.getproductAttrInfo(this.dataForm.brandId);
        }else{
          this.chooseBrand = '0'
          this.dataForm.productAttrId = ''
          this.productAttrList = []
          this.dataList = []
          this.getLabelDataList(this.dataForm.brandId)
        }
      },
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      //产品属性列表
      getproductAttrInfo(param){
        this.dataForm.productAttrId = null;
        this.dataForm.fatherId = null;
        this.dataList = [];
        let params = {
          brandId : param
        };
        getproductAttrInfo(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.productAttrList = data.list;
          } else {
            this.productAttrList = [];
          }
        });
      },
      init (id,brandId) {
        console.log("id::"+id)
        this.getBrandList();
        this.productAttrList = [],
        this.dataForm.relationType = ''
        this.chooseBrand = ''
        this.relationType =[]
        this.dataList = [];
        this.dataForm.productNoRuleId = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.productNoRuleId) {
            var params = {
              productNoRuleId:this.dataForm.productNoRuleId,
              brandId: brandId || ''
            }
            info(params).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.ruleName = data.productNoRule.ruleName
                this.dataForm.brandId = data.productNoRule.brandId
                this.relationType = this.relationTypeInfo
                this.dataForm.relationType = data.productNoRule.relationType
                if(this.dataForm.brandId){
                  this.getproductAttrInfo(this.dataForm.brandId)
                  this.getLabelDataList(this.dataForm.brandId)
                }
                this.dataForm.productAttrId = data.productNoRule.relationId
                if(this.dataForm.relationType === '1'){
                  this.selectAttrValueList();
                  this.chooseBrand = '1'
                }else{
                  this.chooseBrand = '0'
                }
                this.dataForm.relationId = data.productNoRule.relationId
                this.dataForm.matchStart = data.productNoRule.matchStart
                this.dataForm.matchLength = data.productNoRule.matchLength
                this.dataForm.isEnable = data.productNoRule.isEnable
                this.dataForm.createBy = data.productNoRule.createBy
                this.dataForm.createTime = data.productNoRule.createTime
                this.dataForm.formList = data.productNoRule.productNoRuleValueVoList
              }
            })
          }
        })
      },

      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'productAttrValueDtoList':this.dataList,
              'productLabelDtoList':this.labelDataList,
              'productNoRuleId': this.dataForm.productNoRuleId || undefined,
              'ruleName': this.dataForm.ruleName,
              'brandId': this.dataForm.brandId,
              'relationType': this.dataForm.relationType,
              'relationId': this.dataForm.productAttrId,
              'matchStart': this.dataForm.matchStart,
              'matchLength': this.dataForm.matchLength,
              'isEnable': this.dataForm.isEnable,
              'currentSort': this.dataForm.currentSort,
              'isDelete': this.dataForm.isDelete,
              'createBy': this.dataForm.createBy,
              'createTime': this.dataForm.createTime,
              'updateBy': this.dataForm.updateBy,
              'updateTime': this.dataForm.updateTime,
              'version': this.dataForm.version
            }
            var tick = !this.dataForm.productNoRuleId ? updateNoRuleAndAttrValue(params) : updateNoRuleAndAttrValue(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

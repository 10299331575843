<template>
    <div class="app-container">
        <el-row :gutter="12">
            <el-col :span="24">
                <el-row :gutter="2">
                    <div style="float:right;">
                        <el-tabs v-model="barActiveName" type="card" @tab-click="handleBarClick">
                            <el-tab-pane label="近7天" name="first"></el-tab-pane>
                            <el-tab-pane label="近30天" name="second"></el-tab-pane>
                            <el-tab-pane label="自定义" name="third"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <div style="float:right" v-show="barActiveName == 'third'">
                        <el-date-picker
                                    v-model="createDates"
                                    value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="-"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="handleBarClick">
                        </el-date-picker>
                    </div>
                </el-row>
                <el-row :gutter="10">
                    <div>
                        <el-card shadow="never">
                            <div id="baractiveNoDate" style="width: 100%;height: 500px;text-align:center;padding-top: 250px;display:none"><span>暂无数据</span></div>
                            <div id="baractiveSeries"  style="width: 100%;height: 500px"></div>
                        </el-card>
                    </div>
                </el-row>
            </el-col>
        </el-row>

        <el-row :gutter="12" style="height: 500px;">
            <el-col :span="12">
                <!-- <el-tabs v-model="attrActive" type="card" @tab-click="handleAttrActiveClick">
                    <el-tab-pane label="今日" name="first"></el-tab-pane>
                    <el-tab-pane label="近7天" name="second"></el-tab-pane>
                    <el-tab-pane label="近30天" name="third"></el-tab-pane>
                </el-tabs> -->
                <el-card shadow="never">
                    <div id="attrsNoDate" style="width: 100%;height: 500px;text-align:center;padding-top: 250px;display:none"><span>暂无数据</span></div>
                    <div id="attrs"  style="width: 100%;height: 530px;padding-top: 30px;"></div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <!-- <el-tabs v-model="colorSeriesActive" type="card" @tab-click="handleColorSeriesActiveClick">
                    <el-tab-pane label="今日" name="first"></el-tab-pane>
                    <el-tab-pane label="近7天" name="second"></el-tab-pane>
                    <el-tab-pane label="近30天" name="third"></el-tab-pane>
                </el-tabs> -->
                <el-card shadow="never">
                    <el-select v-model="productAttr" size="small" placeholder="产品属性" @change="findProductAttrValueDir">
                        <el-option
                            v-for="item in productAttrList"
                            :key="item.productAttrId"
                            :label="item.attrName"
                            :value="item.productAttrId"
                            size="small"
                        ></el-option>
                    </el-select>
                    <div id="colorSeriesNoDate" style="width: 100%;height: 500px;text-align:center;padding-top: 250px;display:none"><span>暂无数据</span></div>
                    <div id="colorSeries"  style="width: 100%;height: 500px"></div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import {selectProductAttrList}from '@/api/modules/productattr'
    import {findBarValueDir, findProductAttrValueDir, findProductAttrDir} from '@/api/modules/record'

    export default {
        data() {
            return {
                dataList:[],
                productAttrList:[],
                operationList:[{label:'全部',value:''},{label:'完全匹配',value:'2'},{label:'配色可参考',value:'4'},{label:'不匹配',value:'3'}],
                operation:'',
                productAttr:'',
                productAttrName:'全部',
                barActiveName: 'first',
                colorSeriesActive:'first',
                attrActive:'first',
                barActiveType:'1',
                colorSeriesActiveType:'1',
                attrActiveType:'1',
                baractiveSeries:'',
                findColorSeries:'',
                productBarSeries:{
                    barx:[],
                    bary:[]
                },
                attrSeries:'',
                createDates:[]
            }
        },

        created() {
        },
        mounted(){
            this.selectProductAttrList();
            this.findBarValueDir();
            this.findProductAttrDir();
            this.findProductAttrValueDir();
        },
        methods: {

            selectProductAttrList(){
                this.productAttrList = []
                var params = {
                    isUniversal:'0'
                }
                selectProductAttrList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productAttrList = data.productAttrList;
                        this.productAttr = data.productAttrList[0].productAttrId
                        this.findProductAttrValueDir();
                    } else {
                        this.$message.error(data.msg);
                    }
                })
            },

            handleBarClick(){
                if(this.barActiveName==="first"){
                    this.barActiveType="1"
                }else if(this.barActiveName==="second"){
                    this.barActiveType="2"
                }else {
                    this.barActiveType="3"
                }

                this.findBarValueDir();
                this.findProductAttrDir();
                this.findProductAttrValueDir();
            },

            findBarValueDir() {
                var arrOrderTime = this.createDates;
                var startDate = null;
                var endDate = null;
                if (arrOrderTime && arrOrderTime.length > 0) {
                    startDate = arrOrderTime[0];
                    endDate = arrOrderTime[1];
                }
                var params = {
                    'type': this.barActiveType,
                    'startDate': startDate,
                    'endDate': endDate
                };
                findBarValueDir(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productBarSeries.barx=data.productAddBar.barx;
                        this.productBarSeries.bary=data.productAddBar.bary;
                        // console.log("barx", this.productBarSeries.barx);
                        // console.log("bary", this.productBarSeries.barx);
                        this.initBarSeries();
                    } else {
                    }
                })
            },

            initBarSeries(){
                if(this.productBarSeries.barx==null || this.productBarSeries.barx==0){
                    document.getElementById("baractiveNoDate").style.display="";
                    document.getElementById("baractiveSeries").style.display="none";
                    return;
                } else {
                    document.getElementById("baractiveNoDate").style.display="none";
                    document.getElementById("baractiveSeries").style.display="";
                }
                var allBar = this.productBarSeries.barx;
                var allBarDetail = this.productBarSeries.bary;
                // console.log("x值",JSON.stringify(allBar));
                // console.log("y值",JSON.stringify(allBarDetail));
                var activeBar = echarts.init(document.getElementById('baractiveSeries'));
                let option = null;
                var titleName="";
                var arrOrderTime = this.createDates;
                var startDate = null;
                var endDate = null;
                if (arrOrderTime && arrOrderTime.length > 0) {
                    startDate = arrOrderTime[0];
                    endDate = arrOrderTime[1];
                }
                if(this.barActiveName==="first"){
                    titleName="近7日新增产品趋势图";
                }else if(this.barActiveName==="second"){
                    titleName="近30日新增产品趋势图";
                }else {
                    if(arrOrderTime && arrOrderTime.length > 0){
                        titleName=startDate+'~'+endDate+"时间内新增产品趋势图";
                    } else {
                        titleName="新增产品趋势图";
                    }
                }
                option = {
                    title: {
                        text: titleName,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer : {
                            type : 'shadow'
                        }
                    },
                    xAxis : [
                        {
                            type : 'category',
                            data : allBar,
                            axisTick: {
                            alignWithLabel: true
                            }
                        }
                        ],
                    yAxis : [
                        {
                            type : 'value'
                        }
                    ],
                    series: [
                        {
                            name:'新增',
                            type:'line',
                            barWidth: '60%',
                            data:allBarDetail
                        }
                    ]
                };

                if (option && typeof option === "object") {
                    activeBar.setOption(option, true);
                }
            },

            initColorSeries(){
                if(this.findColorSeries==null || this.findColorSeries.length==0){
                    document.getElementById("colorSeriesNoDate").style.display="";
                    document.getElementById("colorSeries").style.display="none";
                    return;
                } else {
                    document.getElementById("colorSeriesNoDate").style.display="none";
                    document.getElementById("colorSeries").style.display="";
                }
                var allPie = this.findColorSeries;
                var activeDiscount = echarts.init(document.getElementById('colorSeries'));
                let option = null;
                option = {
                    title: {
                        text: "产品属性统计",
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    
                    series: [
                        {
                            name: this.productAttrName,
                            type: 'pie',
                            radius: ['20%', '40%'],
                            labelLine: {
                                length: 20,
                            },
                            label: {
                                //formatter: '{a|{a}}{abg|}\n{hr|}\n {c} {per|{d}%}',
                                formatter: '{b|{b}：}{c}  {per|{d}%}  ',
                                backgroundColor: '#F6F8FC',
                                borderColor: '#8C8D8E',
                                borderWidth: 1,
                                borderRadius: 4, 
                                rich: {
                                a: {
                                    color: '#6E7079',
                                    lineHeight: 22,
                                    align: 'center'
                                },
                                hr: {
                                    borderColor: '#8C8D8E',
                                    width: '100%',
                                    borderWidth: 1,
                                    height: 0
                                        },
                                b: {
                                    color: '#4C5058',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    lineHeight: 33
                                },
                                per: {
                                    color: '#fff',
                                    backgroundColor: '#4C5058',
                                    padding: [3, 4],
                                    borderRadius: 4
                                }
                }                           
                            },
                            data: allPie
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },

            initAttrs(){
                if(this.attrSeries==null || this.attrSeries.length==0){
                    document.getElementById("attrsNoDate").style.display="";
                    document.getElementById("attrs").style.display="none";
                    return;
                } else {
                    document.getElementById("attrsNoDate").style.display="none";
                    document.getElementById("attrs").style.display="";
                }
                var allPie = this.attrSeries;
                console.log("x值",JSON.stringify(allPie));
                var activeDiscount = echarts.init(document.getElementById('attrs'));
                let option = null;
                option = {
                    title: {
                        text: "客户产品统计",
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    
                    series: [
                        {
                            
                            type: 'pie',
                            radius: ['20%', '40%'],
                            labelLine: {
                                length: 30,
                            },
                            label: {
                                //formatter: '{a|{a}}{abg|}\n{hr|}\n {c} {per|{d}%}',
                                formatter: '{b|{b}：}{c}  {per|{d}%}  ',
                                backgroundColor: '#F6F8FC',
                                borderColor: '#8C8D8E',
                                borderWidth: 1,
                                borderRadius: 4, 
                                rich: {
                                    a: {
                                        color: '#6E7079',
                                        lineHeight: 22,
                                        align: 'center'
                                    },
                                    hr: {
                                        borderColor: '#8C8D8E',
                                        width: '100%',
                                        borderWidth: 1,
                                        height: 0
                                    },
                                    b: {
                                        color: '#4C5058',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        lineHeight: 33
                                    },
                                    per: {
                                        color: '#fff',
                                        backgroundColor: '#4C5058',
                                        padding: [3, 4],
                                        borderRadius: 4
                                    }
                                }                           
                            },
                            data: allPie
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },

            findProductAttrValueDir(data) {
                if(this.productAttr == null || this.productAttr == ''){
                    this.productAttrList.forEach((item, index) => {
                        if(index == 0){
                            this.productAttr = item.productAttrId
                        }
                    })
                    if(data == null || data == ''){
                        data = this.productAttr;
                    }
                }
                var arrOrderTime = this.createDates;
                var startDate = null;
                var endDate = null;
                if (arrOrderTime && arrOrderTime.length > 0) {
                    startDate = arrOrderTime[0];
                    endDate = arrOrderTime[1];
                }
                var params = {
                    type: this.barActiveType,
                    productAttr: this.productAttr,
                    'startDate': startDate,
                    'endDate': endDate
                };
                var objVal = '';
                this.productAttrList.forEach((val)=>{
                    if(val.productAttrId==data){
                        objVal=val.attrName;
                    }
                })
                this.productAttrName=objVal;
                findProductAttrValueDir(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.findColorSeries=data.colorSeries;
                        this.initColorSeries();
                    } else {
                    }

                })
            },

            findProductAttrDir() {
                var arrOrderTime = this.createDates;
                var startDate = null;
                var endDate = null;
                if (arrOrderTime && arrOrderTime.length > 0) {
                    startDate = arrOrderTime[0];
                    endDate = arrOrderTime[1];
                }
                var params = {
                    type: this.barActiveType,
                    'startDate': startDate,
                    'endDate': endDate
                };
                findProductAttrDir(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.attrSeries=data.attrs;
                        this.initAttrs();
                    } else {
                    }

                })
            },
    
            handleColorSeriesActiveClick(tab, event){
                if(this.colorSeriesActive ==="first"){
                    this.colorSeriesActiveType="1"
                }else if(this.colorSeriesActive ==="second"){
                    this.colorSeriesActiveType="2"
                }else if(this.colorSeriesActive ==="third"){
                    this.colorSeriesActiveType="3"
                }
                this.findProductAttrValueDir();
            },
            handleAttrActiveClick(tab, event){
                if(this.attrActive ==="first"){
                    this.attrActiveType="1"
                }else if(this.attrActive ==="second"){
                    this.attrActiveType="2"
                }else if(this.attrActive ==="third"){
                    this.attrActiveType="3"
                }
                this.findProductAttrDir();
            }
        }
    }
</script>

<style>
    .el-tabs__nav.is-top {
        float: right;
    }

    .el-tabs__header.is-top {
        margin: 0;
    }
</style>

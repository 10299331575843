<template>
  <div class="app-container">
    <!--<el-dialog
            :title="!dataForm.id ? '查看配方' : '查看配方'"
            :close-on-click-modal="false"
            :visible.sync="recipeListVisible"
            append-to-body>
      <div>-->
    <sides-lip ref="SidesLip"
               :close-on-click-modal="false"
    >
      <div slot="soltForm" style="margin-bottom: 20px">
        <el-table :data="dataList"
                  border
                  v-loading="dataListLoading"
                  @selection-change="selectionChangeHandle"
                  style="width: 100%;">
          <el-table-column
                  prop="materialNo"
                  header-align="center"
                  align="center"
                  label="材料编号">
          </el-table-column>
          <el-table-column
                  prop="materialName"
                  header-align="center"
                  align="center"
                  label="材料名称">
          </el-table-column>
          <el-table-column
                  prop="value"
                  header-align="center"
                  align="center"
                  label="计量值">
            <template slot-scope="scope">
              <span>{{scope.row.value|numFilter}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="units"
                  header-align="center"
                  align="center"
                  label="单位">
          </el-table-column>
          <el-table-column
                  prop="rate"
                  header-align="center"
                  align="center"
                  label="计量百分比">
          </el-table-column>
        </el-table>
        <!--<el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>-->


      <div v-if="dataCipeSize > 0">
        <span>邦定工艺</span>
        <el-table :data="dataCipeList"
                  border
                  v-loading="dataListLoading"
                  @selection-change="selectionChangeHandle"
                  style="width: 100%;">
          <el-table-column
                  prop="addStart"
                  header-align="center"
                  align="center"
                  label="add start开始">
          </el-table-column>
          <el-table-column
                  prop="addBonding"
                  header-align="center"
                  align="center"
                  label="add bonding过程">
          </el-table-column>
          <el-table-column
                  prop="rpm"
                  header-align="center"
                  align="center"
                  label="Rpm 速度">
          </el-table-column>
          <el-table-column
                  prop="temp"
                  header-align="center"
                  align="center"
                  label="Temp 温度">
          </el-table-column>
          <el-table-column
                  prop="time"
                  header-align="center"
                  align="center"
                  label="Time 时间">
          </el-table-column>
          <el-table-column
                  prop="cocktail"
                  header-align="center"
                  align="center"
                  label="cocktail 干混">
          </el-table-column>
        </el-table>
        <!--<el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>-->
      </div>
      </div>
      <div slot="soltFooter">
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">返回</el-button>
        </span>
      </div>
    <!--</el-dialog>-->
    </sides-lip>
  </div>
</template>

<script>
  import {list,listCipe} from '@/api/modules/samplerecipe'
  import SidesLip from "@/components/SidesLip";
  export default {
    data () {
      return {
        dataForm: {
          recipeOrderId: '',

          daterange:[],
          category:'',
        },
        dataList: [],
        dataCipeList:[],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataCipeSize: 0,
        dataListLoading: false,
        dataListSelections: [],
        recipeListVisible: false
      }
    },
    components: {
      SidesLip
    },
      //写个过滤器，保留小数点后两位
      filters: {
          numFilter (value) {
              let realVal = ''
              if (!isNaN(value) && value!== '') {
                  // 截取当前数据到小数点后两位
                  realVal = parseFloat(value).toFixed(2)
              } else {
                  realVal = '--'
              }
              return realVal
          }
      },
    methods: {
      close() {
        this.$refs.SidesLip.sideClose();
      },
      init(id,category,sampleType){
        console.log(category)
        this.dataForm.sampleProductId = id;
        this.$refs.SidesLip.init(this.dataForm.id?"查看配方":"查看配方", "800px", "block");
        this.dataForm.category=category;
          this.dataForm.sampleType=sampleType;
        this.recipeListVisible = true;
        this.getDataList()
      },

      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          sampleProductId: this.dataForm.sampleProductId
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
        listCipe(params).then(({data})=>{
          if(data && data.code === 0){
            this.dataCipeList = data.page
            this.dataCipeSize = data.totalCount
          }else {
            this.dataCipeList = []
            this.dataCipeSize = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      }
    }
  }
</script>

import request from '@/utils/request'

export function searchHistoryList (params) {
    return request({
        url: '/searchHistory/searchhistory/searchHistoryList',
        method: 'get',
        params: params
    })
}


<template>
    <el-dialog
        :title="'打印产品信息'"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="500px"
        @close="close()"
    >
        <div id="printarea" ref="print">
            <table border=1 width="90%" frame=void rules=none style="margin: 5%;">
                <tr>
                    <td style="width:40%">
                        <el-image style="width: 200px;height:200px" :src="dataForm.qrUrl" ></el-image>
                    </td>
                    <td style="width:60%">
                        <span style="margin-left: 5px;font-size: 30px;">{{'No:'+dataForm.productNo}}</span><br>
                        <span style="margin-left: 5px;font-size: 25px;">{{'L*:'+dataForm.aAve}}</span><br>
                        <span style="margin-left: 5px;font-size: 25px;">{{'a*:'+dataForm.bAve}}</span><br>
                        <span style="margin-left: 5px;font-size: 25px;">{{'b*:'+dataForm.lAve}}</span>
                    </td>
                </tr>   
            </table> 
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">取消</el-button>
            <el-button type="primary" @click="printSub()" size="small" :disabled="dataLoading">打印</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {info} from '@/api/modules/product'
    export default {
        data() {
            return {
                visible: false,
                dataLoading: false,
                showBtn: false,
                dataForm: {
                    id: 0,
                    productNo: '',
                    productName: '',
                    qrUrl: '',
                    lAve: '',
                    aAve: '',
                    bAve: '',
                },
            };
        },
        methods: {
            close(){
                this.visible = false;
            },
            init(id) {
                this.dataForm.id = id
                this.visible = true
                this.$nextTick(() => {
                    this.dataForm.lAve ='';
                    this.dataForm.aAve ='';
                    this.dataForm.bAve ='';
                    this.dataForm.productNo = '';
                    this.dataForm.productName = '';
                    this.dataForm.qrUrl = '';
                    if (this.dataForm.id) {
                        info({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.productNo = data.product.productNo
                                this.dataForm.productName = data.product.productName
                                this.dataForm.qrUrl = data.product.qrUrl
                                this.dataForm.lAve = data.product.lAve
                                this.dataForm.aAve = data.product.aAve
                                this.dataForm.bAve = data.product.bAve
                            }
                        })
                    }
                })
            },
            printSub() {
                //this.$print(this.$refs.print);
                //var oldHtml = document.body.innerHTML;
                document.body.innerHTML = document.getElementById("printarea").innerHTML;
                window.print();
                setTimeout("window.close();",0);
                window.location.reload();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .productDirs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #606266;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 1px solid #d8d8d8;
        .left {
            margin-left: 30px;
        }
        .right {
            margin-left: 5px;
            margin-right: 30px;
        }
    }
    .fontNormal {
        font-weight: normal;
    }
</style>

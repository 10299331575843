<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="关联方式" prop="relationMode" >
        <template>
          <el-radio  v-model="relationMode" @change="setRelationMode('0')" label="0">指定品牌</el-radio>
          <el-radio  v-model="relationMode" @change="setRelationMode('1')" label="1">全部品牌</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="选择品牌" prop="brandId"  v-if="relationMode=='0'?true:false">
        <!--<el-input v-model="dataForm.brandId" placeholder="品牌"  size="small"></el-input>-->
        <!--品牌选择标签-->
        <el-select v-model="dataForm.brandId"  size="small" placeholder="请选择品牌" style="width: 80%">
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签编号" prop="labelNo">
        <el-input v-model="dataForm.labelNo" placeholder="标签编号"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="标签名称" prop="labelName">
        <el-input v-model="dataForm.labelName" placeholder="标签名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="标签英文名称" prop="labelEname">
        <el-input v-model="dataForm.labelEname" placeholder="标签英文名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>

      <el-form-item label="标签别名" prop="labelAlias">
        <el-input v-model="dataForm.labelAlias" placeholder="标签别名"  size="small" style="width: 80%"></el-input>
      </el-form-item>

      <el-form-item label="是否展示" prop="isShow">
        <!--<el-input v-model="dataForm.isShow" placeholder="是否展示"  size="small"></el-input>-->
        <el-select v-model="dataForm.isShow" size="small" placeholder="是否展示" style="width: 80%">
          <el-option
                  v-for="item in showList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="当前排序" prop="currentSort">
        <el-input v-model="dataForm.currentSort" placeholder="当前排序"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="标签说明" prop="description">
        <el-input v-model="dataForm.description"  maxlength="150" type="textarea" :rows="4" placeholder="标签说明"  size="small" style="width: 80%"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/productlabel'
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          productLabelId: 0,
          brandId: '',
          labelNo: '',
          labelName: '',
          labelEname: '',
          labelAlias: '',
          isShow: '',
          currentSort: '',
          description: '',
          isDelete: '',
          createBy: '',
          createTime: '',
          updateBy: '',
          updateTime: '',
          version: '',
        },
          relationMode: '0',
        brandList:[],
        showList: [
          {
            value: '0',
            label: '展示'
          },
          {
            value: '1',
            label: '隐藏'
          }
        ],

        dataRule: {
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur' }
          ],
          labelNo: [
            { required: true, message: '标签编号不能为空', trigger: 'blur' }
          ],
          labelName: [
            { required: true, message: '标签名称不能为空', trigger: 'blur' }
          ],
          labelEname: [
            { required: true, message: '标签英文名称不能为空', trigger: 'blur' }
          ],
          labelAlias: [
            { required: true, message: '标签别名不能为空', trigger: 'blur' }
          ],
          isShow: [
            { required: true, message: '是否展示不能为空', trigger: 'blur' }
          ],
          currentSort: [
            { required: true, message: '当前排序不能为空', trigger: 'blur' }
          ],

          isDelete: [
            { required: true, message: '是否删除不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createTime: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '更新人不能为空', trigger: 'blur' }
          ],
          updateTime: [
            { required: true, message: '更新时间不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本号不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      setRelationMode(params){
        this.dataForm.brandId = null;
        this.relationMode = params;
      },
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      init (id) {
        this.dataForm.productLabelId = id || 0
        this.visible = true
        this.dataForm.brandId = null
        this.relationMode = '0'
        this.getBrandList();
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.productLabelId) {
            info({productLabelId:this.dataForm.productLabelId}).then(({data}) => {
              if (data && data.code === 0) {
                if(data.productLabel.brandId === '0'){
                  this.relationMode = '1'
                }
                this.dataForm.brandId = data.productLabel.brandId
                this.dataForm.labelNo = data.productLabel.labelNo
                this.dataForm.labelName = data.productLabel.labelName
                this.dataForm.labelEname = data.productLabel.labelEname
                this.dataForm.labelAlias = data.productLabel.labelAlias
                this.dataForm.isShow = data.productLabel.isShow
                this.dataForm.currentSort = data.productLabel.currentSort
                this.dataForm.description = data.productLabel.description
                this.dataForm.isDelete = data.productLabel.isDelete
                this.dataForm.createBy = data.productLabel.createBy
                this.dataForm.createTime = data.productLabel.createTime
                this.dataForm.updateBy = data.productLabel.updateBy
                this.dataForm.updateTime = data.productLabel.updateTime
                this.dataForm.version = data.productLabel.version
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'productLabelId': this.dataForm.productLabelId || undefined,
              'brandId': this.dataForm.brandId,
              'labelNo': this.dataForm.labelNo,
              'labelName': this.dataForm.labelName,
              'labelEname': this.dataForm.labelEname,
              'labelAlias': this.dataForm.labelAlias,
              'isShow': this.dataForm.isShow,
              'currentSort': this.dataForm.currentSort,
              'description': this.dataForm.description
            }
            var tick = !this.dataForm.productLabelId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
    <div class="dashboard-container" style="margin: 15px;">
            <div>
                <P>基础概况</P>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/product">
                        <span>产品库总量</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.productCount}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/product">
                        <span>今日新增产品</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.countToDay}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/product">
                        <span>7日新增产品</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.countWeek}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/product">
                        <span>30日新增产品</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.countUser}}</b>
                    </div>
                </el-card>
            </div>
            <div>
                <P>授权概况</P>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/device">
                        <span>未授权</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.unauthorizedCount}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/device">
                        <span>已授权</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.authorizedCount}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/device">
                        <span>已停用</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.offStreamCount}}</b>
                    </div>
                </el-card>
                <el-card class="box-card-4" shadow="hover">
                    <div slot="header" class="clearfix">
                        <router-link to="/device">
                        <span>使用中</span>
                        </router-link>
                    </div>
                    <div class="card-item">
                        <b>{{this.dataForm.occupiedCount}}</b>
                    </div>
                </el-card>
            </div>
            <div>
                <p>数据统计</p>
            </div>
        <div style="display: flex">
                <div class="chart" id="chart" style="width: 50%;height: 600px" ></div>
        <div class="chart" id="chartP" style="width: 50%;height: 600px" ></div>
        </div>
        </div>
</template>


<script>
    import {
        occupiedCount,
        CountProThirtyDay,
        ProductCount,
        CountToDay,
        CountWeek,
        CountUser,
        UnauthorizedCount,
        AuthorizedCount,
        OffStreamCount,
        CountThisWeek,
        CountMonth,
        CountThisWeekEveryDay,
        CountMonthEveryDay,
        getCountFactoryPro,
        getCountPersonnel,
    } from '@/api/modules/dashboard'


    export default {
        data(){
            return{
                dataForm:{
                    productCount:'',
                    countToDay:'',
                    countWeek:'',
                    countUser:'',
                    unauthorizedCount:'',
                    authorizedCount:'',
                    offStreamCount:'',
                    occupiedCount:'',
                    countThisWeek:'',
                    countMonth:'',
                    countThisWeekEveryDay:[],
                    CountMonthEveryDay:[],
                    countFactoryProList:[],
                    factoryNameList:[],//工厂名称集合
                    proNumList:[],//产品数量集合
                    dayNumList:[],//工厂日新增数据集合
                    userNameArry:[],//人员名称集合
                    proNumArry:[],//产品集合
                    dayNumArry:[],//每日新增集合
                    oldDayNumArry:[],//昨日新增集合
                    countPersonnelList:[]
                }
            }
        },
        created(){
            this.getProductCount(),
            this.getCountToDay(),
            this.getCountWeek(),
            this.getCountUser(),
            this.getUnauthorizedCount(),
            this.getAuthorizedCount(),
            this.getOffStreamCount(),
                this.getOccupiedCount(),
            this.getCountThisWeek(),
            this.getCountMonth()
            this.getCountThisWeekEveryDay(),
                /*this.queryCountFactoryPro(),
                this.queryCountPersonnel(),*/
            this.getCountMonthEveryDay()
        },
        mounted(){
            this.queryCountFactoryPro(),
            this.queryCountPersonnel()
        },
        methods:{

            //点击数据功能框，跳转对应的页面
            skipPage(){
                this.$router.push({
                    name: 'product'
                });
            },

            //工厂统计数据
            queryCountFactoryPro(){
                this.dataForm.factoryNameList = [],
                    this.dataForm.proNumList =[],
                    this.dataForm.dayNumList = [],
                getCountFactoryPro().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countFactoryProList = data.countFactoryPro;
                        this.dataForm.countFactoryProList.forEach(item=>{
                            this.dataForm.factoryNameList.push(item.factoryName)
                            this.dataForm.proNumList.push(item.proNum)
                            this.dataForm.dayNumList.push(item.dayNum)
                        })
                    }
                    this.initChart()
                })
            },

            //人员统计数据
            queryCountPersonnel(){
                getCountPersonnel().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countPersonnelList = data.countPersonnel;
                        this.dataForm.countPersonnelList.forEach(item=>{
                            this.dataForm.userNameArry.push(item.userName)
                            this.dataForm.proNumArry.push(item.proNum)
                            this.dataForm.dayNumArry.push(item.dayNum)
                            this.dataForm.oldDayNumArry.push(item.oldDayNum)
                        })
                    }
                    this.inintPesChart()
                })
            },
            getProductCount(){
                ProductCount().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.productCount = data.productCount;
                    }
                })
            },
            getCountToDay() {
                CountToDay().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countToDay = data.CountToDay;
                    }
                })
            },
            getCountWeek(){
                CountWeek().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countWeek = data.CountWeek;
                    }
                })
            },
            getCountUser() {
                CountProThirtyDay().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countUser = data.CountThirtyDayPro;
                    }
                })
            },
            getUnauthorizedCount(){
                UnauthorizedCount().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.unauthorizedCount = data.UnauthorizedCount;
                    }
                })
            },
            getAuthorizedCount() {
                AuthorizedCount().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.authorizedCount = data.AuthorizedCount;
                    }
                })
            },
            getOffStreamCount(){
                OffStreamCount().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.offStreamCount = data.OffStreamCount;
                    }
                })
            },
            getOccupiedCount(){
                occupiedCount().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.occupiedCount = data.OccupiedCount;
                    }
                })
            },
            getCountThisWeek() {
                CountThisWeek().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countThisWeek = data.CountThisWeek;
                    }
                })
            },
            getCountMonth(){
                CountMonth().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countMonth = data.CountMonth;
                    }
                })
            },
            getCountThisWeekEveryDay() {
                CountThisWeekEveryDay().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countThisWeekEveryDay = data.CountThisWeekEveryDay;
                    }
                })
            },
            getCountMonthEveryDay(){
                CountMonthEveryDay().then(({ data })=>{
                    if(data && data.code === 0){
                        this.dataForm.countMonthEveryDay = data.CountMonthEveryDay;
                    }
                })
            },


            //=========================================工厂数据统计================================================
            //工厂数据图表
            initChart(){
                console.log(this.dataForm.factoryNameList)
                // 基于准备好的dom，初始化echarts实例
                var myChart = this.$echarts.init(document.getElementById('chart'));
                    console.log(myChart);
                var app = {};
               let option = null;

                var posList = [
                    'left', 'right', 'top', 'bottom',
                    'inside',
                    'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
                    'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
                ];

                app.configParameters = {
                    rotate: {
                        min: -90,
                        max: 90
                    },
                    align: {
                        options: {
                            left: 'left',
                            center: 'center',
                            right: 'right'
                        }
                    },
                    verticalAlign: {
                        options: {
                            top: 'top',
                            middle: 'middle',
                            bottom: 'bottom'
                        }
                    },
                    position: {
                        options: this.$echarts.util.reduce(posList, function (map, pos) {
                            map[pos] = pos;
                            return map;
                        }, {})
                    },
                    distance: {
                        min: 0,
                        max: 100
                    }
                };

                app.config = {
                    rotate: 90,
                    align: 'left',
                    verticalAlign: 'middle',
                    position: 'insideBottom',
                    distance: 15,
                    onChange: function () {
                        var labelOption = {
                            normal: {
                                rotate: app.config.rotate,
                                align: app.config.align,
                                verticalAlign: app.config.verticalAlign,
                                position: app.config.position,
                                distance: app.config.distance
                            }
                        };
                        myChart.setOption({
                            series: [{
                                label: labelOption
                            }, {
                                label: labelOption
                            }, {
                                label: labelOption
                            }, {
                                label: labelOption
                            }]
                        });
                    }
                };
                var labelOption = {
                    normal: {
                        show: true,
                        position: app.config.position,
                        distance: app.config.distance,
                        align: app.config.align,
                        verticalAlign: app.config.verticalAlign,
                        rotate: app.config.rotate,
                        formatter: '{c}  {name|{a}}',
                        fontSize: 16,
                        rich: {
                            name: {
                                textBorderColor: '#fff'
                            }
                        }
                    }
                };
                option = {
                    title: {
                        text: '工厂数据统计图'
                    },
                    color: ['#003366', '#006699', '#4cabce', '#e5323e'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['产品总数', '日新增总数', 'Desert', 'Wetland']
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: { show: true },
                            dataView: { show: true, readOnly: false },
                            magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
                            restore: { show: true },
                            saveAsImage: { show: true }
                        }
                    },
                    calculable: true,
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: { show: false },
                            data: this.dataForm.factoryNameList
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '产品总数',
                            type: 'bar',
                            barGap: 0,
                            label: labelOption,
                            data: this.dataForm.proNumList
                        },
                        {
                            name: '日新增总数',
                            type: 'bar',
                            label: labelOption,
                            data: this.dataForm.dayNumList
                        },
                        /*
                       {
                           name: 'Desert',
                           type: 'bar',
                           label: labelOption,
                           data: this.dataForm.oldDayNumArry
                       },

                       {
                           name: 'Wetland',
                           type: 'bar',
                           label: labelOption,
                           data: [98, 77, 101, 99, 40]
                       }*/
                    ]
                };

                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    myChart.setOption(option, true);
                }
            },




            //=========================================人员数据统计================================================

           inintPesChart(){
               // 基于准备好的dom，初始化echarts实例
               var myPesChart = this.$echarts.init(document.getElementById('chartP'));
              // console.log(myChart);
               var appPes = {};
               let optionS = null;
               var posListp = [
                   'left', 'right', 'top', 'bottom',
                   'inside',
                   'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
                   'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
               ];

               appPes.configParameters = {
                   rotate: { //设置角度
                       min: -90,
                       max: 90
                   },
                   align: {
                       options: {
                           left: 'left',
                           center: 'center',
                           right: 'right'
                       }
                   },
                   verticalAlign: {
                       options: {
                           top: 'top',
                           middle: 'middle',
                           bottom: 'bottom'
                       }
                   },
                   position: {
                       options: this.$echarts.util.reduce(posListp, function (map, pos) {
                           map[pos] = pos;
                           return map;
                       }, {})
                   },
                   distance: {
                       min: 0,
                       max: 100
                   }
               };

               appPes.config = {
                   rotate: 90,
                   align: 'left',
                   verticalAlign: 'middle',
                   position: 'insideBottom',
                   distance: 15,
                   onChange: function () {
                       var labelOptionS = {
                           normal: {
                               rotate: appPes.config.rotate,
                               align: appPes.config.align,
                               verticalAlign: appPes.config.verticalAlign,
                               position: appPes.config.position,
                               distance: appPes.config.distance
                           }
                       };
                       myPesChart.setOption({
                           series: [{
                               label: labelOptionS
                           }, {
                               label: labelOptionS
                           }, {
                               label: labelOptionS
                           }, {
                               label: labelOptionS
                           }]
                       });
                   }
               };
               var labelOptionS = {
                   normal: {
                       show: true,
                       position: appPes.config.position,
                       distance: appPes.config.distance,
                       align: appPes.config.align,
                       verticalAlign: appPes.config.verticalAlign,
                       rotate: appPes.config.rotate,
                       formatter: '{c}  {name|{a}}',
                       fontSize: 16,
                       rich: {
                           name: {
                               textBorderColor: '#fff'
                           }
                       }
                   }
               };
               optionS = {
                   title: {
                       text: '人员数据统计图'
                   },
                   color: ['#003366', '#006699', '#4cabce', '#e5323e'],
                   tooltip: {
                       trigger: 'axis',
                       axisPointer: {
                           type: 'shadow'
                       }
                   },
                   legend: {
                       data: ['录入产品总数', '今日录入数', '昨日录入数', 'Wetland']
                   },
                   toolbox: {
                       show: true,
                       orient: 'vertical',
                       left: 'right',
                       top: 'center',
                       feature: {
                           mark: { show: true },
                           dataView: { show: true, readOnly: false },
                           magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
                           restore: { show: true },
                           saveAsImage: { show: true }
                       }
                   },
                   calculable: true,
                   xAxis: [
                       {
                           type: 'category',
                           axisTick: { show: false },
                           data: this.dataForm.userNameArry
                       }
                       /*{
                           type: 'value'
                       }*/
                   ],
                   yAxis: [
                       {
                           type: 'value'
                       }
                       /*{
                           type: 'category',
                           axisTick: { show: false },
                           data: this.dataForm.userNameArry
                       }*/
                   ],
                   series: [
                       {
                           name: '录入产品总数',
                           type: 'bar',
                           barGap: 0,
                           label: labelOptionS,
                           data: this.dataForm.proNumArry
                       },
                       {
                           name: '今日录入数',
                           type: 'bar',
                           label: labelOptionS,
                           data: this.dataForm.dayNumArry
                       },
                       {
                           name: '昨日录入数',
                           type: 'bar',
                           label: labelOptionS,
                           data: this.dataForm.oldDayNumArry
                       },
                       /*
                       {
                           name: 'Wetland',
                           type: 'bar',
                           label: labelOption,
                           data: [98, 77, 101, 99, 40]
                       }*/
                   ]
               };


               /*=======================================设置滑动================================*/


               /*=======================================设置滑动================================*/



               // 使用刚指定的配置项和数据显示图表。
               if (optionS && typeof optionS === "object") {
                   myPesChart.setOption(optionS, true);
               }

           }

        },




    }




</script>
<style>
    .one {
        width: 20%;
        height: 100px;
        float: left;
        margin-left: 87px;
    }
    .two {
        width:20%;
        height: 100px;
        float: left;
        margin-left: 87px;
    }
    .three{
        position: absolute;
        left: 180px;
        top: 400px;
    }
    .box-card-4 {
        display: inline-block;
        width: 24%;
        height: auto;
        /*min-height: 10rem;*/   /*设置框的宽度*/
        margin-left: 5px;
        margin-bottom: 5px;
        min-width: 16.5rem;
    }
</style>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm"  label-width="100px"
     v-loading="dataLoading" style="width:75%;margin:auto;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="品牌名称" prop="tenantName">
        <!-- <el-input v-model="dataForm.tenantName" placeholder="请输入品牌名称"  size="small" maxlength="30"></el-input> -->
        <el-select v-model="dataForm.tenantName" size="small" placeholder="请选择品牌">
          <el-option v-for="op in brandList" :label="op.brandName" :value="op.id" :key="op.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据源地址" prop="datasourceUrl">
        <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 5}" v-model="dataForm.datasourceUrl" placeholder="请输入数据源地址"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="账号名称" prop="datasourceUsername">
        <el-input v-model="dataForm.datasourceUsername" placeholder="请输入账号名称"  size="small" maxlength="50">
          <template slot="append"></template>
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="datasourcePassword">
        <el-input v-model="dataForm.datasourcePassword" placeholder="请输入密码"  size="small" maxlength="100" :type="passwordType">
        </el-input>
        <!-- <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span> -->
      </el-form-item>
      <el-form-item label="数据源驱动" prop="datasourceDriver">
        <el-input
                v-model="dataForm.datasourceDriver"
                placeholder="请输入数据源驱动">
        </el-input>
      </el-form-item>
      <el-form-item label="是否初始化" prop="isNew">
        <el-radio v-model="dataForm.isNew" label='1'>是</el-radio>
        <el-radio v-model="dataForm.isNew" label='0'>否</el-radio>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="dataForm.status" label='1'>启用</el-radio>
        <el-radio v-model="dataForm.status" label='0'>禁用</el-radio>
        <el-radio v-model="dataForm.status" label='2'>连接异常</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" style="text-algin:center;">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,save,update} from '@/api/modules/source';
  import {brandList} from '@/api/modules/brand'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        brandList:[],
        dataForm: {
          id: 0,
          tenantName: '',
          datasourceUrl: '',
          datasourceUsername: '',
          datasourcePassword: '',
          datasourceDriver: '',
          isNew: '0',
          status: '1',
        },
        passwordType: "password",
        dataRule: {
          tenantName: [
            { required: true, message: '品牌名称不能为空', trigger: 'blur' }
          ],
          datasourceUrl: [
            { required: true, message: '数据源地址不能为空', trigger: 'blur' }
          ],
          datasourceUsername: [
            { required: true, message: '账号名称不能为空', trigger: 'blur' }
          ],
          datasourcePassword: [
            { required: true, message: '密码不能为空', trigger: 'blur' }
          ],
          datasourceDriver: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          isNew: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          status: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.getBrandlist()
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.passwordType = "password"
          if (this.dataForm.id) {
            info({tenantId:this.dataForm.id}).then(({data}) => {
              if (data && data.success) {
                this.dataForm.id = data.data.id
                this.dataForm.tenantName = data.data.tenantId
                this.dataForm.datasourceUrl = data.data.datasourceUrl
                this.dataForm.datasourceUsername = data.data.datasourceUsername
                this.dataForm.datasourcePassword = data.data.datasourcePassword
                this.dataForm.datasourceDriver = data.data.datasourceDriver
                this.dataForm.status = data.data.status
              }
            })
          }
        })
      },
      getBrandlist(){
        let params = {}
          brandList(params).then(({data}) => {
              if (data && data.code === 0) {
                  this.brandList = data.list
              } else {
                  this.brandList = []
              }
          })
      },
      showPwd() {
        if (this.passwordType === "password") {
          this.passwordType = "text";
        } else {
          this.passwordType = "password";
        }
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'tenantName': this.brandList.find(item =>{
                if (item.id == this.dataForm.tenantName) {
                  return item
                }
              }).brandName,
              'tenantId': this.dataForm.tenantName,
              'datasourceUrl': this.dataForm.datasourceUrl,
              'datasourceUsername': this.dataForm.datasourceUsername,
              'datasourcePassword': this.dataForm.datasourcePassword,
              'datasourceDriver': this.dataForm.datasourceDriver,
              'isNew': this.dataForm.isNew,
              'status': this.dataForm.status
            }
            var tick = !this.dataForm.id ? save(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code == '10200') {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	  this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.message ? data.message : 'error')
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.show-pwd {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 16px;
    color: #2e414e;
    cursor: pointer;
    user-select: none;
  }
</style>

import request from '@/utils/request'

// 根据属性id获取可配置的属性
export function selectConfigurableAttr (params) {
  return request({
    url: '/productattr/productattr/selectConfigurableAttr',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/product/productattrrelation/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/product/productattrrelation/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/product/productattrrelation/update',
    method: 'post',
    data: params
  })
}

// 列表
export function list (params) {
  return request({
    url: '/product/productattrrelation/list',
    method: 'get',
    params: params
  })
}

// 新增属性值规则
export function valuerelationSave (params) {
  return request({
    url: '/product/productattrvaluerelation/save',
    method: 'post',
    data: params
  })
}

// 获取已设置属性值规则
export function valuerelationList (params) {
  return request({
    url: '/product/productattrvaluerelation/list',
    method: 'get',
    params: params
  })
}
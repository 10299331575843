import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/recipe/recipe/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/recipe/recipe/info',
    method: 'get',
    params: params
  })
}
// 获取配方材料信息
export function recipeMterialInfo (params) {
    return request({
        url: '/recipe/recipe/recipeMterialInfo',
        method: 'get',
        params: params
    })
}
// 获取配方信息信息
export function recipeInfo (params) {
    return request({
        url: '/recipe/recipe/recipeInfo',
        method: 'get',
        params: params
    })
}
// 添加
export function add (params) {
  return request({
    url: '/recipe/recipe/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/recipe/recipe/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/recipe/recipe/delete',
    method: 'post',
    data: params
  })
}

// 根据产品编号联想产品信息
export function queryProduct (params) {
  return request({
    url: '/recipe/recipe/queryProduct',
    method: 'get',
    params
  })
}

//材料信息下拉
export function queryMateriaInfo () {
  return request({
    url: '/recipe/recipe/queryMateriaInfo',
    method: 'get',
  })
}

//固化条件信息下拉
export function querySolidify (params) {
  return request({
    url: '/recipe/recipe/querySolidify',
    method: 'get',
      params
  })
}

//固化内容
export function getSolidifyInFo (params) {
    return request({
        url: '/recipe/recipe/getSolidifyInFo',
        method: 'get',
        params
    })
}
//配方导入
export function recipeImportExcel(params) {
    return request({
        url: '/recipe/recipe/recipeImportExcel',
        method: 'post',
        data: params
    })
}
//配方材料导入
export function reMaImportExcel(params) {
    return request({
        url: '/recipe/recipe/reMaImportExcel',
        method: 'post',
        data: params
    })
}
//根据材料编号获取id
export function getMaId (params) {
    return request({
        url: '/recipe/recipe/getMaId',
        method: 'get',
        params
    })
}

<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
      size="small"
    >
      <el-form-item>
        <el-select
          v-model="dataForm.interfaceName"
          placeholder="接口"
          clearable
        >
          <el-option label="同步产品" value="同步产品"></el-option>
          <el-option label="同步打样订单" value="同步打样订单"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="daterange"
          range-separator="~"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :editable="false"
          start-placeholder="记录时间从"
          end-placeholder="记录时间到"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button type="primary" @click="exportExcelHandle()" size="small"
          >导出数据
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      size="small"
      style="width: 100%"
    >
      <el-table-column
        prop="interfaceName"
        header-align="center"
        align="center"
        label="接口"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        header-align="center"
        align="center"
        label="内容"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="180"
        label="记录时间"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { logList } from "@/api/sys/syncLog";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      dataForm: {
        interfaceName: "",
      },
      daterange: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 查询清空
    reset(dataForm) {
      this.dataForm.interfaceName = "";
      this.daterange = [];
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      const params = {
        page: this.pageIndex,
        limit: this.pageSize,
        interfaceName: this.dataForm.interfaceName,
        startDate:
          this.daterange && this.daterange.length > 0
            ? this.daterange[0] + " 00:00:00"
            : "",
        endDate:
          this.daterange && this.daterange.length > 0
            ? this.daterange[1] + " 23:59:59"
            : "",
      };
      const { data } = await logList(params);
      if (data && data.code === 0 && data.page && data.page.list.length > 0) {
        this.dataList = data.page.list;
        this.totalPage = data.page.totalCount;
      } else {
        this.dataList = [];
        this.totalPage = 0;
      }
      this.dataListLoading = false;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    //导出数据
    exportExcelHandle() {
      const params = {
        interfaceName: this.dataForm.interfaceName,
        startDate:
          this.daterange && this.daterange.length > 0
            ? this.daterange[0] + " 00:00:00"
            : "",
        endDate:
          this.daterange && this.daterange.length > 0
            ? this.daterange[1] + " 23:59:59"
            : "",
      };
      var url =
        `${process.env.VUE_APP_BASE_API}/sys/syncLog/exportExcel?interfaceName=` +
        params.interfaceName +
        "&startDate=" +
        params.startDate +
        "&endDate=" +
        params.endDate +
        `&token=${getToken()}`;
      // 模拟创建元素实现
      var alink = document.createElement("a");
      alink.href = url;
      alink.style = "display:none";
      alink.click();
    },
  },
};
</script>

import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/dict/dict/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/dict/dict/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/dict/dict/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/dict/dict/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/dict/dict/delete',
    method: 'post',
    data: params
  })
}
// 色号类别列表
export function colorBooklist (params) {
    return request({
        url: '/dict/dict/colorBooklist',
        method: 'get',
        params: params
    })
}
// 色号类别添加
export function colorBooksave (params) {
    return request({
        url: '/dict/dict/colorBooksave',
        method: 'post',
        data: params
    })
}

// 色号类别修改
export function colorBookupdate (params) {
    return request({
        url: '/dict/dict/colorBookupdate',
        method: 'post',
        data: params
    })
}

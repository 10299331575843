<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm"  label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="应用行业" prop="industryId">
        <el-select v-model="dataForm.industryId" placeholder="请选择应用行业">
          <el-option
                  v-for="item in industryInfo"
                  :key="item.industryName"
                  :label="item.industryName"
                  :value="item.industryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品系列名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="产品系列名称"  size="small" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="产品系列编号" prop="no">
        <el-input v-model="dataForm.no" placeholder="产品系列编号"  size="small" maxlength="8"></el-input>
      </el-form-item>
      <el-form-item label="产品系列说明" prop="description">
        <div style="margin: 20px 0;"></div>
        <el-input
                type="textarea"
                :rows="5"
                placeholder="产品系列说明"
                v-model="dataForm.description"
                maxlength="150"
                show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">返回</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,hierarchyInfo,industryInfo} from '@/api/modules/productseries'
  export default {
    data () {
      return {
        hierarchyInfo:[],
        industryInfo:[],
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          no: '',
          name: '',
          hierarchyId: '',
          industryId:'',
          description: ''
        },
        dataRule: {
          no: [
            { required: true, message: '产品系列编号不能为空', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '产品系列名称不能为空', trigger: 'blur' }
          ],
          industryId: [
            { required: true, message: '产品体系不能为空', trigger: 'blur' }
          ]
        }
      }
    },
      created() {
          //获取体系名称下拉
          this.getHierarchyInfo();
          //获取应用行业下拉
        this.getIndustryInfo();
      },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.no = data.productSeries.no
                this.dataForm.name = data.productSeries.name
                this.dataForm.industryId = data.productSeries.industryId
                this.dataForm.description = data.productSeries.description
              }
            })
          }
        })
      },
        //获取体系名称下拉
        getHierarchyInfo(){
            hierarchyInfo().then(({ data }) => {
                if (data && data.code === 0) {
                    this.hierarchyInfo = data.hierarchy;
                    //  this.MaterialCategoryInfo = data.roleName;
                }
            });
        },
      //获取应用行业下拉
      getIndustryInfo(){
        industryInfo().then(({ data }) =>{
          if(data && data.code === 0){
            this.industryInfo = data.industry;
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'no': this.dataForm.no,
              'name': this.dataForm.name,
              'hierarchyId': this.dataForm.hierarchyId,
              'industryId':this.dataForm.industryId,
              'description': this.dataForm.description
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <div class="dashboard-editor-container">
    <template>
      <el-row style="background: #fff; margin-bottom: 15px">
        <el-card>
          <div slot="header" class="clearfix">
            <span>客户概览</span>
            <div style="float: right; padding: 3px 0">
              <date-picker @dateChange="dateChange" />
            </div>
          </div>
          <div class="no-more" id="no-more">
            <img src="@/assets/no-more.png" />
            <span>暂无数据</span>
          </div>
          <div id="barChartDiv">
            <el-row :gutter="15">
              <el-col :xs="24" :sm="24" :lg="16">
                <bar-chart ref="barChart" @barchartemit="barchartemit" />
              </el-col>
              <el-col :xs="24" :sm="24" :lg="8">
                <rank-info ref="rankInfo" />
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-row>
      <el-row style="background: #fff; margin-bottom: 15px">
        <el-card>
          <div slot="header" class="clearfix">
            <span>客户产品分布</span>
            <!-- <div style="float: right; padding: 3px 0">
              <date-picker @dateChange="dateChange" />
            </div> -->
          </div>
          <el-row :gutter="15">
            <el-col :xs="24" :sm="24" :lg="10" style="justify-content: center;">
              <div style="padding-left:100px;padding-top:20%" ref="searchCount">
                <label style="padding-right:5px;">客户名称</label>
                <!-- <el-select v-model="customInfo" size="small" placeholder="客户名称" @change="selectCustomChange">
                  <el-option
                    v-for="item in customInfoList"
                    :key="item"
                    :label="item"
                    :value="item"
                    size="small"
                  ></el-option>
                </el-select> -->
                <el-autocomplete
                        v-model="customInfo"
                        :fetch-suggestions="querySearch"
                        placeholder="客户名称"
                        :trigger-on-focus="true"
                        @select="handleSelect"
                        size="small"
                        :debounce=10
                        style="width: 200px;"
              ></el-autocomplete>
              </div>
              <div style="padding-left:100px;padding-top:50px" ref="searchCount">
                <label style="padding-right:5px;">统计内容</label>
                <el-select v-model="productAttr" size="small" placeholder="产品属性" @change="selectAttrChange">
                  <el-option
                    v-for="item in productAttrList"
                    :key="item.productAttrId"
                    :label="item.attrName"
                    :value="item.productAttrId"
                    size="small"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :lg="14">
              <div class="no-more" id="no-more-pie">
                <img src="@/assets/no-more.png" />
                <span>暂无数据</span>
              </div>
              <div id="pieChartDiv">
                <pie-chart ref="pieChart" :chartIsLoad="true" @piechartemit="piechartemit" v-if="pData.length > 0"/>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </template>
    <Footer style="margin-top:-30px" />
  </div>
</template>

<script>
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import {selectBrandProductAttrList}from '@/api/modules/productattr'
import {selectCustomInfoList}from '@/api/modules/record'

export default {
  name: "customer",
  components: {
    PieChart,
    BarChart,
    RankInfo: () => import('./components/RankInfo'),
    DatePicker: () => import('./components/DatePicker'),
    Footer: () => import('./components/Footer'),
  },
  data() {
    return {
      searchCount: 0,
      searchAccuracyRate: 0,
      pData: [''],
      bData: [''],
      serNum: 0,
      serRercentage: '0%',
      bgColor: [
        "rgba(145, 238, 107, 1)",
        "rgba(145, 238, 107, 0.4)",
        "rgba(145, 238, 107, 0)",
      ],
      bgColor1: [
        "rgba(78,151,254,1)",
        "rgba(78,151,254,0.4)",
        "rgba(78,151,254,0)",
      ],
      customInfo: '',
      customInfoList: [],
      productAttr: '',
      productAttrList: [],
      dateValue: [],
      restaurants: '',
    };
  },
  created () {
    this.selectProductAttrList();
    this.selectCustomInfoList();
    this.dateChange(this.dateValue, this.customInfo, this.productAttr);
  },
  beforeDestroy() {
  },
  methods: {
    async selectProductAttrList(){
      this.productAttrList = []
      var params = {
        isUniversal:'0'
      }
      const { data } = await selectBrandProductAttrList(params);
      if (data && data.code === 0) {
          this.productAttrList = data.productAttrList;
          this.productAttr = data.productAttrList[0].productAttrId
        } else {
          this.$message.error(data.msg);
        }
    },
    async selectCustomInfoList(value){
      this.customInfoList = []
      var params = {
        startTime: value ? (value[0] || '') : '',
        endTime: value ? (value[1] || '') : '',
      }
      const { data } = await selectCustomInfoList(params);
      if (data && data.code === 0) {
        this.customInfoList = data.customInfoList;
        this.customInfo = data.customInfoList[0]
        this.selectProductAttrList();
        this.$refs.pieChart.getCustomInfoStatistics(this.dateValue, this.customInfo, this.productAttr)
      } else {
        this.$message.error(data.msg);
      }
    },
    searchcount(data) {
      this.searchCount = data
    },
    searchaccuracyrate(data) {
      this.searchAccuracyRate = data
    },
    piechartemit(data) {
      this.pData = data
      if(this.pData==null || this.pData.length==0){
        document.getElementById("no-more-pie").style.display="";
        document.getElementById("pieChartDiv").style.display="none";
      } else {
        document.getElementById("no-more-pie").style.display="none";
        document.getElementById("pieChartDiv").style.display="";
      }

    },
    barchartemit(data) {
      console.log(data)
      this.bData = data
      if(this.bData==null || this.bData.length==0){
        document.getElementById("no-more").style.display="";
        document.getElementById("barChartDiv").style.display="none";
      } else {
        document.getElementById("no-more").style.display="none";
        document.getElementById("barChartDiv").style.display="";
      }
    },
    dateChange(value) {
      this.pData = [''],
      this.bData = [''],
      console.log(value)
      if (value === null) {
        value = ['', '']
      }
      this.dateValue = value;
      this.selectCustomInfoList(value);
      if (this.$refs.barChart) {
        this.$refs.barChart.selectCustomStatistics(value)
      }
      if (this.$refs.rankInfo) {
        this.$refs.rankInfo.selectCustomStatistics(value)
      }
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(value, this.customInfo, this.productAttr)
      }
    },

    querySearch(queryString, cb) {
      if(queryString){
        console.log(queryString);
        setTimeout(() =>{
          let params = { 
            "customerName": this.customInfo,
            "startTime": this.dateValue ? (this.dateValue[0] || '') : '',
            "endTime": this.dateValue ? (this.dateValue[1] || '') : '',
          };
          selectCustomInfoList(params).then(({ data }) => {
            if (data && data.code === 0) {
                var customInfoList = data.customInfoList;
                if(null != customInfoList && customInfoList.length > 0){
                  this.restaurants = [];
                  for(var i=0;i<customInfoList.length;i++){
                    var value = {"value": customInfoList[i]};
                    this.restaurants.push(value);
                  }
                }
              
                if(this.restaurants.length <= 0){
                  return this.$message.error("没有匹配到相关客户");
                }
                console.log(this.restaurants);
                cb(this.restaurants);
            }
          })
        },1000);
      }
    },
    handleSelect(item) {
      console.log(item);
      if(item){
        this.customInfo = item.value;
      }
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(this.dateValue, this.customInfo, this.productAttr)
      }
    },
    selectCustomChange(value) {
      this.pData = [''],
      console.log(value)
      
      this.customInfo = value;
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(this.dateValue, this.customInfo, this.productAttr)
      }
    },
    selectAttrChange(value) {
      this.pData = [''],
      console.log(value)
      
      this.productAttr = value;
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(this.dateValue, this.customInfo, this.productAttr)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.default-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 1000px;
  padding-top: 200px;
}
.dashboard-editor-container {
  padding: 15px 15px 10px 15px;
  background-color: #eaf1fc;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    // padding: 16px 16px 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.no-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 411px;
  span {
    font-size: 14px;
    color: #000;
    padding-top: 10px;
  }
}
</style>

<template>
  <el-dialog
    title="设置属性值规则"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="association"
    width="900px">
    <div slot="title" class="header-title">
      <span class="title-age" style="cursor:pointer;" title="设置当前筛选规则中属性值的关联关系，
竖线左边是显示设置完成的关联关系区域，
竖线右边是编辑关联关系的区域">设置属性值规则<i class="el-icon-question" style="margin-left:5px;"></i></span>
    </div>
    <hr style="color:#EAF1FC;margin:-30px 0 20px 0;"/>
    <el-breadcrumb separator="/">
        <el-breadcrumb-item>{{oneName}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="twoName">{{twoName}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="threeName">{{threeName}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="div_">
        <div>
          <div class="list-item" v-for="(item, index) in list_" :key="index">
            <div class="item-name">
                <div @click="secondAttrMethod(item,index)"><span v-if="index === num.one">> </span>{{ item.valueName }}</div>
            </div>
            <div class="childList-item">
                <div class="list-item" style="margin-left:15px;" v-show="item.childList && item.childList.length > 0 && index === num.one" v-for="(ite, inde) in item.childList" :key="inde">
                  <div class="item-name">
                      <div @click="threeAttrId && secondAttrMethod_(ite,inde)"><span v-if="inde === num.two">> </span>{{ ite.valueName }}</div>
                  </div>
                  <div v-if="ite.childList && inde === num.two" class="childList-item">
                      <div class="list-item" style="margin-left:15px;" v-show="ite.childList && inde === num.two" v-for="(it, ind) in ite.childList" :key="ind">
                        <div class="item-name">
                            <div>{{ it.valueName }}</div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div>
          <el-checkbox-group v-if="arr.length > 0" v-model="searchData">
            <el-checkbox v-for="(item,index) in arr" :label="item.id" :key="index" @change="changesearchData" style="width:100%;">{{item.valueName}}</el-checkbox>
          </el-checkbox-group>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
      <el-button @click="visible = false" size="small">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import List from './list'
  import { list } from "@/api/modules/productattrvalue";
  import {valuerelationSave, valuerelationList} from '@/api/modules/productfilterrule'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        id:'',
        brandId:'',
        secondAttrId:'',
        threeAttrId:'',
        oneName:'',
        twoName:'',
        threeName:'',
        list:[],
        list_:[],
        arr:[],
        searchData:[],
        num: {
          one:'',
          two:'',
        }
      }
    },
    methods: {
      init (id, brandId, name,mainAttrId,secondAttrId,threeAttrId,twoName,threeName) {
        this.id = id
        this.brandId = brandId
        this.secondAttrId = secondAttrId
        this.threeAttrId = threeAttrId
        this.oneName = name
        this.twoName = twoName
        this.threeName = threeName
        this.visible = true
        this.onClear()
        this.$nextTick(() => {
          if (this.id) {
            var params = {
              attrRelationId:id,
              brandId: brandId == '0' ? '' : brandId
            }
            valuerelationList(params).then(({data}) => {
              if (data && data.code === 0) {
                this.list= data.data
                this.getList(mainAttrId,brandId)
              }
            })
          }
        })
        // console.log(this.num == 0)
      },
      onClear(){
        this.list=[]
        this.list_=[]
        this.arr=[]
        this.num = {
          one:'',
          two:'',
        }
      },
      changesearchData(){
        var data = []
        this.searchData.map(item => {
          var obj = {
            attrId: this.num.two || this.num.two === 0 ? this.threeAttrId : this.secondAttrId,
            attrRelationId: this.id,
            attrValueId: item,
            brandId: this.brandId,
            childList: [],
            hasChild: 0,
            valueName: this.arr.find(ite => {
              return ite.id == item
            }).valueName,
          }
          data.push(obj)
        })
          console.log(this.num)
        if (this.num.two || this.num.two === 0) {
          this.list_[this.num.one].childList[this.num.two].childList = data
        }else{
          this.list_[this.num.one].childList = data
        }
      },
      secondAttrMethod(item,index){
        this.num = {
          one:index,
          two:'',
        }
        this.searchData = []
        item.childList.map((item) => {
          this.searchData.push(item.attrValueId)
        })
        this.getList(this.secondAttrId,item.brandId,'1')
      },
      secondAttrMethod_(item,index){
        this.num.two = index
        this.searchData = []
        item.childList.map((item) => {
          this.searchData.push(item.attrValueId)
        })
        this.getList(this.threeAttrId,item.brandId,'1')
      },
      getList(id,brandId,val){
        var params = {
          page:1,
          limit:100,
          productAttrId:id,
          brandId: brandId == '0' ? '' : brandId
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            if (val == '1') {
              this.arr = data.page.records
            }else{
              data.page.records.map((item,index) => {
                var isshow = {
                  show:false,
                  index:0,
                }
                this.list.map((ite,inde) =>{
                  if (item.id == ite.attrValueId) {
                    isshow.show = true,
                    isshow.index = inde
                  }
                })
                var obj = {}
                if (isshow.show) {
                  obj = {
                    attrId: id,
                    attrRelationId: this.list[isshow.index].attrRelationId,
                    attrValueId: this.list[isshow.index].attrValueId,
                    brandId: this.list[isshow.index].brandId,
                    childList: this.list[isshow.index].childList,
                    hasChild: this.list[isshow.index].hasChild,
                    valueName: item.valueName,
                  }
                }else{
                  obj = {
                    attrId: id,
                    attrRelationId: this.id,
                    attrValueId: item.id,
                    brandId: item.brandId ? item.brandId : '0',
                    childList: [],
                    hasChild: 0,
                    valueName: item.valueName,
                  }
                }
                this.list_.push(obj)
              })
            }
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.dataLoading = true;
        var params = {
          brandId: this.brandId == '0' ? '' :this.brandId,
          attrRelationId:this.id,
          productAttrValueRelationVoList:this.list_
        }
        valuerelationSave(params).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.dataLoading = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
            this.dataLoading = false;
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.association{
    .div_{
      margin-top:20px;
        display: flex;
        >div:nth-child(2){
            width: 600px;
            // border: 1px solid #999
        }
        >div:nth-child(1){
            margin-right:30px;
            width: 200px;
            min-height: 300px;
            border-right: 1px solid #999;
        }
    }
}
</style>
<style lang="scss" scoped>
.list-item {
    .item-name{
        line-height: 25px;
        padding-left: 15px;
    }
}
</style>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="订单id" prop="sampleOrderId">
        <el-input v-model="dataForm.sampleOrderId" placeholder="订单id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="订单编号" prop="sampleOrderNo">
        <el-input v-model="dataForm.sampleOrderNo" placeholder="订单编号"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="L" prop="l">
        <el-input v-model="dataForm.l" placeholder="L"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="A" prop="a">
        <el-input v-model="dataForm.a" placeholder="A"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="B" prop="b">
        <el-input v-model="dataForm.b" placeholder="B"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="光泽值" prop="glossValue">
        <el-input v-model="dataForm.glossValue" placeholder="光泽值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="工厂id" prop="factortId">
        <el-input v-model="dataForm.factortId" placeholder="工厂id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="系列" prop="productSeries">
        <el-input v-model="dataForm.productSeries" placeholder="系列"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="productCategory">
        <el-input v-model="dataForm.productCategory" placeholder="分类"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="表面" prop="productSurface">
        <el-input v-model="dataForm.productSurface" placeholder="表面"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="行业" prop="productIndustry">
        <el-input v-model="dataForm.productIndustry" placeholder="行业"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="库存" prop="productNum">
        <el-input v-model="dataForm.productNum" placeholder="库存"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="dataForm.remarks" placeholder="备注"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否删除（0.未删除，1.删除）" prop="delFlag">
        <el-input v-model="dataForm.delFlag" placeholder="是否删除（0.未删除，1.删除）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="处理状态（0.未处理，1.完全接纳，2.比例微调后接纳，3.不采纳）" prop="state">
        <el-input v-model="dataForm.state" placeholder="处理状态（0.未处理，1.完全接纳，2.比例微调后接纳，3.不采纳）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateDate">
        <el-input v-model="dataForm.updateDate" placeholder=""  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/mixpowder'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          sampleOrderId: '',
          sampleOrderNo: '',
          l: '',
          a: '',
          b: '',
          glossValue: '',
          factortId: '',
          productSeries: '',
          productCategory: '',
          productSurface: '',
          productIndustry: '',
          productNum: '',
          remarks: '',
          delFlag: '',
          state: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: ''
        },
        dataRule: {
          sampleOrderId: [
            { required: true, message: '订单id不能为空', trigger: 'blur' }
          ],
          sampleOrderNo: [
            { required: true, message: '订单编号不能为空', trigger: 'blur' }
          ],
          l: [
            { required: true, message: 'L不能为空', trigger: 'blur' }
          ],
          a: [
            { required: true, message: 'A不能为空', trigger: 'blur' }
          ],
          b: [
            { required: true, message: 'B不能为空', trigger: 'blur' }
          ],
          glossValue: [
            { required: true, message: '光泽值不能为空', trigger: 'blur' }
          ],
          factortId: [
            { required: true, message: '工厂id不能为空', trigger: 'blur' }
          ],
          productSeries: [
            { required: true, message: '系列不能为空', trigger: 'blur' }
          ],
          productCategory: [
            { required: true, message: '分类不能为空', trigger: 'blur' }
          ],
          productSurface: [
            { required: true, message: '表面不能为空', trigger: 'blur' }
          ],
          productIndustry: [
            { required: true, message: '行业不能为空', trigger: 'blur' }
          ],
          productNum: [
            { required: true, message: '库存不能为空', trigger: 'blur' }
          ],
          remarks: [
            { required: true, message: '备注不能为空', trigger: 'blur' }
          ],
          delFlag: [
            { required: true, message: '是否删除（0.未删除，1.删除）不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '处理状态（0.未处理，1.完全接纳，2.比例微调后接纳，3.不采纳）不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.sampleOrderId = data.mixpowder.sampleOrderId
                this.dataForm.sampleOrderNo = data.mixpowder.sampleOrderNo
                this.dataForm.l = data.mixpowder.l
                this.dataForm.a = data.mixpowder.a
                this.dataForm.b = data.mixpowder.b
                this.dataForm.glossValue = data.mixpowder.glossValue
                this.dataForm.factortId = data.mixpowder.factortId
                this.dataForm.productSeries = data.mixpowder.productSeries
                this.dataForm.productCategory = data.mixpowder.productCategory
                this.dataForm.productSurface = data.mixpowder.productSurface
                this.dataForm.productIndustry = data.mixpowder.productIndustry
                this.dataForm.productNum = data.mixpowder.productNum
                this.dataForm.remarks = data.mixpowder.remarks
                this.dataForm.delFlag = data.mixpowder.delFlag
                this.dataForm.state = data.mixpowder.state
                this.dataForm.createBy = data.mixpowder.createBy
                this.dataForm.createDate = data.mixpowder.createDate
                this.dataForm.updateBy = data.mixpowder.updateBy
                this.dataForm.updateDate = data.mixpowder.updateDate
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'sampleOrderId': this.dataForm.sampleOrderId,
              'sampleOrderNo': this.dataForm.sampleOrderNo,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b,
              'glossValue': this.dataForm.glossValue,
              'factortId': this.dataForm.factortId,
              'productSeries': this.dataForm.productSeries,
              'productCategory': this.dataForm.productCategory,
              'productSurface': this.dataForm.productSurface,
              'productIndustry': this.dataForm.productIndustry,
              'productNum': this.dataForm.productNum,
              'remarks': this.dataForm.remarks,
              'delFlag': this.dataForm.delFlag,
              'state': this.dataForm.state,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增规则' : '修改规则'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="productfilterrule"
    width="600px">
    <div slot="title" class="header-title">
      <span class="title-age" style="cursor:pointer;" title="个性化属性与标准化属性的关联规则，用于【小程序】的筛选条件">{{!dataForm.id ? '新增规则' : '修改规则'}}<i class="el-icon-question" style="margin-left:5px;"></i></span>
    </div>
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm"  label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="规则名称" prop="relationName">
        <el-input v-model="dataForm.relationName" placeholder="规则名称"  size="small" maxlength="30" style="width:200px;"></el-input>
      </el-form-item>
      <el-form-item prop="brandId" label="适用品牌">
        <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" @change="getproductAttrInfo(dataForm.brandId)" :disabled="dataForm.id ? true : false">
          <el-option label="通用" value="0"></el-option>
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="一级属性" prop="mainAttrId">
        <span slot="label">
            <span class="span-box" title="设置当前筛选规则的第一层级的属性" style="cursor:pointer">
                <span>一级属性</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <el-select v-model="dataForm.mainAttrId" size="small" placeholder="请选择一级属性" @change="selectConfigurableAttr('1')" :disabled="dataForm.id ? true : false">
          <el-option
            v-for="item in productAttrList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            size="small"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="isMultiple" label="是否多选">
        <span slot="label">
            <span class="span-box" title='设置当前筛选规则中最后一层级（可能是第二层级或第三层级）的属性是否支持多选，
若设置"是"，在当前规则的最后一级属性可以多选，否则不支持多选' style="cursor:pointer">
                <span>是否多选</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <el-radio-group v-model="dataForm.isMultiple">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="relationLevel" label="级联级别">
        <span slot="label">
            <span class="span-box" title='设置当前筛选规则的关联层级，若设置"二级"，表示当前筛选规则有两个属性关联，
若设置"三级"，表述当前筛选规则有三个属性关联' style="cursor:pointer">
                <span>级联级别</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <el-radio-group v-model="dataForm.relationLevel" :disabled="dataForm.id ? true : false">
          <el-radio label="0">二级</el-radio>
          <el-radio label="1">三级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="" label="次级属性配置" title="若设置“显示”，当前属性值显示在【小程序】筛选条件中；否则“隐藏”，则不显示">
        <span slot="label">
            <span class="span-box" style="cursor:pointer">
                <span>次级属性配置</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
      </el-form-item>
      <div class="div">
        <div style="padding:20px;">
          <el-radio-group v-model="dataForm.secondAttrId" @change="selectConfigurableAttr('2')" :disabled="dataForm.id ? true : false">
            <el-radio v-for="(item,index) in secondAttrIdList " v-show="!dataForm.id || dataForm.secondAttrId == item.productAttrId" :key="index" :label="item.productAttrId" style="width:100%;margin-bottom:10px;">{{item.attrName}}</el-radio>
          </el-radio-group>
        </div>
        <div style="padding:20px;" v-if="dataForm.relationLevel == '1'">
          <el-radio-group v-model="dataForm.threeAttrId" :disabled="dataForm.id ? true : false">
            <el-radio v-for="(item,index) in threeAttrIdList " v-show="!dataForm.id || dataForm.threeAttrId == item.productAttrId" :key="index" :label="item.productAttrId" style="width:100%;margin-bottom:10px;">{{item.attrName}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="tips">每次创建只能选择一个属性</div>
      <!-- <el-form-item prop="isOpen" label="状态">
        <el-radio-group v-model="dataForm.isOpen" :disabled="!dataForm.id ? true : false">
          <el-radio label="1">禁用</el-radio>
          <el-radio label="0">启用</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="dataForm.remarks" type="textarea"  size="small" style="width:350px;" :autosize="{ minRows: 5, maxRows: 5}"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
      <el-button @click="visible = false" size="small">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getBrand } from "@/api/modules/product";
  import {getproductAttrInfo} from '@/api/modules/productattrvalue'
  import {selectConfigurableAttr, add, update, info} from '@/api/modules/productfilterrule'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        brandList:[],
        productAttrList:[],
        secondAttrIdList:[],
        threeAttrIdList:[],
        dataForm: {
          id: 0,
          relationName:'',
          brandId:'',
          relationLevel:'0',
          isMultiple:'1',
          mainAttrId:'',
          secondAttrId: '',
          threeAttrId: '',
          isOpen: '1',
          remarks:'',
        },
        dataRule: {
          relationName: [
            { required: true, message: '规则名称不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '适用品牌不能为空', trigger: 'blur' }
          ],
          relationLevel: [
            { required: true, message: '级联级别不能为空', trigger: 'blur' }
          ],
          isMultiple: [
            { required: true, message: '是否多选不能为空', trigger: 'blur' }
          ],
          mainAttrId: [
            { required: true, message: '一级属性不能为空', trigger: 'blur' }
          ],
          isOpen: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id,brandId,twoname,threename) {
        this.dataForm.id = id || 0
        this.secondAttrIdList = [],
        this.threeAttrIdList = [],
        this.productAttrList = [],
        this.visible = true
        this.getBrandList();
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            let params = {
              id:this.dataForm.id,
              brandId: brandId == '0' ? '' : brandId
            };
            info(params).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.relationName = data.productAttrRelation.relationName
                this.dataForm.brandId = data.productAttrRelation.brandId
                this.getproductAttrInfo(this.dataForm.brandId)
                this.dataForm.relationLevel = data.productAttrRelation.relationLevel
                this.dataForm.isMultiple = data.productAttrRelation.isMultiple
                this.dataForm.mainAttrId = data.productAttrRelation.mainAttrId
                // this.selectConfigurableAttr('1')
                this.dataForm.secondAttrId = data.productAttrRelation.secondAttrId
                this.secondAttrIdList=[{
                  productAttrId:this.dataForm.secondAttrId,
                  attrName:twoname
                }]
                // this.selectConfigurableAttr('2')
                this.dataForm.threeAttrId = data.productAttrRelation.threeAttrId
                this.threeAttrIdList=[{
                  productAttrId:this.dataForm.threeAttrId,
                  attrName:threename
                }]
                this.dataForm.isOpen = data.productAttrRelation.isOpen
                this.dataForm.remarks = data.productAttrRelation.remarks
              }
            })
          }
        })
      },
      getBrandList() {
        let params = {};
        getBrand(params).then(({ data }) => {
            if (data && data.code === 0) {
                this.brandList = data.list;
            } else {
                this.brandList = [];
            }
        });
      },
      getproductAttrInfo(param){
        this.dataForm.mainAttrId = null;
        this.dataForm.secondAttrId = '';
        this.dataForm.threeAttrId = '';
        this.secondAttrIdList = [];
        this.threeAttrIdList = [];
          let params = {
            brandId : param == null || param == '0' || param == ' ' ? '' : param
          };
          getproductAttrInfo(params).then(({ data }) => {
              if (data && data.code === 0) {
                  this.productAttrList = data.list;
              } else {
                  this.productAttrList = [];
              }
          });
      },
      selectConfigurableAttr(val){
        this.dataForm.threeAttrId = '';
        if (val == '1') {
          this.dataForm.secondAttrId = '';
        }
        let params = {
            mainAttrId:this.dataForm.mainAttrId,
            secondAttrId: val == '2' ? this.dataForm.secondAttrId : null,
            brandId: this.dataForm.brandId == null || this.dataForm.brandId == '0' || this.dataForm.brandId == ' ' ? '' : this.dataForm.brandId
          };
          selectConfigurableAttr(params).then(({ data }) => {
              if (data && data.code === 0) {
                  if (val == '1') {
                    this.secondAttrIdList = data.data
                  }else if (val == '2') {
                    this.threeAttrIdList = data.data
                  }
              }
          });
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (this.dataForm.secondAttrId && this.dataForm.relationLevel == '0' || this.dataForm.relationLevel == '1' && this.dataForm.threeAttrId && this.dataForm.secondAttrId) {
              this.dataLoading = true;
              var brandId = ''
              if (this.dataForm.id) {
                brandId = this.dataForm.brandId =='0' || this.dataForm.brandId==' ' ? '' : this.dataForm.brandId
              }else{
                brandId = this.dataForm.brandId
              }
              var params = {
                'id': this.dataForm.id || undefined,
                'relationName': this.dataForm.relationName,
                'brandId': this.dataForm.brandId=='0' || this.dataForm.brandId==' ' ? '' : this.dataForm.brandId,
                'relationLevel': this.dataForm.relationLevel,
                'isMultiple': this.dataForm.isMultiple,
                'mainAttrId': this.dataForm.mainAttrId,
                'secondAttrId': this.dataForm.secondAttrId,
                'threeAttrId': this.dataForm.threeAttrId,
                'isOpen': this.dataForm.isOpen,
                'remarks': this.dataForm.remarks
              }
              var tick = !this.dataForm.id ? add(params) : update(params)
              tick.then(({data}) => {
                if (data && data.code === 0) {
                  this.$message({
                      message: '操作成功',
                    type: 'success',
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.dataLoading = false;
                      this.$emit('refreshDataList')
                    }
                  })
                } else {
                  this.$message.error(data.msg)
                  this.dataLoading = false;
                }
              })
            }else{
              this.$message.warning("请配置次级属性")
            }
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.productfilterrule{
  .tips{
    width:100%;
    text-align:center;
    margin: 20px auto;
  }
  .div{
    display: flex;
    justify-content: space-around;
    margin-top:-15px;
    >div{
      width: 245px;
      min-height: 200px;
      border: 1px solid #999
    }
    // >div:nth-child(1){
    //   margin: 0 25px;
    // }
  }
}
</style>

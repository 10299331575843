import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/device/device/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/device/device/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/device/device/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/device/device/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/device/device/delete',
    method: 'post',
    data: params
  })
}

//型号下拉列表
export function dictList (params) {
  return request({
    url: '/device/device/dictList',
    method: 'post',
    data: params
  })
}

//工厂下拉列表
export function factoryList (params) {
  return request({
    url: '/factory/factory/factorylist',
    method: 'post',
    data: params
  })
}

// 获取列表
export function setDeviceState (params) {
  return request({
    url: '/device/device/setDeviceState',
    method: 'get',
    params: params
  })
}
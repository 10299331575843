<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="orderNo">
        订单编号
        <el-input v-model="dataForm.orderNo" placeholder="订单编号" size="small" clearable
                  style="width: 60%" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select v-model="dataForm.sampleType" placeholder="订单类型">
          <el-option
                  v-for="item in sampleTypes"
                  :key="item.sampleTypeKey"
                  :label="item.sampleTypeValue"
                  :value="item.sampleTypeKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单日期">
        <div class="block">
          <el-date-picker
                  v-model="dataForm.createDates"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="this.vo.roleName !='打样员'" type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      :default-sort = "{prop: 'createDate', order: 'descending'}"
      @selection-change="selectionChangeHandle"
      @sort-change="sortChange"
      style="width: 100%;">
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        fixed="left"
        label="订单编号">
      </el-table-column>
      <el-table-column
        prop="thirdOrderNo"
        header-align="center"
        align="center"
        fixed="left"
        label="平台编号">
      </el-table-column>
      <el-table-column
        prop="orderSource"
        header-align="center"
        align="center"
        label="订单来源">
        <template slot-scope="scope">
          <span v-if="scope.row.orderSource=='0'">管理系统</span>
          <span v-if="scope.row.orderSource=='1' || scope.row.orderSource=='3'">小程序</span>
          <span v-if="scope.row.orderSource=='2'">门户网站</span>
        </template>
      </el-table-column>
      <el-table-column
              prop="productCartegry"
              header-align="center"
              align="center"
              label="产品类别">
        <template slot-scope="scope">
          <span v-if="scope.row.productCartegry=='0'">金属粉</span>
          <span v-if="scope.row.productCartegry=='1'">单色粉</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sampleType"
        header-align="center"
        align="center"
        label="打样类别">
        <template slot-scope="scope">
          <span v-if="scope.row.sampleType=='0'">新产品打样</span>
          <span v-if="scope.row.sampleType=='1'">样粉样板申请</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="dosage"
        header-align="center"
        align="center"
        label="预计用量（公斤）">
      </el-table-column>
      <el-table-column
        prop="materialSum"
        header-align="center"
        align="center"
        label="样粉数量（公斤）">
      </el-table-column>
      <el-table-column
        prop="modelSum"
        header-align="center"
        align="center"
        label="样板数量">
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="预计价格">
      </el-table-column>
      <el-table-column
        prop="contactsName"
        header-align="center"
        align="center"
        label="客户">
      </el-table-column>
      <el-table-column
        prop="contactsLevel"
        header-align="center"
        align="center"
        :sortable="'custom'"
        width="100px;"
        label="客户等级">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="订单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">未分配</span>
          <span v-if="scope.row.state=='1'">打样中</span>
          <span v-if="scope.row.state=='2'">待验证</span>
          <span v-if="scope.row.state=='3'">已完成</span>
          <span v-if="scope.row.state=='4'">验证驳回</span>
          <span v-if="scope.row.state=='5'">待结单</span>
          <span v-if="scope.row.state=='6'">待打样</span>
          <span v-if="scope.row.state=='7'">待自检</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliverDate"
        header-align="center"
        align="center"
        :sortable="'custom'"
        width="100px;"
        label="交付日期">
        <template slot-scope="scope">
          <span>{{scope.row.deliverDate|formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        :sortable="'custom'"
        width="100px;"
        label="订单日期">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <!--<el-button type="text" size="small" @click="detail(scope.row.id)">订单详情</el-button>-->
          <el-button v-if= "scope.row.state == 0 && isAuth('sample:sampleorder:sampleHandle') " type="text" size="small" @click="SampleHandle(scope.row.id,scope.row.sampleOrderNo)">打样分配</el-button>
          <el-button v-if= "scope.row.state != 0 &&  isAuth('sample:sampleorder:proofingList') " type="text" size="small" @click="SampleListHandle(scope.row.id,scope.row.cartegryNameS)">打样单</el-button>
          <el-button v-if= "scope.row.state != 0 &&  isAuth('sample:sampleorder:myproofing') " type="text" size="small" @click="SampleListHandle(scope.row.id,scope.row.cartegryNameS)">我的打样</el-button>
          <el-button  v-if="dataList.length>0 && scope.row.state == '5' && isAuth('sample:sampleorder:statement') " type="text" size="small" @click="addOrUpdateHandleTo(scope.row.id,scope.row.sampleOrderNo)">结单</el-button>
          <el-button  v-if="dataList.length>0 && scope.row.state == '3' && isAuth('sample:sampleorder:delivergoods') " type="text" size="small" @click="billOfParcelsTo(scope.row.sampleOrderNo)">发货单</el-button>
          <el-button v-if= " isAuth('sample:sampleorder:edit')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">订单编辑</el-button>
          <el-button v-if= "(scope.row.state == 0 || scope.row.state == 1 || scope.row.state == 6 || scope.row.state == 7) && isAuth('sample:sampleorder:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          <el-button v-if= "isAuth('sample:sampleorder:view')" type="text" size="small" @click="addOrUpdateHandleInfo(scope.row.id)">订单详情</el-button>
            <el-button v-if="isAuth('sample:sampleorder:checkColor')" type="text" size="small" @click="queryColor(scope.row)">查色</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <detail v-if="detailVisible" ref="detail" @refreshDataList="getDataList"></detail>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <orderInfo v-if="orderInfoVisible" ref="orderInfo" @refreshDataList="getDataList"></orderInfo>
    <sample v-if="sampleVisible" ref="sample" @refreshDataList="getDataList"></sample>
    <sampleList v-if="sampleListVisible" ref="sampleList" @refreshDataList="getDataList"></sampleList>
    <productupdatejd v-if="productupdateJDVisible" ref="productupdatejd" @refreshDataList="getDataList"></productupdatejd>
    <billOfParcels v-if="billOfParcelsVisible" ref="billOfParcels" @refreshDataList="getDataList"></billOfParcels>

  </div>
</template>

<script>
  import {list,del,checkColor} from '@/api/modules/sampleorder'
  import AddOrUpdate from './add-or-update'
  import detail from './detail'
  import sample from './sample'
  import sampleList from './sampleList'
  import orderInfo from "./orderInfo";
  import productupdatejd from './productupdatejd';
  import billOfParcels from './billOfParcels';
  import {nameAndRole} from '../../api/modules/product';
  export default {
    data () {
      return {
        dataForm: {
          orderNo: '',
          company: '',
          contactsName: '',
          contactsPhone: '',
          deliverDate:[],
          createDates:[],
        },
        States:[
            {stateKey:'0', stateValue:'未分配'},
            {stateKey:'6', stateValue:'待打样'},
            {stateKey:'1', stateValue:'打样中'},
            {stateKey:'7', stateValue:'待自检'},
          {stateKey:'2', stateValue:'待验证'},
          {stateKey:'3', stateValue:'已完成'},
          {stateKey:'4', stateValue:'验证驳回'},
          {stateKey:'5', stateValue:'待结单'}
        ],
        sampleTypes:[
          {sampleTypeKey:'0', sampleTypeValue:'新产品打样'},
          {sampleTypeKey:'1', sampleTypeValue:'样粉样板申请'},
        ],
        vo:{},
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        orderInfoVisible: false,
        detailVisible: false,
        sampleVisible: false,
        sampleListVisible: false,
        productupdateJDVisible:false,
          billOfParcelsVisible:false,
        createDate:'DESC',
        contactsLevel:'',
        deliverDate:''
      }
    },
    components: {
      productupdatejd,
      orderInfo,
      AddOrUpdate,
      detail,
      sample,
      sampleList,
        billOfParcels
    },
      filters: {
          formatDate(val) {
              if (!val) return '- -';
              var date = new Date(val.replace(/-/g,'/'));
              var year = date.getFullYear();
              var month = date.getMonth() + 1;
              month = month < 10 ? '0' + month : month;
              var day = date.getDate();
              day = day < 10 ? '0' + day : day;
              return year + '-' + month + '-' + day ;
          }
      },
    created () {
      this.getDataList()
    },
    methods: {
      sortChange (column, prop, order) {
        if (column.prop === 'contactsLevel') {
          if (column.order === null) {
            this.clear()
          }
          if (column.order === 'descending') {
            this.createDate='',
            this.contactsLevel='DESC',
            this.deliverDate=''
          }
          if (column.order === 'ascending') {
            this.createDate='',
            this.contactsLevel='ASC',
            this.deliverDate=''
          }
        }
        if (column.prop === 'deliverDate') {
          if (column.order === null) {
            this.clear()
          }
          if (column.order === 'descending') {
            this.createDate='',
            this.contactsLevel='',
            this.deliverDate='DESC'
          }
          if (column.order === 'ascending') {
            this.createDate='',
            this.contactsLevel='',
            this.deliverDate='ASC'
          }
        }
        if (column.prop === 'createDate') {
          if (column.order === null) {
            this.clear()
          }
          if (column.order === 'descending') {
            this.createDate='DESC',
            this.contactsLevel='',
            this.deliverDate=''
          }
          if (column.order === 'ascending') {
            this.createDate='ASC',
            this.contactsLevel='',
            this.deliverDate=''
          }
        }
        this.restPage()
      },
        clear(){
          this.createDate='',
          this.contactsLevel='',
          this.deliverDate=''
        },
        //查色按钮
        queryColor(row){
            let params = {
                'id':row.id,
            }
            checkColor(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.$router.push({ path: "/productLogin", query:{"surface":row.surface,"factoryId":row.factoryId,"productCartegry":row.productCartegry,"gloss":row.gloss,"glossValue":row.glossValue,"lAve":row.lAve,"aAve":row.aAve,"bAve":row.bAve} });
                } else {
                    this.$message.error(data.msg);
                }
            })
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
        this.dataForm.sampleOrderNo='',
        this.dataForm.state='',
        this.dataForm.sampleType='',
        this.dataForm.paymentType='',
        this.dataForm.createDates=[],
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var arrOrderTime = this.dataForm.createDates;
        var strOrderTime = "";
        var startDate = null;
        var endDate = null;
        if (arrOrderTime.length > 0) {
          startDate = arrOrderTime[0];
          endDate = arrOrderTime[1];
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          'sampleOrderNo': this.dataForm.orderNo,
          'state': '0',
          'sampleType': this.dataForm.sampleType,
          'startDate': startDate,
          'endDate': endDate,
          'createDate': this.createDate,
          'contactsLevel': this.contactsLevel,
          'deliverDate': this.deliverDate,
        }
        nameAndRole(params).then(({data}) => {
          if (data && data.code === 0) {
            this.vo = data.vo;
          } else {
            this.vo = {}
          }
        }),
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 详情
      addOrUpdateHandleInfo (id) {
        this.orderInfoVisible = true
        this.$nextTick(() => {
          this.$refs.orderInfo.init(id)
        })
      },
      detail (id) {
        this.detailVisible = true
        this.$nextTick(() => {
          this.$refs.detail.init(id)
        })
      },
      //打样
      SampleHandle(id,orderNo) {
        this.sampleVisible = true
        this.$nextTick(() => {
          this.$refs.sample.init(id,orderNo)
        })
      },
        //发货单
        billOfParcelsTo(sampleOrderNo){
            this.billOfParcelsVisible = true;
            this.$nextTick(() => {
                this.$refs.billOfParcels.init(sampleOrderNo)
            })
        },
      //结单弹框
      addOrUpdateHandleTo (sampleOrderNo) {
        this.productupdateJDVisible = true
        this.$nextTick(() => {
          this.$refs.productupdatejd.init(sampleOrderNo)
        })
      },
      //打样单
      SampleListHandle(id,category) {
        this.sampleListVisible = true
        this.$nextTick(() => {
          this.$refs.sampleList.init(id,category)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对此条记录进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.select{
  color: #7679ff !important;
}
</style>

<template>
    <el-dialog
            :title=" '设备授权'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-divider></el-divider>
        <el-form :model="dataForm"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
                 v-loading="dataLoading" style="width:55%;margin:0  auto"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >

              <h3>设备信息</h3>
              <div class="div">
                  <el-form-item label="设备型号" prop="deviceModel">
                      <el-input  style="width: 77%" v-model="dataForm.deviceModel" placeholder="设备型号"  size="small" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="设备编号" prop="deviceNo">
                      <el-input style="width: 77%" v-model="dataForm.deviceNo" placeholder="设备编号"  size="small" disabled></el-input>
                  </el-form-item>
              </div>
            <h3>授权用户信息</h3>
            <div class="div1">
                <el-form-item label="用户类型" >
                    <el-select style="width: 77%" v-model="dataForm.roleId" clearable placeholder="选择用户类型" @change="getUserList()">
                        <el-option
                                v-for="item in roleTypeList"
                                :key="item.roleId"
                                :label="item.roleName"
                                :value="item.roleId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属企业" prop="enterprise">
                    <el-input style="width: 77%" v-model="dataForm.enterprise" placeholder="所属企业"  size="small" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="description">
                    <el-select style="width: 77%" v-model="dataForm.userId" clearable placeholder="选择用户类型">
                        <el-option
                                v-for="item in userList"
                                :key="item.userId"
                                :label="item.name ? item.name : item.username"
                                :value="item.userId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <h3>授权管理</h3>
            <div class="div">
                <el-form-item label="授权期限" prop="time">
                    <template>
                        <div class="block">
                            <el-date-picker
                                    style="width: 90%"
                                    v-model="dataForm.time"
                                    type="daterange"
                                    align="right"
                                    range-separator="——"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </template>
                </el-form-item>
                <el-form-item label="授权状态" prop="state">
                    <template>
                        <el-radio v-model="dataForm.state" label="0">启用</el-radio>
                        <el-radio v-model="dataForm.state" label="1">停用</el-radio>
                    </template>
                </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="closeSubmit()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {add,roleTypeList,UserList,deviceInfo} from '@/api/modules/deviceuser'
    import { getRoleListParams } from "@/api/sys";
    export default {
        data () {
            return {
                roleTypeList:[],
                userList:[],
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    enterprise:'',
                    deviceModel:'',
                    deviceNo:'',
                    userId:'',
                    time:[],
                    state:'0',
                }
            }
        },
        methods: {
            init (id) {
                this.getroleTypeList(),
                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        let params = {
                            id:this.dataForm.id
                        }
                        deviceInfo(params).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.deviceModel = data.deviceIn.deviceModel;
                                this.dataForm.deviceNo = data.deviceIn.deviceNo;
                            }
                        })
                    }
                })
            },
            //获取用户类型下拉列表
            getroleTypeList(){
                var params = {
                    roleType: '2'
                };
                getRoleListParams(params).then(({ data })=>{
                    if(data && data.code === 0 ){
                        this.roleTypeList = data.list;
                    }
                })
            },
            //获取用户下拉列表
            getUserList(){
                let params = {
                    roleId:this.dataForm.roleId,
                    deviceId:this.dataForm.id
                }
                UserList(params).then(({ data })=>{
                    if(data && data.code === 0 ){
                        this.userList = data.userList;
                    }
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'deviceId': this.dataForm.id || undefined,
                            'enterprise': this.dataForm.enterprise,
                            'userId': this.dataForm.userId,
                            'startDate': this.dataForm.time[0],
                            'endDate': this.dataForm.time[1],
                            'state': this.dataForm.state
                        }
                        add(params).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.dataLoading = false;
                                        // this.$emit('refreshDataList')
                                        this.dataForm.roleName = '';
                                        this.dataForm.userId = '';
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                                this.dataForm.roleName = '';
                                this.dataForm.userId = '';
                            }
                        })
                    }
                })
            },
            //关闭
            closeSubmit(){
                this.dataForm.userId = '';
               this.dataForm.roleName = '';
                this.visible = false;
            }
        }
    }
</script>
<style scoped>
    .div
    {
        /*width:400px;*/
        /*height:120px;*/
        border: 2px solid #ececec;
        box-shadow: 10px 10px 5px #888888;
        padding: 15px 0;
        margin-bottom: 35px;
    }
    .div1
    {
        /*width:400px;*/
        /*height:150px;*/
        border: 2px solid #ececec;
        -moz-box-shadow: 10px 10px 5px #888888; /* 老的 Firefox */
        box-shadow: 10px 10px 5px #888888;
        padding: 15px 0;
        margin: 35px 0;
    }
</style>
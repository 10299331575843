<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from "echarts";
// require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import {findBarValueDir} from '@/api/modules/record'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      default: () => []
    },
    chartIsLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chart: null,

    };
  },
  mounted() {
    if (this.chartIsLoad) {
      //this.getLineStatistics();
    } else {
      this.initChart(this.chartData);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(data) {
      this.chart = echarts.init(this.$el, "macarons");
      var allBar = data.barx;
      var allBarDetail = data.bary;
      let options = {
         xAxis: {
          data: allBar,
          boundaryGap: false,
          axisTick: {
            show: true
          },
          axisLabel: {  
            interval:0,  
            rotate:50  
          }
        },
        
        grid: {
          left: 30,
          right: 30,
          bottom: 20,
          top: 20,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type : 'shadow'
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: true
          }
        },
        series: [{
          name: '新增数量', 
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            normal: {
              color: '#FF005A',
              lineStyle: {
                color: '#FF005A',
                width: 2
              }
            }
          },
          smooth: false,
          type: 'line',
          data: allBarDetail,
          animationDuration: 1000,
          animationEasing: 'linear'
        }]
      };

      this.chart.setOption(options);
    },

    async getLineStatistics(value) {
      //console.log("value值",JSON.stringify(value));
      const params = {
        'type': value,
      }
      //console.log("params值",JSON.stringify(params));
      const { data } = await findBarValueDir(params);
      if (data && data.code === 0) {
        this.$nextTick(() => {
          this.initChart(data.productAddBar);
        });
        this.$emit('lineChartemit',data.productAddBar)
      }
    }
  }
}
</script>

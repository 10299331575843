<template>
  <el-dialog
    :title="!dataForm.id ? '修改' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
  >
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >

      <div style="display: flex">
      <div style="width: 70%;">
      <el-form-item label="测量LAB值">
        <div>
          <el-button size="small" type="primary" class="mr10" @click="syncDevice()" :loading="loading">数据同步</el-button>
        </div>
      </el-form-item>
      <el-form-item label="LAB值">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="l">
              <el-input v-model="dataForm.l" placeholder="L值" class="w150 mr10" size="small"
                        clearable maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="a">
              <el-input v-model="dataForm.a" placeholder="A值" class="w150 mr10" size="small"
                        clearable maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="b">
              <el-input v-model="dataForm.b" placeholder="B值" class="w150 mr10" size="small"
                        clearable maxlength="30"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <!--二维码通过后台自动生成存库，前端修改产品编号保存，后台二维码会自动变化，不再前端做新增和编辑-->
      </div>

      <div style="width: 30%;margin-top: 0px">
        <el-form-item label="" prop="picUrl" label-width="0px">
          <el-upload
                  class="avatar-uploader"
                  :action="action"
                  :show-file-list="false"
                  :on-success="handleUploadSuccess"
                  :before-upload="beforeUpload">
            <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
          </el-upload>
        </el-form-item>
      </div>
      </div>
      <el-form-item label="">
        <el-collapse>
          <el-collapse-item >
            <span class="collapse-title" slot="title">查看测量数据</span>
            <div>
              <ul class="productLAB">
                <li>
                  <span v-for="x in productLAB.title" class="title">{{x}}</span>
                </li>
                <li v-for="(x,index) in productLAB.data">
                  <span class="title">{{x}}</span>
                  <el-input v-model="dataForm[`l${index+1}`]" placeholder="L值" class="w100" disabled="disabled"></el-input>
                  <el-input v-model="dataForm[`a${index+1}`]" placeholder="A值" class="w100" disabled="disabled"></el-input>
                  <el-input v-model="dataForm[`b${index+1}`]" placeholder="B值" class="w100" disabled="disabled"></el-input>
                </li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
      <div style="display: flex">
        <div style="width: 70%;">
          <el-form-item label="光泽值" prop="glossValue">
            <el-input v-model="dataForm.glossValue" placeholder="光泽值" size="small"
                      @input="getSelectGloss()" style="width: 100%" maxlength="6"></el-input>
          </el-form-item>
        </div>
        <div style="width: 30%;">
          <el-form-item label="" prop="gloss" label-width="20px">
            <el-select v-model="dataForm.gloss" size="small" placeholder=""
                       style="width: 100%" disabled="disabled">
              <el-option
                      v-for="item in productGlossList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                      size="small"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="">
        <el-collapse>
          <el-collapse-item>
            <span class="collapse-title" slot="title">查看参考标准</span>
            <div style="display: flex" >
              <div style="width: 60%">
                <p class="ziti">无光：无光泽范围0~15</p>
                <p class="ziti">哑光：光泽范围16~25</p>
                <p class="ziti">弱光：光泽范围26~35</p>
                <p class="ziti">半哑光：光泽范围36~54</p>
              </div>
              <div style="width: 40%">
                <p class="ziti">半光：光泽范围55~65</p>
                <p class="ziti">弱高光：光泽范围66~79</p>
                <p class="ziti">高光：光泽范围80~95</p>
                <p class="ziti">超高光：光泽范围95+</p>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
      <el-form-item label="标准色号" prop="ralColorNo">
        <el-input v-model="dataForm.ralColorNo" placeholder="产品标准色号" size="small"
                  style="width: 100%" maxlength="30"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    queryNoSeries,
    queryNoColor,
    getFactoryBrand,
    selectGloss,
    getDict,
    syncDevice,
    syncDeviceData,
  } from '@/api/modules/product'
  import {info,add,update} from '@/api/modules/sampleproduct'
  import Cookies from "js-cookie";
  import {getToken} from "@/utils/auth";
  export default {
    data () {
      return {
        productGlossList: [],
        productLAB: {
          title: ["角度", "L值", "A值", "B值"],
          data: ["25度", "45度", "75度"]
        },
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        loading: false,
        timer: null, //首先我在data函数里面进行定义定时器名称：
        timerNum: 5, // 设置定时器时间
        device:{
          deviceId:''
        },
        dataForm: {
          id: 0,
          //sampleNo: '',
          sampleOrderId: '',
          sampleOrderNo: '',
          picUrl: '',
          qrUrl: '',
          ralColorNo: '',
          gloss: '',
          glossValue: '',
          surface: '',
          factoryId: '',
          userId: '',
          laboratory: '',
          description: '',
          l: '',
          a: '',
          b: '',
          state: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: ''
        },
        dataRule: {

          // ralColorNo: [
          //   { required: true, message: '样品RAL色号不能为空', trigger: 'blur' }
          // ],
          // gloss: [
          //   { required: true, message: '样品光泽不能为空', trigger: 'blur' }
          // ],
          // glossValue: [
          //   { required: true, message: '样品光泽值不能为空', trigger: 'blur' }
          // ],
          // surface: [
          //   { required: true, message: '样品表面不能为空', trigger: 'blur' }
          // ],
          // factoryId: [
          //   { required: true, message: '工厂id不能为空', trigger: 'blur' }
          // ],
          // userId: [
          //   { required: true, message: '打样员id不能为空', trigger: 'blur' }
          // ],
          // laboratory: [
          //   { required: true, message: '实验室名称不能为空', trigger: 'blur' }
          // ],
          // description: [
          //   { required: true, message: '打样说明不能为空', trigger: 'blur' }
          // ],
          // l: [
          //   { required: true, message: '样品L值不能为空', trigger: 'blur' }
          // ],
          // a: [
          //   { required: true, message: '样品A值不能为空', trigger: 'blur' }
          // ],
          // b: [
          //   { required: true, message: '样品B值不能为空', trigger: 'blur' }
          // ],
          // state: [
          //   { required: true, message: '样品状态不能为空', trigger: 'blur' }
          // ],
          // createBy: [
          //   { required: true, message: '操作人不能为空', trigger: 'blur' }
          // ],
          // createDate: [
          //   { required: true, message: '操作时间不能为空', trigger: 'blur' }
          // ],
          // updateBy: [
          //   { required: true, message: '修改人不能为空', trigger: 'blur' }
          // ],
          // updateDate: [
          //   { required: true, message: '修改时间不能为空', trigger: 'blur' }
          // ],
          // remarks: [
          //   { required: true, message: '不能为空', trigger: 'blur' }
          // ]
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    methods: {
      init (id) {
        this.getProductGlossList();
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()

        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'sampleNo': this.dataForm.sampleNo,
              'sampleOrderId': this.dataForm.sampleOrderId,
              'sampleOrderNo': this.dataForm.sampleOrderNo,
              'picUrl': this.dataForm.picUrl,
              'qrUrl': this.dataForm.qrUrl,
              'ralColorNo': this.dataForm.ralColorNo,
              'gloss': this.dataForm.gloss,
              'glossValue': this.dataForm.glossValue,
              'surface': this.dataForm.surface,
              'factoryId': this.dataForm.factoryId,
              'userId': this.dataForm.userId,
              'laboratory': this.dataForm.laboratory,
              'description': this.dataForm.description,
              'l': this.dataForm.l,
              'a': this.dataForm.a,
              'b': this.dataForm.b
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },

      //设备同步
      syncDevice() {
        this.loading = true;
        this.device.deviceId = Cookies.get('deviceId')
        let params ={
          deviceId:this.device.deviceId
        }
        syncDevice(params).then(({data}) => {
          if (data && data.code === 0) {
            this.timer = setInterval(() => {  //创建定时器
              if (this.timerNum === 0) { // 设置的定时器时间为0后执行的操作
                this.clearTimer(); // 关闭定时器
                this.loading = false;
                this.timerNum = 5;
              } else {
                //查看设备状态
                this.syncDeviceTime();
              }
            }, 1000);
          } else {
            this.$message.error(data.msg);
            this.loading = false;
          }
        })
      },
      //获取同步数据
      syncDeviceTime() {
        let params = {
          deviceId: this.device.deviceId
        }
        syncDeviceData(params).then(({data}) => {
          if (data && data.code === 0) {
            //this.dataForm.productNo = data.product.productNo
            this.dataForm.picUrl = data.product.picUrl
            this.dataForm.l1 = data.product.l1
            this.dataForm.a1 = data.product.a1
            this.dataForm.b1 = data.product.b1
            this.dataForm.l2 = data.product.l2
            this.dataForm.a2 = data.product.a2
            this.dataForm.b2 = data.product.b2
            this.dataForm.l3 = data.product.l3
            this.dataForm.a3 = data.product.a3
            this.dataForm.b3 = data.product.b3
            this.dataForm.l = data.product.lAve
            this.dataForm.a = data.product.aAve
            this.dataForm.b = data.product.bAve
            this.timerNum = 0;
          } else {
            this.timerNum -= 1; // 定时器减1
          }
        })
      },
      clearTimer() {//清除定时器
        clearInterval(this.timer);
        this.timer = null;
      },

      //获取产品光泽选项
      getProductGlossList() {
        let params = {
          type: '3'
        }
        getDict(params).then(({data}) => {
          if (data && data.code === 0) {
            this.productGlossList = data.list
          } else {
            this.productGlossList = []
          }
        })
      },

//根据光泽测试值显示光泽范围名称
      getSelectGloss() {
        this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g,'') // 清除“数字”和“.”以外的字符
        this.dataForm.glossValue = this.dataForm.glossValue.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
        this.dataForm.glossValue = this.dataForm.glossValue.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        this.dataForm.glossValue = this.dataForm.glossValue.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3');//只能输入两个小数   
        if (this.dataForm.glossValue.indexOf(".") < 0 && this.dataForm.glossValue != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
            this.dataForm.glossValue = parseFloat(this.dataForm.glossValue);
        }
        let params = {
          glossValue: this.dataForm.glossValue ? parseInt(this.dataForm.glossValue) : ''
        }
        selectGloss(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm.gloss = data.DictList.dictId;
          } else {
            this.dataForm.gloss = ''
          }
        })
      },
      //上传产品图处理
      handleUploadSuccess(res, file, type) {
        this.dataForm.picUrl = res.data.fileUrl;
      },
      //上传前对图片大小及格式进行判断
      beforeUpload(file) {
        const isJPG = (file.type === "image/jpeg" || file.type === "image/bmp" || file.type === "image/png");
        const isLt2M = file.size / 1024 / 1024 < 8;

        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },

    }
  }
</script>
<style lang="scss">
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin-left: 20px;
    }
    .el-upload:hover {
      border-color: #409EFF;
    }
  }
</style>
<style lang="scss" scoped>
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 178px;
    text-align: center;
  }

  .ziti {
    font-size: 12px;
    margin: 0px;
    line-height: 25px;
  }

  .avatar {
    width: 178px;
    max-height: 178px;
    display: block;
  }

  .productLAB {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: -50px;
    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 10px;
      .title {
        display: inline-block;
        width: 100px;
        text-align: center;
        color: #606266;
        font-size: 700;
      }
    }

  }

  .w100 {
    width: 100px;
  }
  .w150 {
    width: 119px;
  }
  .mr10 {
    margin-right: 5px;
  }
  .collapse-title {
    flex: 1 0 90%;
    color: #4AB7BD;
    font-weight: bolder;
    text-align: center;
  }

  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 18px;
  }
</style>

<template>
  <div class="dashboard-editor-container">
    <template>
      <el-row style="background: #fff; margin-bottom: 15px">
        <el-card>
          <div slot="header" class="clearfix">
            <span>产品概览</span>
          </div>
          <el-row :gutter="15" v-if="nameAndRoleSysOrder !== '3'" >
            <el-col :xs="24" :sm="24" :lg="12">
              <div class="chart-wrapper">
                <overview-list title="产品合计" />
              </div>
              <div class="chart-wrapper">
                <overview-list title="产品新增" />
              </div>
              <div class="chart-wrapper">
                <overview-list title="产品搜索" />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :lg="12">
              <div class="chart-wrapper">
                <el-card style="height:495px;">
                  <div slot="header" class="clearfix">
                    <span>产品属性分布</span>
                  </div>
                  <el-select v-model="productAttr" placeholder="产品属性" @change="selectAttrChange">
                    <el-option
                        v-for="item in productAttrList"
                        :key="item.productAttrId"
                        :label="item.attrName"
                        :value="item.productAttrId"
                        size="small"
                    ></el-option>
                  </el-select>
                  <div class="no-more" id="no-more">
                    <img src="@/assets/no-more.png" />
                    <span>暂无数据</span>
                  </div>
                  <div id="pieChartDiv">
                    <pie-chart ref="pieChart" :chartIsLoad="true" @piechartemit="piechartemit"/>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
          <el-row v-else >
            <el-row :gutter="15">
              <el-col :xs="24" :sm="24" :lg="12">
                <div class="chart-wrapper">
                  <overview-list title="产品合计" />
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :lg="12">
                <div class="chart-wrapper">
                  <overview-list title="产品新增" />
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="chart-wrapper" style="position: relative;">
                  <el-card style="height:460px;">
                    <div slot="header" class="clearfix">
                      <span>产品属性分布</span>
                    </div>
                    <el-select style="position: absolute;top:45%;" v-model="productAttr" placeholder="产品属性" @change="selectAttrChange">
                      <el-option
                          v-for="item in productAttrList"
                          :key="item.productAttrId"
                          :label="item.attrName"
                          :value="item.productAttrId"
                          size="small"
                      ></el-option>
                    </el-select>
                    <div class="no-more" id="no-more">
                      <img src="@/assets/no-more.png" />
                      <span>暂无数据</span>
                    </div>
                    <div id="pieChartDiv" style="margin-left:20%;">
                      <pie-chart ref="pieChart" :chartIsLoad="true" @piechartemit="piechartemit"/>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </el-row>
        </el-card>
      </el-row>
      <el-row style="margin-bottom: 15px">
        <el-col :xs="24" :sm="24" :lg="24">
          <div class="chart-wrapper">
            <el-card>
              <div slot="header" class="clearfix">
                <span>产品新增趋势</span>
                <div style="float: right;"> 
                  <el-tabs v-model="lineActiveName" type="card" @tab-click="handleLineClick" style="margin: 0 0 -10px;">
                    <el-tab-pane label="近7天" name="first"></el-tab-pane>
                    <el-tab-pane label="近30天" name="second"></el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="no-more-line " id="no-more-line">
                <img src="@/assets/no-more.png" />
                <span>暂无数据</span>
              </div>
              <div id="lineChartDiv">
                <line-chart ref="lineChart" :chartIsLoad="true" @lineChartemit="lineChartemit" />
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </template>
    <Footer style="margin-top:-30px" />
  </div>
</template>

<script>
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import {selectBrandProductAttrList}from '@/api/modules/productattr'
import OverviewList from "./components/overviewList";
import { mapState } from "vuex";
export default {
  name: "productAdmin",
  components: {
    LineChart,
    PieChart,
    OverviewList,
    DatePicker: () => import('./components/DatePicker'),
    Footer: () => import('./components/Footer'),
  },
  data() {
    return {
      nameAndRoleSysOrder:'',
      searchCount: 0,
      searchAccuracyRate: 0,
      pData: [''],
      bData: [''],
      serNum: 0,
      serRercentage: '0%',
      bgColor: [
        "rgba(145, 238, 107, 1)",
        "rgba(145, 238, 107, 0.4)",
        "rgba(145, 238, 107, 0)",
      ],
      bgColor1: [
        "rgba(78,151,254,1)",
        "rgba(78,151,254,0.4)",
        "rgba(78,151,254,0)",
      ],
      
      customInfo: '',
      customInfoList: [],
      productAttr: '',
      productAttrList: [],
      dateValue: [],
      lineActiveName:'first',
      lineActiveType:'1',
    };
  },
  mounted() {
    this.selectProductAttrList();
    this.handleLineClick();
    document.getElementById("no-more-line").style.display="";
    document.getElementById("lineChartDiv").style.display="none";
    document.getElementById("no-more").style.display="";
    document.getElementById("pieChartDiv").style.display="none";
    this.nameAndRoleSysOrder = sessionStorage.getItem('nameAndRoleSysOrder')
  },
  beforeDestroy() {
  },
  methods: {
    selectProductAttrList(){
      this.productAttrList = []
      var params = {
        isUniversal:'0'
      }
      selectBrandProductAttrList(params).then(({data}) => {
        if (data && data.code === 0) {
          this.productAttrList = data.productAttrList;
          this.productAttr = data.productAttrList[0].productAttrId
          if (this.$refs.pieChart) {
            this.$refs.pieChart.getProductAttrValueDir(this.productAttr)
          }
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    searchcount(data) {
      this.searchCount = data
    },
    searchaccuracyrate(data) {
      this.searchAccuracyRate = data
    },
    piechartemit(data) {
      console.log("pData"+data)
      this.pData = data
      if(this.pData==null || this.pData.length==0){
        document.getElementById("no-more").style.display="";
        document.getElementById("pieChartDiv").style.display="none";
      } else {
        document.getElementById("no-more").style.display="none";
        document.getElementById("pieChartDiv").style.display="";
      }
    },
    lineChartemit(data) {
      console.log("bData"+data)
      this.bData = data
      if(this.bData==null || this.bData.length==0){
        document.getElementById("no-more-line").style.display="";
        document.getElementById("lineChartDiv").style.display="none";
      } else {
        document.getElementById("no-more-line").style.display="none";
        document.getElementById("lineChartDiv").style.display="";
      }
    },
    dateChange(value) {
      this.pData = [''];
      this.bData = [''];
      if (value === null) {
        value = ['', '']
      }
      this.dateValue = value;
      if (this.$refs.lineChart) {
        console.log(this.dateValue)
        this.$refs.lineChart.getLineStatistics(this.dateValue)
      }
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getProductAttrValueDir(this.productAttr)
      }
    },
    handleLineClick(){
      this.bData = [''];
      if(this.lineActiveName==="first"){
        this.lineActiveType="1"
      }else{
        this.lineActiveType="2"
      }
      if (this.$refs.lineChart) {
        console.log(this.lineActiveType)
        this.$refs.lineChart.getLineStatistics(this.lineActiveType)
      }
    },
    selectAttrChange(value) {
      this.pData = [''],
      console.log(value)
      
      this.productAttr = value;
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getProductAttrValueDir(this.productAttr)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.default-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 1000px;
  padding-top: 200px;
}
.dashboard-editor-container {
  padding: 15px 15px 10px 15px;
  background-color: #eaf1fc;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    // padding: 16px 16px 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.no-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 360px;
  span {
    font-size: 14px;
    color: #000;
    padding-top: 10px;
  }
}
.no-more-line {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //height: 360px;
  span {
    font-size: 14px;
    color: #000;
    padding-top: 10px;
  }
}
</style>

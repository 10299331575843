<template>
  <div class="app-container">
    <el-card class="box-pro">
      <div slot="header" class="clearfix">
        <span>产品筛选</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="ruleName">
        <el-input v-model="dataForm.ruleName" placeholder="规则名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="brandId">
        <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌">
          <el-option
            v-for="item in  brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('product:productnorule:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>
      </el-form-item>
    </el-form>
    </el-card>
    <el-card style="margin-top:30px">
    <div slot="header" class="clearfix">
      <span>规则列表</span>
      <div style="float: right; padding: 3px 0">
        <el-button v-if="isAuth('product:productnorule:save')" type="primary" @click="addOrUpdateHandle()" size="small">添加规则</el-button>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%;"
    >
      <el-table-column
        prop="ruleName"
        header-align="center"
        align="center"
        height="150"
        label="规则名称">
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        height="150"
        label="关联品牌">
      </el-table-column>
      <el-table-column
        prop="relationType"
        header-align="center"
        align="center"
        height="150"
        label="关联类型">
      </el-table-column>
      <el-table-column
        prop="attrName"
        header-align="center"
        align="center"
        height="150"
        label="关联值">
      </el-table-column>
      <el-table-column
        prop="matchStart"
        header-align="center"
        align="center"
        height="150"
        label="匹配开始位置">
      </el-table-column>
      <el-table-column
        prop="matchLength"
        header-align="center"
        align="center"
        height="150"
        label="关联位数">
      </el-table-column>
<!--        <el-table-column   height="150" prop="productAttrValueEntityList" header-align="center" align="center" label="关联规则"  width="200">-->
<!--          <template slot-scope="scope" >-->
<!--            <table  v-for="(col,index) in scope.row.productAttrValueEntityList">-->
<!--              <span >{{col.relationRole?col.relationRole +'='+col.attrName:col.attrName}}</span>-->
<!--            </table>-->
<!--          </template>-->
<!--        </el-table-column>-->
      <el-table-column
        prop="isEnable"
        header-align="center"
        align="center"
        height="150"
        label="状态">
      </el-table-column>

      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        height="150"
        label="创建时间">
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        height="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.productNoRuleId,scope.row.brandId)">编辑</el-button>
          <el-button type="text" size="small" v-if="scope.row.isEnable=='关闭'" @click="dataFormSubmit(scope.row.productNoRuleId,'1')">启用</el-button>
          <el-button type="text" size="small" v-if="scope.row.isEnable=='启用'" @click="dataFormSubmit(scope.row.productNoRuleId,'0')">关闭</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.productNoRuleId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-card>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import {list,del,update} from '@/api/modules/productnorule'
  import {getBrand} from '@/api/modules/product'
  import AddOrUpdate from './add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          ruleName: '',
          brandId: ''
        },
        brandList: [],
        dataList: [],
        productAttrValueEntityList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      this.getDataList()
    },
    mounted() {
      this.getDataList();
      this.getBrandList();
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
        this.getBrandList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      // 表单提交
      dataFormSubmit (id,isEnable) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'productAttrValueDtoList':'',
              'productNoRuleId': id || undefined,
              'ruleName': '',
              'brandId': '',
              'relationType': '',
              'relationId': '',
              'matchStart': '',
              'matchLength': '',
              'isEnable': isEnable,
              'currentSort': '',
              'isDelete': '',
              'createBy': '',
              'createTime': '',
              'updateBy': '',
              'updateTime': '',
              'version': ''
            }
            var tick = !id ? update(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                })
                this.getDataList();
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          ruleName: this.dataForm.ruleName,
          brandId: this.dataForm.brandId
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id,brandId) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id,brandId)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.productNoRuleId
        })
        this.$confirm(`是否删除产品编号规则`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="120px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="物料号" prop="materialNo">
        <el-input
          :disabled="dataForm.id"
          v-model="dataForm.materialNo"
          placeholder="物料号"
          size="small"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="materialName">
        <el-input
          v-model="dataForm.materialName"
          placeholder="名称"
          size="small"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="类别" prop="categoryId">
        <el-select v-model="dataForm.categoryId" placeholder="请选择物料类别">
          <el-option
            v-for="item in MaterialCategoryInfo"
            :key="item.categoryName"
            :label="item.categoryName"
            :value="item.categoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="计量单位" prop="units">
        <el-input
          v-model="dataForm.units"
          placeholder="单位"
          size="small"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <div style="margin: 5px 0"></div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="描述"
          v-model="dataForm.description"
          maxlength="150"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  info,
  add,
  update,
  MaterialCategoryInfo,
} from "@/api/modules/material";
export default {
  data() {
    return {
      MaterialCategoryInfo: [],
      visible: false,
      dataLoading: false,
      dataForm: {
        id: 0,
        materialNo: "",
        materialName: "",
        categoryId: "",
        units: "",
        description: "",
        brandId: "",
      },
      dataRule: {
        materialNo: [
          { required: true, message: "物料号不能为空", trigger: "blur" },
        ],
        materialName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        categoryId: [
          { required: true, message: "类别不能为空", trigger: "blur" },
        ],
        units: [{ required: true, message: "单位不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    //获取产品类型下拉
    this.getMaterialCategoryInfo();
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.materialNo = data.material.materialNo;
              this.dataForm.materialName = data.material.materialName;
              this.dataForm.categoryId = data.material.categoryId;
              this.dataForm.units = data.material.units;
              this.dataForm.description = data.material.description;
              this.dataForm.brandId = data.material.brandId;
            }
          });
        }
      });
    },
    //获取类别名称下拉
    getMaterialCategoryInfo() {
      MaterialCategoryInfo().then(({ data }) => {
        if (data && data.code === 0) {
          this.MaterialCategoryInfo = data.MaterialCategoryInfo;
          //  this.MaterialCategoryInfo = data.roleName;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            id: this.dataForm.id || undefined,
            materialNo: this.dataForm.materialNo,
            materialName: this.dataForm.materialName,
            categoryId: this.dataForm.categoryId,
            units: this.dataForm.units,
            description: this.dataForm.description,
            brandId: this.dataForm.brandId,
          };
          var tick = !this.dataForm.id ? add(params) : update(params);
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.dataLoading = false;
                  this.$emit("refreshDataList");
                },
              });
            } else {
              this.$message.error(data.msg);
              this.dataLoading = false;
            }
          });
        }
      });
    },
  },
};
</script>

<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="productName">
        <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="productNo">
        <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="recipeNo">
        <el-input v-model="dataForm.recipeNo" placeholder="配方编号" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="LAB值">
        <el-input v-model="dataForm.lAve" placeholder="L值" class="w100 mr10" size="small" clearable maxlength="30"></el-input>
        <el-input v-model="dataForm.aAve" placeholder="A值" class="w100 mr10" size="small" clearable maxlength="30"></el-input>
        <el-input v-model="dataForm.bAve" placeholder="B值" class="w100 mr10" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <!--<el-form-item prop="daterange">-->
        <!--<el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"-->
          <!--:editable="false" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>-->
      <!--</el-form-item>-->
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <el-button v-if="isAuth('recipe:recipe:save')" type="primary" @click="addOrUpdateHandle()" size="small">添加配方</el-button>
        <!--<el-button v-if="isAuth('recipe:recipe:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>-->
        <el-button v-if="isAuth('recipe:recipe:import')" type="primary" @click="exportExcelHandle()" size="small">下载配方EXCEL模板</el-button>
        <el-button v-if="isAuth('recipe:recipe:import')" type="success" @click="uploadHandle()" :disabled="dataList.length < 0">配方导入</el-button>
        <el-button v-if="isAuth('recipe:recipe:import')" type="primary" @click="exporMatExcelHandle()" size="small">下载配方材料EXCEL模板</el-button>
        <el-button v-if="isAuth('recipe:recipe:import')" type="success" @click="maUploadHandle()" :disabled="dataList.length < 0">配方材料导入</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="recipeNo"
        header-align="center"
        align="center"
        label="配方编号">
      </el-table-column>
      <el-table-column
        prop="productNo"
        header-align="center"
        align="center"
        label="产品编号">
      </el-table-column>
      <el-table-column
        prop="productName"
        header-align="center"
        align="center"
        label="产品名称">
      </el-table-column>
      <el-table-column
        prop="lab"
        header-align="center"
        align="center"
        label="45度平均LAB值">
        <template slot-scope="scope">
          <span v-if="scope.row.lab">L:{{scope.row.lab.lAve}}A:{{scope.row.lab.aAve}}B:{{scope.row.lab.bAve}}</span>
        </template>
      </el-table-column>
       <!--<el-table-column
        prop="recipeMaterial"
        header-align="center"
        align="center"
        label="配方材料">
         <template slot-scope="scope">
           <span  v-for="x in scope.row.recipeMaterial">{{x.materialName}}-&#45;&#45;{{x.value}}|{{x.units}}&#45;&#45;</span>
         </template>
      </el-table-column>-->
      <el-table-column
        prop="solidifyName"
        header-align="center"
        align="center"
        label="固化条件">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('recipe:recipe:info')" type="text" size="small" @click="addRecipeHandle(scope.row.productId)">查看</el-button>
          <el-button v-if="isAuth('recipe:recipe:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button v-if="isAuth('recipe:recipe:delete')" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
    <MaUpload v-if="MaUploadVisible" ref="MaUpload" @refreshDataList="getDataList"></MaUpload>
    <materialRecipe v-if="materialRecipeVisible" ref="materialRecipe" @refreshDataList="getDataList"></materialRecipe>
  </div>
</template>

<script>
  import {list,del,recipeImportExcel,reMaImportExcel} from '@/api/modules/recipe'
  import AddOrUpdate from './add-or-update'
  import {getToken} from "@/utils/auth";
  import Upload from "./recipe-upload";
  import MaUpload from "./reMa-upload";
  import MaterialRecipe from "./materialRecipe";
  export default {
    data () {
      return {
        dataForm: {
            productName: '',
            productNo:'',
            recipeNo:'',
            lAve:'',
            aAve:'',
            bAve:'',
          daterange:[],
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
          uploadVisible : false,
          MaUploadVisible:false,
        addOrUpdateVisible: false,
        materialRecipeVisible:false,
      }
    },
    components: {
      AddOrUpdate,
        Upload,
        MaUpload,
        MaterialRecipe
    },
    created () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
       	this.dataForm.bAve='';
          this.dataForm.lAve='';
          this.dataForm.aAve='';
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            productName: this.dataForm.productName,
            productNo: this.dataForm.productNo,
            recipeNo: this.dataForm.recipeNo,
            lAve: this.dataForm.lAve,
            aAve: this.dataForm.aAve,
            bAve: this.dataForm.bAve
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
              this.dataList = data.page.list;
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
        // 配方上传文件
        uploadHandle() {
            this.uploadVisible = true;
            this.$nextTick(() => {
                this.$refs.upload.init();
            });
        },
        
        //配方详情
        addRecipeHandle(id) {
          this.materialRecipeVisible = true;
          this.$nextTick(() => {
            this.$refs.materialRecipe.init(id);
          });
        },
        // 配方材料上传文件
        maUploadHandle() {
            this.MaUploadVisible = true;
            this.$nextTick(() => {
                this.$refs.MaUpload.init();
            });
        },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
        //导出配方EXCEL表格
        exportExcelHandle(){
          var params = {}
          var url = `${process.env.VUE_APP_BASE_API}/recipe/recipe/recipeExcle?`+`&token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
        //导出配方材料EXCEL表格
        exporMatExcelHandle(){
            var params = {}
            var url = `${process.env.VUE_APP_BASE_API}/recipe/recipe/reMaExcel?`+`&token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`是否确认删除配方?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .w100{
    width: 100px;
  }
  .mr10 {
    margin-right: 10px;
  }
</style>

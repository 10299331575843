<template>
    <div class="dashboard-container" style="margin: 15px;">
        <div>
            <P>基础概况</P>
            <!--订单信息-->
            <el-row :gutter="10">
                <el-col :span="11">
                    <el-card class="card">
                        <div style="display: flex">

                                <div style="line-height: 24px;font-size: 20px;">
                                    <span>待打样订单</span><br>
                                    <span>{{this.proofingOrder}}</span>
                                </div>

                                <div style="line-height: 24px;font-size: 20px;margin-left: 70px;">
                                    <span>打样中订单</span><br>
                                    <span>{{this.inProofingOrder}}</span>
                                </div>

                                <div style="line-height: 24px;font-size: 20px;margin-left: 70px;">
                                    <span>即将过期订单</span><br>
                                    <span>{{this.aboutOrder}}</span>
                                </div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="6">
                    <el-card class="card2" style="background-image: url('../../icons/svg/beiJing.png')">
                        <div style="line-height: 24px;font-size: 20px;color: white">
                            <img :src="require('@/icons/svg/chaoShi.png')"/><br>
                            <span >超期订单</span><br>
                            <span >{{this.pastOrder}}</span>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card>
                        <div style="line-height: 2em;font-size: 20px;color: white">
                            <router-link to="/productLogin">
                                <img :src="require('@/icons/svg/souSuo.png')"/><br>
                                <span>搜索</span>
                            </router-link>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="3">
                    <el-card>
                        <div style="line-height: 2em;font-size: 20px;color: white">
                            <router-link to="/sampleorder">
                                <img :src="require('@/icons/svg/lieBiao.png')"/><br>
                                <span>打样列表</span>
                            </router-link>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <!--打样单信息-->
            <el-row :gutter="10">
                <el-col :span="17">
                    <el-card class="card">
                        <div style="display: flex">

                                <div style="line-height: 24px;font-size: 20px;">
                                    <span>待自检</span><br>
                                    <span>{{this.inspectionOrder}}</span>
                                </div>

                                <div style="line-height: 24px;font-size: 20px;margin-left: 160px;">
                                    <span>待验证</span><br>
                                    <span>{{this.verificationOrder}}</span>
                                </div>

                                <div style="line-height: 24px;font-size: 20px;margin-left: 160px;">
                                    <span>待结单</span><br>
                                    <span>{{this.statementOrder}}</span>
                                </div>

                                <div style="line-height: 24px;font-size: 20px;margin-left: 160px;">
                                    <span>已完成</span><br>
                                    <span>{{this.completedOrder}}</span>
                                </div>

                        </div>
                    </el-card>
                </el-col>


                <el-col :span="7">
                    <el-card class="card2">
                        <div style="line-height: 24px;font-size: 20px;color: white">
                            <img :src="require('@/icons/svg/jianQu.png')"/><br>
                            <span>验证驳回</span><br>
                            <span>{{this.rejectOrder}}</span>
                        </div>
                    </el-card>
                </el-col>

            </el-row>
        </div>
    </div>
</template>


<script>

    import {queryProduct,querySearchStatistics,querySampleUserNum,queryMaterialNum,queryOrderSampleType,queryOrderTrendFactory,
        getCountSample,getSampleBrand,getSampleFactory,queryProductNum} from '@/api/modules/homePage'
    import {
        getCountPersonnel,
    } from '@/api/modules/dashboard'
    import {querySampleCount}from'@/api/modules/sampleorder'

    export default {

        data() {
            return {
                orderType:'first',
                orderStatu:'first',
                prooferType:'first',
                prooferChangeType:'1',
                activeNameDiscount:'first',
                activeNameDiscountType:'1',//产品新增数据状态（七日、三十日）
                product:{
                    allProduct:'',
                    produceProduct:'',
                    experimentProduct:'',
                    processProduct:'',
                },
                homePageSample:{
                    allOrder:'',
                    unOrder:'',
                    pastOrder:'',
                    overdueOrder:'',
                },
                productSearchStatistics:[],
                prooferDateList:[],
                materialDateList:[],
                proNumArry:[],//产品集合
                userNameArry:[],
                orderSampleTypeDateList:[],
                OrderTrendFactoryDateList:[],
                sampleBrandDateList:[],
                sampleFactoryDateList:[],
                dataList:[],
                proofingOrder:"",//待打样订单数
                inProofingOrder:"",//打样中订单
                aboutOrder:"",//即将过期订单
                pastOrder:"",//超期订单
                inspectionOrder:"",//已自检订单
                verificationOrder:"",//待验证订单
                rejectOrder:"",//验证驳回订单
                statementOrder:"",//待结单订单
                completedOrder:"",//已完成订单
                sampleCountNumber:"",//打样订单总数
                inProofingProduct:"",//打样中打样产品总数
                productSampleCount:"",//打样产品总数
            }
        },
        created() {
            /*this.queryProduct();
            this.querySample();*/
            this.getSampleCount();
        },
        mounted() {
            /*this.getDataList();
            this.productInput();
            this.querySearchStatistics();
            this.querySampleUserNum();
            this.queryMaterialNum();
            this.queryCountPersonnel();
            this.queryOrderSampleType();
            this.getSampleBrand();*/
            /*this.getSampleCount();*/
        },
        methods: {
            //获取订单统计数据
            getSampleCount(){
                var params = {};
                querySampleCount(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.proofingOrder=data.sampleCount.proofingOrder;
                        this.inProofingOrder=data.sampleCount.inProofingOrder;
                        this.aboutOrder=data.sampleCount.aboutOrder;
                        this.pastOrder=data.sampleCount.pastOrder;
                        this.inspectionOrder=data.sampleCount.inspectionOrder;
                        this.verificationOrder=data.sampleCount.verificationOrder;
                        this.rejectOrder=data.sampleCount.rejectOrder;
                        this.statementOrder=data.sampleCount.statementOrder;
                        this.completedOrder=data.sampleCount.completedOrder;
                    } else {

                    }
                })
            },



            handleClick(tab, event) {
                if(this.activeNameDiscount ==="first"){
                    this.activeNameDiscountType="1"
                }else if(this.activeNameDiscount ==="second"){
                    this.activeNameDiscountType="2"
                }
                this.getDataList();

            },
            handleClickOrderType(tab, event) {
                if(this.orderType ==="first"){
                    this.queryOrderSampleType();

                }else if(this.orderType ==="second"){
                    this.queryOrderTrendFactory();
                }
            },
            handleClickOrderStatu(tab, event){
                if(this.orderStatu ==="first"){
                    this.getSampleBrand();

                }else if(this.orderStatu ==="second"){
                    this.getSampleFactory();
                }
            },
            handleClickProofer(tab, event){
                if(this.prooferType ==="first"){
                    this.prooferChangeType="1"
                }else if(this.prooferType ==="second"){
                    this.prooferChangeType="2"
                }else if(this.prooferType ==="third"){
                    this.prooferChangeType="3"
                }
                this.querySampleUserNum();
            },

            // 获取数据列表
            getDataList() {
                var params = {
                    type: this.activeNameDiscountType
                }
                queryProductNum(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList=data.ProductNum;
                        this.initActiveDiscount();
                    } else {
                    }

                })
            },
            queryOrderTrendFactory(){
                var params = {};
                queryOrderTrendFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.OrderTrendFactoryDateList=data.list;
                        this.orderSampleTypeFactory();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            getSampleBrand(){ //品牌打样订单统计
                var params = {};
                getSampleBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.sampleBrandDateList=data.sampleList;
                        this.sampleBrand();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            getSampleFactory(){//工厂打样订单统计
                var params = {};
                getSampleFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.sampleFactoryDateList=data.sampleList;
                        this.sampleFactory();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            queryOrderSampleType(){
                var params = {};
                queryOrderSampleType(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.orderSampleTypeDateList=data.list;
                        this.orderSampleType();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },


            initActiveDiscount(){
                var activeDiscount = this.$echarts.init(document.getElementById('activeDiscount'));
                var searchDate = new Array();
                var searchCount = new Array();
                /*var searchAccuracy = new Array();
                var searchNonOperation = new Array();*/
                for(var i=0;i<this.dataList.length;i++){
                    searchDate[i]=this.dataList[i].searchDate;
                    searchCount[i]=this.dataList[i].searchCount;
                    /*searchAccuracy[i]=this.dataList[i].searchAccuracy;
                    searchNonOperation[i]=this.dataList[i].searchNonOperation;*/
                }
                let option = null;
                option = {
                    title: {
                        left: "center",
                        text: (this.activeNameDiscount=="first"?'7日新增产品':'30日新增产品')
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['新增产品数'],
                        y:'bottom',     //可设定图例在上、下、居中
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: searchDate
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '新增产品数',
                            type: 'line',

                            data: searchCount
                        },
                        /*{
                            name: '搜索匹配数',
                            type: 'line',

                            data: searchAccuracy
                        },
                        {
                            name: '搜索不匹配数',
                            type: 'line',

                            data: searchNonOperation
                        }*/
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },


            orderSampleTypeFactory(){
                var activeDiscount = this.$echarts.init(document.getElementById('orderType'));
                var orderDate = new Array();
                var sampleCount = new Array();
                var proofCount = new Array();
                var allCount = new Array();
                for(var i=0;i<this.OrderTrendFactoryDateList.length;i++){
                    orderDate[i]=this.OrderTrendFactoryDateList[i].factoryName;
                    sampleCount[i]=this.OrderTrendFactoryDateList[i].sampleCount;
                    proofCount[i]=this.OrderTrendFactoryDateList[i].proofCount;
                    allCount[i]=this.OrderTrendFactoryDateList[i].allCount;
                }
                let option = null;
                option = {
                    title: {
                        left: "center",
                        text: "实验室订单走势"
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        data: ['全部', '样板样粉订单', '打样订单'],
                        y:'bottom',     //可设定图例在上、下、居中
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',

                        data: orderDate
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '全部',
                            type: 'bar',
                            data: allCount
                        },
                        {
                            name: '样板样粉订单',
                            type: 'bar',
                            stack:'order',
                            data: proofCount
                        },
                        {
                            name: '打样订单',
                            type: 'bar',
                            stack:'order',
                            data: sampleCount
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },

            orderSampleType(){
                var activeDiscount = this.$echarts.init(document.getElementById('orderType'));
                var orderDate = new Array();
                var sampleCount = new Array();
                var proofCount = new Array();
                var allCount = new Array();
                for(var i=0;i<this.orderSampleTypeDateList.length;i++){
                    orderDate[i]=this.orderSampleTypeDateList[i].orderDate;
                    sampleCount[i]=this.orderSampleTypeDateList[i].sampleCount;
                    proofCount[i]=this.orderSampleTypeDateList[i].proofCount;
                    allCount[i]=this.orderSampleTypeDateList[i].allCount;
                }
                let option = null;
                option = {
                    title: {
                        left: "center",
                        text: "实验室订单走势"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['全部', '样板样粉订单', '打样订单'],
                        y:'bottom',     //可设定图例在上、下、居中
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: orderDate
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '全部',
                            type: 'line',

                            data: allCount
                        },
                        {
                            name: '样板样粉订单',
                            type: 'line',

                            data: proofCount
                        },
                        {
                            name: '打样订单',
                            type: 'line',

                            data: sampleCount
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },
            //工厂打样订单
            sampleFactory(){
                var activeDiscount = this.$echarts.init(document.getElementById('orderStatu'));
                var orderDate = new Array();
                var sampleCount = new Array();
                var proofCount = new Array();
                var allCount = new Array();
                for(var i=0;i<this.sampleFactoryDateList.length;i++){
                    orderDate[i]=this.sampleFactoryDateList[i].factoryName;
                    sampleCount[i]=this.sampleFactoryDateList[i].sampleCount;
                    proofCount[i]=this.sampleFactoryDateList[i].proofCount;
                    allCount[i]=this.sampleFactoryDateList[i].allCount;
                }
                let option = null;
                option = {
                    title: {
                        left: "center",
                        text: "实验室打样走势"
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        data: ['全部', '打样完成订单', '打样中订单'],
                        y:'bottom',     //可设定图例在上、下、居中
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',

                        data: orderDate
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '全部',
                            type: 'bar',
                            data: allCount
                        },
                        {
                            name: '打样完成订单',
                            type: 'bar',
                            stack:'order',
                            data: proofCount
                        },
                        {
                            name: '打样中订单',
                            type: 'bar',
                            stack:'order',
                            data: sampleCount
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },
            //品牌打样订单
            sampleBrand(){
                var activeDiscount = this.$echarts.init(document.getElementById('orderStatu'));
                var orderDate = new Array();
                var sampleCount = new Array();
                var proofCount = new Array();
                var allCount = new Array();
                for(var i=0;i<this.sampleBrandDateList.length;i++){
                    orderDate[i]=this.sampleBrandDateList[i].orderDate;
                    sampleCount[i]=this.sampleBrandDateList[i].sampleCount;
                    proofCount[i]=this.sampleBrandDateList[i].proofCount;
                    allCount[i]=this.sampleBrandDateList[i].allCount;
                }
                let option = null;
                option = {
                    title: {
                        left: "center",
                        text: "实验室打样走势"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['全部', '打样完成订单', '打样中订单'],
                        y:'bottom',     //可设定图例在上、下、居中
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: orderDate
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '全部',
                            type: 'line',

                            data: allCount
                        },
                        {
                            name: '打样完成订单',
                            type: 'line',

                            data: proofCount
                        },
                        {
                            name: '打样中订单',
                            type: 'line',

                            data: sampleCount
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                if (option && typeof option === "object") {
                    activeDiscount.setOption(option, true);
                }
            },

            queryMaterialNum(){
                var params = {};
                queryMaterialNum(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.materialDateList=data.list;
                        this.materialUseNum();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            materialUseNum(){
                var materialUseNum = this.$echarts.init(document.getElementById('material'));
                var productNoList= new Array();
                var count= new Array();
                for(var i=0;i<this.materialDateList.length;i++){
                    productNoList[i]=this.materialDateList[i].materialNo;
                    count[i]=this.materialDateList[i].materialCount;
                }
                let option = null;
                option = {
                    title: {
                        text: '材料使用排行',
                        left: 'center'

                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '5%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: productNoList
                    },
                    series: [
                        {
                            name: '材料使用次数 ',
                            type: 'bar',
                            data: count,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: 'black',
                                            fontSize: 12
                                        }
                                    }
                                }
                            },

                        }
                    ]
                };
                if (option && typeof option === "object") {
                    materialUseNum.setOption(option, true);
                }

            },

            querySampleUserNum(){
                var params = {
                    type:this.prooferChangeType,
                };
                querySampleUserNum(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.prooferDateList=data.list;
                        this.prooferStatistics();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },

            //打样员统计
            prooferStatistics(){
                var proofer = this.$echarts.init(document.getElementById('proofer'));
                var proofNum= new Array();
                var sampleNum= new Array();
                var userName= new Array();
                for(var i=0;i<this.prooferDateList.length;i++){
                    userName[i]=this.prooferDateList[i].userName;
                    sampleNum[i]=this.prooferDateList[i].sampleNum;
                    proofNum[i]=this.prooferDateList[i].proofNum;
                }
                let option = null;
                option = {
                    title: {
                        text: '实验室打样员排行',
                        left: 'center'

                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '5%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: userName
                    },
                    series: [
                        {
                            name: '打样订单',
                            type: 'bar',
                            data: sampleNum,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: 'black',
                                            fontSize: 12
                                        }
                                    }
                                }
                            },

                        },
                        {
                            name: '样板样粉',
                            type: 'bar',
                            data: proofNum,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: 'black',
                                            fontSize: 12
                                        }
                                    }
                                }
                            },

                        }
                    ]
                };
                if (option && typeof option === "object") {
                    proofer.setOption(option, true);
                }

            },
            querySample(){
                var params = {};
                getCountSample(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.homePageSample.allOrder=data.homePageSample.allOrder;
                        this.homePageSample.unOrder=data.homePageSample.unOrder;
                        this.homePageSample.pastOrder=data.homePageSample.pastOrder;
                        this.homePageSample.overdueOrder=data.homePageSample.overdueOrder;
                    } else {
                        this.$message.error(data.msg);
                    }

                })
            },
            queryProduct(){
                var params = {};
                queryProduct(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.product.allProduct=data.product.allProduct;
                        this.product.produceProduct=data.product.produceProduct;
                        this.product.experimentProduct=data.product.experimentProduct;
                        this.product.processProduct=data.product.processProduct;
                    } else {
                        this.$message.error(data.msg);
                    }

                })
            },
            querySearchStatistics(){
                var params = {};
                querySearchStatistics(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSearchStatistics=data.list;
                        this.productSearch();
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            //搜索产品统计
            productSearch(){
                var productSearch = this.$echarts.init(document.getElementById('productSearch'));
                var productNoList= new Array();
                var count= new Array();
                for(var i=0;i<this.productSearchStatistics.length;i++){
                    productNoList[i]=this.productSearchStatistics[i].productNo;
                    count[i]=this.productSearchStatistics[i].count;
                }
                let option = null;
                option = {
                    title: {
                        text: '产品搜索排行',
                        left: 'center'

                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '5%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: productNoList
                    },
                    series: [
                        {
                            name: '产品搜索次数',
                            type: 'bar',
                            data: count,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: 'black',
                                            fontSize: 12
                                        }
                                    }
                                }
                            },

                        }
                    ]
                };
                if (option && typeof option === "object") {
                    productSearch.setOption(option, true);
                }

            },

            //人员统计数据
            queryCountPersonnel(){
                getCountPersonnel().then(({ data })=>{
                    if(data && data.code === 0){
                        data.countPersonnel.forEach(item=>{
                            this.userNameArry.push(item.userName);
                            this.proNumArry.push(item.proNum);
                        })
                    }
                    this.productInput()
                })
            },
            productInput(){
                var productInput = this.$echarts.init(document.getElementById('productInput'));
                let option = null;
                option = {
                    title: {
                        text: '产品录入排行',
                        left: 'center'

                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '5%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data:this.userNameArry
                    },
                    series: [
                        {
                            name: '产品录入数量',
                            type: 'bar',
                            data: this.proNumArry,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: 'black',
                                            fontSize: 12
                                        }
                                    }
                                }
                            },

                        }
                    ]
                };
                if (option && typeof option === "object") {
                    productInput.setOption(option, true);
                }
            }
        }


    }


</script>
<style>
    .text {
        font-size: 14px;
    }

    .item {
        padding: 18px 0;
    }

    .box-card {
        height: 150px;
        background-color: #6EB1FC;

    }
    .card{
        height: 150px;
        background-color: white;
    }
    .card2{

        height: 150px;
        background-color: red;
    }
    .el-tabs__nav.is-top {
        float: right;
    }

    .el-tabs__header.is-top {
        margin: 0;
    }
</style>

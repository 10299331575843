<template>
  <div class="app-container">
    <el-dialog
            :title="!dataForm.id ? '查看配方' : '查看配方'"
            :close-on-click-modal="false"
            :visible.sync="recipeListVisible"
            append-to-body>
      <div>
        <!--<el-tag v-for="(item,index) in matetail" :key="index" @click="init(item.componentId,item.productId)">{{item.materialName}}</el-tag>-->
        <!--<p v-for="(item,index) in matetail" :key="index">{{item}}</p>-->
        <el-table :data="dataList"
                  border
                  v-loading="dataListLoading"
                  style="width: 100%;">
          <el-table-column
                  prop="materialNo"
                  header-align="center"
                  align="center"
                  label="材料编号">
            <template slot-scope="scope">
              <el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="init(scope.row.componentId,scope.row.productId,scope.row.materialName)">{{scope.row.materialNo}}</el-link>
              <label v-if="scope.row.componentId==null&&scope.row.productId==null">{{scope.row.materialNo}}</label>
            </template>
          </el-table-column>
          <el-table-column
                  prop="materialName"
                  header-align="center"
                  align="center"
                  label="原料名称">
            <!--<template slot-scope="scope">-->
              <!--<el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="init(scope.row.componentId,scope.row.productId,scope.row.materialName)">{{scope.row.materialName}}</el-link>-->
              <!--<label v-if="scope.row.componentId==null&&scope.row.productId==null">{{scope.row.materialName}}</label>-->
            <!--</template>-->
          </el-table-column>

          <el-table-column
                  prop="value"
                  header-align="center"
                  align="center"
                  label="计量值">
            <template slot-scope="scope">
              <span>{{scope.row.value|numFilter}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="units"
                  header-align="center"
                  align="center"
                  label="单位">
          </el-table-column>
          <el-table-column
                  prop="rate"
                  header-align="center"
                  align="center"
                  label="计量百分比">
          </el-table-column>
        </el-table>
      </div>

      <div v-if="dataCipeList != null && dataCipeList.length > 0">
        <p>邦定工艺</p>
        <el-table :data="dataCipeList"
                  border
                  style="width: 100%;">
          <el-table-column
                  prop="addStart"
                  header-align="center"
                  align="center"
                  label="add start开始">
          </el-table-column>
          <el-table-column
                  prop="addBonding"
                  header-align="center"
                  align="center"
                  label="add bonding过程">
          </el-table-column>
          <el-table-column
                  prop="rpm"
                  header-align="center"
                  align="center"
                  label="Rpm 速度">
          </el-table-column>
          <el-table-column
                  prop="temp"
                  header-align="center"
                  align="center"
                  label="Temp 温度">
          </el-table-column>
          <el-table-column
                  prop="time"
                  header-align="center"
                  align="center"
                  label="Time 时间">
          </el-table-column>
          <el-table-column
                  prop="cocktail"
                  header-align="center"
                  align="center"
                  label="cocktail 干混">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {list,listCipe,listAll,listAllCipe,productMateril} from '@/api/modules/samplerecipe'
  export default {
    data () {
      return {
        dataForm: {
          recipeOrderId: '',
          daterange:[],
          category:'',
          componentId:'',//组别id
          productId:''//产品id
        },
        dataList: [],
        dataCipeList:[],
        matetail:[],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataCipeLoading: false,
        dataListSelections: [],
        recipeListVisible: false
      }
    },
    components: {
    },
    //写个过滤器，保留小数点后两位
    filters: {
      numFilter (value) {
        let realVal = ''
        if (!isNaN(value) && value!== '') {
          // 截取当前数据到小数点后两位
          realVal = parseFloat(value).toFixed(2)
        } else {
          realVal = '--'
        }
        return realVal
      }
    },
    methods: {

        //配方材料数据接口
      init(componentId,productId,materialName){
          this.recipeListVisible= true
         /* if (this.m) */
          console.log(materialName)
          let ma=this.matetail.find(v => {
              return v.componentId == componentId
          })
          if (!ma) {
              this.matetail.push({
                  componentId,productId,materialName
              })
          }

       // console.log(componentId)
        //  console.log(productId)
        this.dataForm.componentId = componentId;
        this.dataForm.productId=productId;
          var params = {
              sampleProductId: this.dataForm.componentId,
              productId: this.dataForm.productId
          }
        //判断参数是否为空，走对应的接口
        if (componentId==null){
            productMateril(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.dataList = data.listVos
                } else {
                    this.dataList = []
                    this.$message.error(data.msg);
                }
                this.dataListLoading = false
            })
        }
          if (productId==null){
              listAll(params).then(({data}) => {
                  if (data && data.code === 0) {
                      this.dataList = data.page
                      this.dataCipeList = data.sampleSpecialrecipe
                  } else {
                      this.dataList = []
                      this.dataCipeList = []
                      this.$message.error(data.msg);
                  }
                  this.dataListLoading = false
                  this.dataCipeLoading = false
              })
          }
      },
    }
  }
</script>

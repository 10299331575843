<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="备份方式" prop="type">
        <el-input v-model="dataForm.type" placeholder="备份方式"  size="small" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="备份名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="备份名称"  size="small" maxlength="30"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">返回</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
            type: '',
          name: '',
       //   description: ''
        },
        dataRule: {
            type: [
            { required: true, message: '备份方式', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '备份名称', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          // if (this.dataForm.id) {
          //   info({id:this.dataForm.id}).then(({data}) => {
          //     if (data && data.code === 0) {
          //       this.dataForm.no = data.materialCategory.no
          //       this.dataForm.name = data.materialCategory.name
          //       this.dataForm.description = data.materialCategory.description
          //     }
          //   })
          // }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
            //  'no': this.dataForm.no,
              'name': this.dataForm.name,
            //  'description': this.dataForm.description
            }
            // var tick = !this.dataForm.id ? add(params) : update(params)
            // tick.then(({data}) => {
            //   if (data && data.code === 0) {
            //     this.$message({
            //       message: '操作成功',
            //       type: 'success',
            //       duration: 1500,
            //       onClose: () => {
            //         this.visible = false;
            //       	this.dataLoading = false;
            //         this.$emit('refreshDataList')
            //       }
            //     })
            //   } else {
            //     this.$message.error(data.msg)
            //     this.dataLoading = false;
            //   }
            // })
          }
        })
      }
    }
  }
</script>

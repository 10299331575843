import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/tenantInfo/list',
    method: 'get',
    params: params
  })
}

//详情
export function info (params) {
  return request({
    url: '/tenantInfo/info',
    method: 'get',
    params: params
  })
}

//新增
export function save (params) {
  return request({
    url: '/tenantInfo/save',
    method: 'post',
    data: params
  })
}
//修改
export function update (params) {
  return request({
    url: '/tenantInfo/update',
    method: 'post',
    data: params
  })
}

//删除
export function del (params) {
  return request({
    url: '/tenantInfo/delete',
    method: 'post',
    data: params
  })
}
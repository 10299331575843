<template>
  <div class="dashboard-editor-container">
    <template>
      <el-row style="background: #fff; margin-bottom: 15px">
        <el-card>
          <div slot="header" class="clearfix">
            <span>产品概览</span>
            <div style="float: right; padding: 3px 0">
              <date-picker @dateChange="dateChange" />
            </div>
          </div>
          <div class="no-more" v-if="bData.length===0">
            <img src="@/assets/no-more.png" />
            <span>暂无数据</span>
          </div>
          <el-row :gutter="15">
            <el-col :xs="24" :sm="24" :lg="16">
              <line-chart ref="lineChart" @barchartemit="lineChartemit" />
            </el-col>
            <el-col :xs="24" :sm="24" :lg="8">
              <rank-info ref="rankInfo" />
            </el-col>
          </el-row>
        </el-card>
      </el-row>
      <el-row :gutter="15">
        <el-col :xs="24" :sm="24" :lg="12">
          <div class="chart-wrapper">
            <el-card>
              <div slot="header" class="clearfix">
                <span>产品属性分布</span>
              </div>
              <div class="no-more" v-if="pData.length===0">
                <img src="@/assets/no-more.png" />
                <span>暂无数据</span>
              </div>
              <pie-chart :chartIsLoad="true" @piechartemit="piechartemit"/>
            </el-card>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :lg="12">
          <div class="chart-wrapper">
            <el-card>
              <div slot="header" class="clearfix">
                <span>产品客户分布</span>
              </div>
              <div class="no-more" v-if="pData.length===0">
                <img src="@/assets/no-more.png" />
                <span>暂无数据</span>
              </div>
              <pie-chart :chartIsLoad="true" @piechartemit="piechartemit"/>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </template>
    <Footer style="margin-top:-30px" />
  </div>
</template>

<script>
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import {selectProductAttrList}from '@/api/modules/productattr'
import {selectCustomInfoList}from '@/api/modules/record'
const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};

export default {
  name: "productAdmin",
  components: {
    LineChart,
    PieChart,
    BarChart,
    RankInfo: () => import('./components/RankInfo'),
    DatePicker: () => import('./components/DatePicker'),
    Footer: () => import('./components/Footer'),
  },
  data() {
    return {
      searchCount: 0,
      searchAccuracyRate: 0,
      pData: [''],
      bData: [''],
      serNum: 0,
      serRercentage: '0%',
      bgColor: [
        "rgba(145, 238, 107, 1)",
        "rgba(145, 238, 107, 0.4)",
        "rgba(145, 238, 107, 0)",
      ],
      bgColor1: [
        "rgba(78,151,254,1)",
        "rgba(78,151,254,0.4)",
        "rgba(78,151,254,0)",
      ],
      lineChartData: lineChartData.newVisitis,
      customInfo: '',
      customInfoList: [],
      productAttr: '',
      productAttrList: [],
      dateValue: [],
    };
  },
  mounted() {
    this.selectProductAttrList();
  },
  beforeDestroy() {
  },
  methods: {
    selectProductAttrList(){
      this.productAttrList = []
      var params = {
        isUniversal:'0'
      }
      selectProductAttrList(params).then(({data}) => {
        if (data && data.code === 0) {
          this.productAttrList = data.productAttrList;
          this.productAttr = data.productAttrList[0].productAttrId
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    searchcount(data) {
      this.searchCount = data
    },
    searchaccuracyrate(data) {
      this.searchAccuracyRate = data
    },
    piechartemit(data) {
      this.pData = data
    },
    lineChartemit(data) {
      console.log(data)
      this.bData = data
    },
    dateChange(value) {
      this.pData = [''],
      this.bData = [''],
      console.log(value)
      if (value === null) {
        value = ['', '']
      }
      this.dateValue = value;
      if (this.$refs.lineChart) {
        this.$refs.lineChart.findBarValueDir(value)
      }
      if (this.$refs.rankInfo) {
        this.$refs.rankInfo.findBarValueDir(value)
      }
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(value, this.customInfo, this.productAttr)
      }
    },
    selectAttrChange(value) {
      this.pData = [''],
      console.log(value)
      
      this.productAttr = value;
      if (this.$refs.pieChart) {
        this.$refs.pieChart.getCustomInfoStatistics(this.dateValue, this.customInfo, this.productAttr)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.default-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 1000px;
  padding-top: 200px;
}
.dashboard-editor-container {
  padding: 15px 15px 10px 15px;
  background-color: #eaf1fc;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    // padding: 16px 16px 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.no-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 411px;
  span {
    font-size: 14px;
    color: #000;
    padding-top: 10px;
  }
}
</style>

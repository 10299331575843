import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/sample/samplerecipe/list',
    method: 'get',
    params: params
  })
}

// 获取列表
export function listAll (params) {
    return request({
        url: '/sample/samplerecipe/listAll',
        method: 'get',
        params: params
    })
}

// 获取信息
export function info (params) {
  return request({
    url: '/sample/samplerecipe/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/sample/samplerecipe/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/sample/samplerecipe/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/sample/samplerecipe/delete',
    method: 'post',
    data: params
  })
}

export function listCipe (params) {
  return request({
    url: '/sample/samplespecialrecipe/listCipe',
    method: 'get',
    params: params
  })
}

export function listAllCipe (params) {
    return request({
        url: '/sample/samplespecialrecipe/listAllCipe',
        method: 'get',
        params: params
    })
}

export function listAllSCipe (params) {
  return request({
      url: '/sample/samplespecialrecipe/listAllSCipe',
      method: 'get',
      params: params
  })
}


// 根据产品id获取列表
export function productMateril (params) {
    return request({
        url: '/sample/samplerecipe/productMateril',
        method: 'get',
        params: params
    })
}

import request from '@/utils/request'

//产品数据一致性
export function selectProductInfoSurvey () {
    return request({
        url: '/product/productData/selectProductInfoSurvey',
        method: 'get'
    })
}

//产品属性数据完整性
export function selectProductAttrSurvey () {
    return request({
        url: '/product/productData/selectProductAttrSurvey',
        method: 'get'
    })
}

//产品概况
export function selectProductSurvey () {
    return request({
        url: '/product/productData/selectProductSurvey',
        method: 'get'
    })
}
<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="授权用户id" prop="userId">
        <el-input v-model="dataForm.userId" placeholder="授权用户id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="设备id" prop="deviceId">
        <el-input v-model="dataForm.deviceId" placeholder="设备id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="开始时间" prop="startDate">
        <el-input v-model="dataForm.startDate" placeholder="开始时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="结束时间" prop="endDate">
        <el-input v-model="dataForm.endDate" placeholder="结束时间"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态（0.已授权，1.已停用，2.已过期）" prop="state">
        <el-input v-model="dataForm.state" placeholder="状态（0.已授权，1.已停用，2.已过期）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否删除（0.未删除，1.删除）" prop="delFlag">
        <el-input v-model="dataForm.delFlag" placeholder="是否删除（0.未删除，1.删除）"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createBy">
        <el-input v-model="dataForm.createBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="createDate">
        <el-input v-model="dataForm.createDate" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateBy">
        <el-input v-model="dataForm.updateBy" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateDate">
        <el-input v-model="dataForm.updateDate" placeholder=""  size="small"></el-input>
      </el-form-item>
      <el-form-item label="" prop="remarks">
        <el-input v-model="dataForm.remarks" placeholder=""  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/deviceuser'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          userId: '',
          deviceId: '',
          startDate: '',
          endDate: '',
          state: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: ''
        },
        dataRule: {
          userId: [
            { required: true, message: '授权用户id不能为空', trigger: 'blur' }
          ],
          deviceId: [
            { required: true, message: '设备id不能为空', trigger: 'blur' }
          ],
          startDate: [
            { required: true, message: '开始时间不能为空', trigger: 'blur' }
          ],
          endDate: [
            { required: true, message: '结束时间不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '状态（0.已授权，1.已停用，2.已过期）不能为空', trigger: 'blur' }
          ],
          delFlag: [
            { required: true, message: '是否删除（0.未删除，1.删除）不能为空', trigger: 'blur' }
          ],
          createBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          createDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateBy: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          updateDate: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          remarks: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.userId = data.deviceuser.userId
                this.dataForm.deviceId = data.deviceuser.deviceId
                this.dataForm.startDate = data.deviceuser.startDate
                this.dataForm.endDate = data.deviceuser.endDate
                this.dataForm.state = data.deviceuser.state
                this.dataForm.delFlag = data.deviceuser.delFlag
                this.dataForm.createBy = data.deviceuser.createBy
                this.dataForm.createDate = data.deviceuser.createDate
                this.dataForm.updateBy = data.deviceuser.updateBy
                this.dataForm.updateDate = data.deviceuser.updateDate
                this.dataForm.remarks = data.deviceuser.remarks
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'userId': this.dataForm.userId,
              'deviceId': this.dataForm.deviceId,
              'startDate': this.dataForm.startDate,
              'endDate': this.dataForm.endDate,
              'state': this.dataForm.state,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <el-dialog
    class="synchronization"
    title="数据同步检查"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px">
    <el-table
        :data="tableData_"
        style="width: 100%"
        :header-cell-style="{background:'#e9e9e9'}"
        :row-class-name="tableRowClassName">
        <el-table-column
        prop="name"
        label="品牌"
        align="center">
        </el-table-column>
        <el-table-column
        prop="state"
        label="状态"
        align="center">
        </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
     :page-size="pageSize"
      layout="total, prev, pager, next"
     :total="totalPage">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="checkData()" size="small"  :disabled="dataLoading">检查</el-button>
      <el-button @click="visible = false" size="small">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {list} from '@/api/modules/source'
import {selectSynProductAttrState} from '@/api/modules/productattr'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        tableData:[],
        tableData_:[]
      }
    },
    methods: {
      init () {
        this.visible = true;
        this.tableData = []
        this.tableData_ = []
        this.pageIndex = 1
        this.dataFormSubmit()
      },
      tableRowClassName({row, rowIndex}) {
        // console.log(rowIndex);
        if (rowIndex % 2 == 1) {
          return 'grey-row';
        }
        // if ((this.pageIndex-1)*this.pageSize  > rowIndex || this.pageIndex*this.pageSize  <= rowIndex){
        //   console.log(rowIndex);
        //   return 'hidden';
        // }
      },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.dataFormSubmit()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.dataFormSubmit()
        },
      dataFormSubmit(){
        // console.log(this.pageIndex);
        this.dataLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
        }
        list(params).then(({data}) => {
          if (data && data.code == '10200') {
            if (this.tableData.length == 0 || this.tableData.length <= (this.pageIndex - 1)*2) {
              data.data.records.map((item) => {
                let obj = {
                  id: item.tenantId,
                  name: item.tenantName,
                  state: '未检查'
                }
                this.tableData.push(obj)
              })
            }
            // var tableData = data.data.records.map((item,index) => {
            //   if (this.tableData.length > 0 && this.tableData[index].state) {
            //     return {
            //       id: item.tenantId,
            //       name: item.tenantName,
            //       state: this.tableData[index].state
            //     }
            //   }else{
            //     return {
            //       id: item.tenantId,
            //       name: item.tenantName,
            //       state: '未检查'
            //     }
            //   }
            // })
            // console.log(tableData);
            // this.tableData = tableData
            console.log(this.pageSize,this.pageIndex);
            console.log(this.tableData,(this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize-1);
            this.totalPage = data.data.total
            this.tableData_ = this.tableData.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize)
          } else {
            this.tableData = []
            this.totalPage = 0
          }
          this.dataLoading = false
          // console.log(this.tableData);
        })
      },
      checkData(){
        this.dataLoading = true
        selectSynProductAttrState().then(({data}) => {
          if (data && data.code == '0') {
            data.data.map(item =>{
              this.tableData.map((ite,inde) =>{
                if (item.brandId == ite.id) {
                  console.log(this.tableData[inde].state,item.synState);
                  this.tableData[inde].state = item.synState
                }
              })
            })
            console.log(this.tableData);
          }else{
            this.$message.error(data.msg)
          }
        })
        this.dataLoading = false
      }
    }
  }
</script>
<style lang="scss">
.synchronization{
    .dialog-footer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .grey-row{
        background: #e9e9e9;
    }
    .hidden{
        display:none;
    }
}
</style>

<template>
  <div class="app-container">
    <el-card class="">
      <div slot="header" class="clearfix">
        <span>规则筛选</span>
      </div>
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="relationName" label="规则名称">
        <el-input
          v-model="dataForm.relationName"
          placeholder="规则名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="brandId" label="适用品牌">
        <el-select
          v-model="dataForm.brandId"
          size="small"
          @change="getAttrList()"
          placeholder="请选择品牌"
        >
          <el-option label="全部" value="全部"></el-option>
          <el-option label="通用" value=""></el-option>
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="一级属性" prop="mainAttrId">
        <el-select v-model="dataForm.mainAttrId" placeholder="一级属性">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in attrList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="级联级别" prop="relationLevel">
        <el-select v-model="dataForm.relationLevel" placeholder="级练级别">
          <el-option label="全部" value=""></el-option>
          <el-option label="二级" value="0"></el-option>
          <el-option label="三级" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remarks" label="备注">
        <el-input
          v-model="dataForm.remarks"
          placeholder="备注"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="isOpen">
        <el-select v-model="dataForm.isOpen" placeholder="状态">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="0"></el-option>
          <el-option label="禁用" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dataForm.daterange"
          size="small"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" type="primary" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')" type="primary" size="small">清空</el-button>
      </el-form-item>
    </el-form>
    </el-card>
    <el-card style="margin-top:30px">
      <div slot="header" class="clearfix">
      <span>规则列表</span>
      <div style="float: right; padding: 3px 0">
        <el-button type="primary" @click="addOrUpdateHandle()" size="small">添加属性筛选规则</el-button>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="relationName"
        header-align="center"
        align="center"
        label="规则名称"
      >
      </el-table-column>
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="适用品牌"
      >
        <template slot-scope="scope">
          <div>{{scope.row.brandName ? scope.row.brandName : '通用'}}</div>
        </template>
      </el-table-column>
      
      <el-table-column
        prop="mainAttrName"
        header-align="center"
        align="center"
        label="一级属性"
      >
      </el-table-column>
      <el-table-column
        prop="relationLevel"
        header-align="center"
        align="center"
        label="级联级别"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.relationLevel == '0'">二级</div>
          <div v-if="scope.row.relationLevel == '1'">三级</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>

      <el-table-column
        prop="isOpen"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.isOpen == '0'">启用</div>
          <div v-if="scope.row.isOpen == '1'">禁用</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        width="250"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="setAssociation(scope.row.id,scope.row.brandId,scope.row.mainAttrName,scope.row.mainAttrId,scope.row.secondAttrId,scope.row.threeAttrId,scope.row.secondAttrName,scope.row.threeAttrName)">属性值关联</el-button>
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id,scope.row.brandId,scope.row.secondAttrName,scope.row.threeAttrName)">编辑</el-button>
          <el-button type="text" size="small" v-if="scope.row.isOpen == '0'" @click="setisOpen(scope.row)">禁用</el-button>
          <el-button type="text" size="small" v-if="scope.row.isOpen == '1'" @click="setisOpen(scope.row)">启用</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id,scope.row.brandId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-card>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" ></add-or-update>
    <attribute-value-association v-if="associationVisible" ref="association" @refreshDataList="getDataList"></attribute-value-association>
  </div>
</template>

<script>
import { list, updateState, update} from "@/api/modules/productfilterrule";
import { getproductAttrInfo,} from "@/api/modules/productattrvalue";
import { getBrand } from "@/api/modules/product";
import AddOrUpdate from "./add-or-update"
import AttributeValueAssociation from "./attribute-value-association"
export default {
  data() {
    return {
      dataForm: {
        relationName: "",
        brandId: "全部",
        relationLevel:'',
        mainAttrId:'',
        remarks: "",
        isOpen: '',
        daterange: [],
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      associationVisible: false,
      attrList: [],
      brandList: [],
    };
  },
  components: {
    AddOrUpdate,
    AttributeValueAssociation
  },
  created() {
    this.getDataList('1');
    this.getBrandList('1');
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.dataForm.daterange=[],
      this.attrList = [],
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    //获取品牌列表
    getBrandList() {
      let params = {};
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list;
        } else {
          this.brandList = [];
        }
      });
    },
    // 获取数据列表
    getDataList() {
      // console.log(this.dataForm.daterange)
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        relationName: this.dataForm.relationName,
        brandId: this.dataForm.brandId == '全部' ? null : this.dataForm.brandId,
        relationLevel: this.dataForm.relationLevel,
        mainAttrId: this.dataForm.mainAttrId,
        remarks: this.dataForm.remarks,
        isOpen: this.dataForm.isOpen,
        startTime: this.dataForm.daterange && this.dataForm.daterange.length > 0 ? this.dataForm.daterange[0]+' 00:00:00' : '',
        endTime: this.dataForm.daterange && this.dataForm.daterange.length > 0 ? this.dataForm.daterange[1]+' 23:59:59' : '',
      };
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 获取属性数据列表
    getAttrList() {
      this.dataForm.productAttrId = '';
      let params = {
        brandId : this.dataForm.brandId == null || this.dataForm.brandId == '0' || this.dataForm.brandId == ' ' ? '' : (this.dataForm.brandId == '全部' ? null : this.dataForm.brandId )
      };
      getproductAttrInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.attrList = data.list;
        } else {
          this.attrList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id,brandId,secondAttrName,threeAttrName) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id,brandId,secondAttrName,threeAttrName);
      });
    },

    //属性值关联
    setAssociation(id,brandId,name,mainAttrId,secondAttrId,threeAttrId,secondAttrName,threeAttrName){
      this.associationVisible = true;
      this.$nextTick(() => {
        this.$refs.association.init(id,brandId,name,mainAttrId,secondAttrId,threeAttrId,secondAttrName,threeAttrName);
      });
    },

     //启用关闭
    setisOpen(data){
      var param = JSON.parse(JSON.stringify(data));
      param.isOpen = param.isOpen == '1' ? '0' : '1'
      param.brandId = param.brandId == '0' || param.brandId == ' ' ? '' : param.brandId
      update(param).then(({ data }) => {
          if (data && data.code === 0) {
             this.$message({
              message: "操作成功",
              type: "success",
              duration: 2000,
              onClose: () => {
                this.getDataList();
              },
            });
          }else{
            this.$message.error(data.msg);
          }
      });
    },

    //删除
    deleteHandle(id,brandId) {
      var params = {
        delFlag:'1',
        id: id,
        brandId: brandId == '0' ? '' : brandId
      };
      this.$confirm(`是否删除该属性筛选规则?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        update(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>

<template>
    <div>
        <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="item-name">
                <div @click="setIsshow(item,index)">{{ item.name }}</div>
            </div>
            <div v-if="item.childList && item.attrValueId == id" class="childList-item">
                <list :list="item.childList"
                id=''
                @fatherMethod="fatherMethod"
                ></list>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'list',
    props: {
        list:{
            type:Array,
        },
        id:{
            type:String,
        }
    },
    data() {
        return {};
    },
    methods: {
        setIsshow(item,index) {
            this.$emit('fatherMethod',item,index);
        },
    },
};
</script>

<style lang="scss" scoped>
.list-item {
    .item-name{
        line-height: 25px;
        padding-left: 15px;
    }
}
</style>

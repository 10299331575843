<template>
    <el-dialog
        :title="'打印材料信息'"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="500px"
        @close="close()"
    >
        <div id="printarea" ref="print">
            <table border=1 width="100%" frame=void rules=none  v-for="(item,index) in materialList" :key="index">
                <tr>
                    <td style="width: 5%">
                        <div style="width: 70%;height: 240px;writing-mode: vertical-lr;margin-top: 10px;margin-left: 15px">
                            {{'  '+item.materialName}}
                        </div>
                    </td>
                    <td style="width:40%">
                        <img style="width: 250px;height:250px;margin-left: 10px" :src="item.qrUrl" />
                    </td>
                    <td style="width:40%">
                        <div style="width: 70%;height: 240px;writing-mode: vertical-lr;margin-top: 10px;font-size: 35px;text-align: center">
                           <strong>{{item.materialNo}}</strong>


                            <!--<span style="margin-left: -35px;font-size: 20px;">{{'NO:'+item.materialNo}}</span><br>-->
                            <!--<span style="margin-left: -35px;font-size: 20px;    display: flow-root;">{{''+item.materialName}}</span><br>-->
                        </div>
                    </td>
                </tr>   
            </table> 
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">取消</el-button>
            <el-button type="primary" @click="printSub()" size="small" :disabled="dataLoading">打印</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {infoList} from '@/api/modules/material'
    export default {
        data() {
            return {
                visible: false,
                dataLoading: false,
                showBtn: false,
                dataForm: {
                    ids:[],
                    materialNo: '',
                    materialName: '',
                    qrUrl: '',
                },
                materialList:{},
            };
        },
        methods: {
            close(){
                this.visible = false;
            },
            init(ids) {
                this.dataForm.ids = ids;
                this.visible = true
                this.$nextTick(() => {
                    this.dataForm.materialNo = '';
                    this.dataForm.materialName = '';
                    this.dataForm.qrUrl = '';
                    if (this.dataForm.ids) {
                        infoList(ids).then(({data}) => {
                            if (data && data.code === 0) {
                                this.materialList = data.materialList
                            }
                        })
                    }
                })
            },
            printSub() {
                //this.$print(this.$refs.print);
                //var oldHtml = document.body.innerHTML;
                document.body.innerHTML = document.getElementById("printarea").innerHTML;
                setTimeout(()=>{
                window.print();
                setTimeout("window.close();",0);
                window.location.reload();
                },1000)
            }
        }
    };
</script>
<style lang="scss" scoped>
    .productDirs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #606266;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 1px solid #d8d8d8;
        .left {
            margin-left: 30px;
        }
        .right {
            margin-left: 5px;
            margin-right: 30px;
        }
    }
    .fontNormal {
        font-weight: normal;
    }
</style>

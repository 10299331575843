import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/searchHistory/searchhistory/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/searchHistory/searchhistory/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/searchHistory/searchhistory/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/searchHistory/searchhistory/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/searchHistory/searchhistory/delete',
    method: 'post',
    data: params
  })
}

// 统计
export function findSearchCount (params) {
    return request({
        url: '/searchHistory/searchhistory/findSearchCount',
        method: 'get',
        params: params
    })
}

// 饼状图统计
export function findPieSearch (params) {
    return request({
        url: '/searchHistory/searchhistory/findPieSearch',
        method: 'get',
        params: params
    })
}

// 普通粉饼图
export function querySearchCommonPowder (params) {
    return request({
        url: '/searchHistory/searchhistory/querySearchCommonPowder',
        method: 'get',
        params: params
    })
}

// 金属粉饼图
export function querySearchMetalPowder (params) {
    return request({
        url: '/searchHistory/searchhistory/querySearchMetalPowder',
        method: 'get',
        params: params
    })
}

// 色系饼图
export function findColorSeries (params) {
    return request({
        url: '/searchHistory/searchhistory/findColorSeries',
        method: 'get',
        params: params
    })
}
// 打样员
export function findUserSearch (params) {
    return request({
        url: '/searchHistory/searchhistory/findUserSearch',
        method: 'get',
        params: params
    })
}
// 查找数量
export function findCount (params) {
    return request({
        url: '/searchHistory/searchhistory/findCount',
        method: 'get',
        params: params
    })
}

// 色系饼图
export function findProductAttrDir (params) {
  return request({
      url: '/searchHistory/searchhistory/findProductAttrDir',
      method: 'get',
      params: params
  })
}

// 色系饼图
export function findProductAttrValueDir (params) {
  return request({
      url: '/searchHistory/searchhistory/findProductAttrValueDir',
      method: 'get',
      params: params
  })
}



<template>
  <div class="app-container">
    <el-dialog
            :title="!dataForm.id ? '查看配方' : '查看配方'"
            :close-on-click-modal="false"
            :visible.sync="recipeListVisible"
            width="1500px">
    <el-table :data="dataList"
              border
              v-loading="dataListLoading"
              @selection-change="selectionChangeHandle"
              style="width: 100%;">
       <el-table-column
        prop="materialNo"
        header-align="center"
        align="center"
        label="材料编号">
      </el-table-column>
      <el-table-column
        prop="materialName"
        header-align="center"
        align="center"
        label="材料名称">
      </el-table-column>
      <el-table-column
        prop="value"
        header-align="center"
        align="center"
        label="计量值">
      </el-table-column>
      <el-table-column
        prop="units"
        header-align="center"
        align="center"
        label="单位">
      </el-table-column>
      <el-table-column
        prop="rate"
        header-align="center"
        align="center"
        label="计量百分比">
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/samplerecipe'
  import AddOrUpdate from './add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          recipeOrderId: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        recipeListVisible: false
      }
    },
    components: {
    },
    activated () {
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },

      init(id){
        this.dataForm.sampleProductId = id;
        this.recipeListVisible = true;
        this.getDataList()
      },

      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          sampleProductId: this.dataForm.sampleProductId
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      }
    }
  }
</script>

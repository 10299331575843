<template>
  <el-dialog
    title="私有化配置"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="品牌名称" prop="brandName">
        <el-input
          v-model="dataForm.brandName"
          placeholder="品牌名称"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="是否私有化" prop="isPrivatisation">
        <el-switch
          v-model="dataForm.isPrivatisation"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <div v-if="dataForm.isPrivatisation == 1">
        <el-form-item label="本地服务地址" prop="localServiceAddress">
          <el-input
            v-model="dataForm.localServiceAddress"
            placeholder="localhost:8080"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="页面地址" v-if="dataForm.localServiceAddress">
          <el-input
            :value="'https://' + dataForm.localServiceAddress"
            placeholder="localhost:8080"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="服务地址" v-if="dataForm.localServiceAddress">
          <el-input
            :value="'https://' + dataForm.localServiceAddress + '/api'"
            placeholder="localhost:8080"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="认证秘钥"
          prop="secretKey"
          v-if="dataForm.isPrivatisation == 1"
        >
          <el-input
            v-model="dataForm.secretKey"
            placeholder="认证秘钥"
            disabled
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { info, savePrivatisation } from "@/api/modules/brand";

export default {
  data() {
    var validateLocalServiceAddress = (rule, value, callback) => {
      if (this.dataForm.isPrivatisation == "1" && (!value || value == "")) {
        callback(new Error("本地服务地址不能为空"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: 0,
        brandName: "",
        isPrivatisation: "0",
        localServiceAddress: "",
        secretKey: "",
      },
      dataRule: {
        isPrivatisation: [
          { required: true, message: "是否本地化不能为空", trigger: "blur" },
        ],
        localServiceAddress: [
          { validator: validateLocalServiceAddress, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id;
      this.visible = true;
      this.$nextTick(() => {
        if (id) {
          info({ id: id }).then(({ data }) => {
            const brand = data.brand;
            this.dataForm.brandName = brand.brandName;
            this.dataForm.isPrivatisation = brand.isPrivatisation;
            this.dataForm.localServiceAddress = brand.localServiceAddress;
            this.dataForm.secretKey = brand.secretKey;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            id: this.dataForm.id,
            isPrivatisation: this.dataForm.isPrivatisation,
            localServiceAddress: this.dataForm.localServiceAddress,
          };
          savePrivatisation(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.dataLoading = false;
                },
              });
            } else {
              this.$message.error(data.msg);
              this.dataLoading = false;
            }
          });
        }
      });
    },
  },
};
</script>

<template>
    <div class="app-container">
        <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
            <!--<el-form-item prop="key">-->
            <!--<el-input v-model="dataForm.key" placeholder="参数名" size="small" clearable></el-input>-->
            <!--</el-form-item>-->
            <el-form-item>
                <!--<el-button @click="restPage()" size="small">筛选</el-button>-->
                <!--<el-button @click="reset('dataForm')"  size="small">清空</el-button>-->
                <el-button  type="primary" @click="addOrUpdateHandle()" size="small">添加备份</el-button>
                <!--<el-button v-if="isAuth('material:materialcategory:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>-->
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <!--<el-table-column-->
                    <!--type="selection"-->
                    <!--fixed="left"-->
                    <!--header-align="center"-->
                    <!--align="center"-->
                    <!--width="50">-->
            <!--</el-table-column>-->
            <el-table-column
                    prop="id"
                    header-align="center"
                    align="center"
                    label="备份id">
            </el-table-column>
            <el-table-column
                    prop="name"
                    header-align="center"
                    align="center"
                    label="备份名称">
            </el-table-column>
            <el-table-column
                    prop="type"
                    header-align="center"
                    align="center"
                    label="备份方式">
                <template slot-scope="scope">
                    <span v-if="scope.row.type == '1'">手动</span>
                    <span v-else>自动</span>
                </template>
            </el-table-column>
            <!--<el-table-column-->
                    <!--prop="name"-->
                    <!--header-align="center"-->
                    <!--align="center"-->
                    <!--label="备份频率">-->
            <!--</el-table-column>-->
            <!--<el-table-column-->
                    <!--prop="name"-->
                    <!--header-align="center"-->
                    <!--align="center"-->
                    <!--label="执行时间">-->
            <!--</el-table-column>-->
            <!--<el-table-column-->
                    <!--prop="name"-->
                    <!--header-align="center"-->
                    <!--align="center"-->
                    <!--label="上次执行时间">-->
            <!--</el-table-column>-->
            <el-table-column
                    prop="createDate"
                    header-align="center"
                    align="center"
                    label="创建时间">
            </el-table-column>
            <el-table-column
                    prop="state"
                    header-align="center"
                    align="center"
                    label="启用状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.state"
                               active-value="1"
                               inactive-value="0"
                    disabled="disabled">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
    import AddOrUpdate from './add-or-update'
    import { updatedbList,updatedbdel } from "@/api/sys";
    export default {
        data () {
            return {
                dataForm: {
                    key: '',
                    daterange:[]
                },
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false
            }
        },
        components: {
            AddOrUpdate
        },
        created () {
            this.getDataList()
        },
        methods: {
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            // 获取数据列表
            getDataList () {
                this.dataListLoading = true
                var params = {
                    page: this.pageIndex,
                    limit: this.pageSize,
                    key: this.dataForm.key
                }
                updatedbList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle (val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle (id) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id)
                })
            },
            // 删除
            deleteHandle (id) {
                var ids = id ? [id] : this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定对数据备份删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    updatedbdel(ids).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>


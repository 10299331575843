<template>
  <el-card class="box-card-component">
    <div slot="header">
      {{ title }}
    </div>
    <div class="main" v-if="title==='产品合计'">
      <div>
        <template v-for="(item,index) in proOverview">
          <span class="tri_right pro" :key="index" :style="{ width:(100/proOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
      <div>
        <template v-for="(item,index) in proList">
          <span class="ser-color" :key="index" :style="{ width:(100/proOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='产品新增'">
      <div>
        <template v-for="(item,index) in addOverview">
          <span class="tri_right ser" :key="index" :style="{ width:(100/addOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
      <div>
        <template v-for="(item,index) in addList">
          <span class="ser-color" :key="index" :style="{ width:(100/addOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='产品搜索'">
      <div>
        <template v-for="(item,index) in serOverview">
          <span class="tri_right ser" :key="index" :style="{ width:(100/serOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
      <div>
        <!-- <template v-for="(item,index) in serList">
          <span class="ser-color" :key="index" :style="{ width:(100/serOverview.length)+'%' }">{{item}}</span>
        </template> -->
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['searchCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['matchingCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['notMatchingCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['matchingRate'] : '0'}}</span>
        </template>
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['searchPersonCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser-color" :style="{ width:(100/serOverview.length)+'%' }">{{serList ? serList['perCount'] : '0'}}</span>
        </template>
      </div>
    </div>
  </el-card>
</template>

<script>
import { queryProduct, queryAddCount } from "@/api/modules/record"
import { nameAndRole } from '@/api/modules/product';
import { selHisOverview } from "@/api"

//首页--产品合计
const proOverview = ['产品总数', '生产产品数', '实验产品数', '过程产品数']
//首页--产品新增
const addOverview = ['今日新增', '昨日新增', '本月新增', '上月新增', '今年新增']
//首页--产品搜索
const serOverview = ['搜索次数', '匹配次数', '不匹配次数', '匹配率', '搜索人数', '人均次数']

export default {
  props: {
    title: {
      type: String,
      default: "11",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      proList: null,
      serList: null,
      addList: null,
      // sysOrder: 1,
      proOverview: ['产品总数', '生产产品数', '实验产品数', '过程产品数'],
      addOverview: ['今日新增', '昨日新增', '本月新增', '上月新增', '今年新增'],
      serOverview: ['搜索次数', '匹配次数', '不匹配数', '匹配比率', '搜索人数', '人均次数'],

      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024,
      },
    };
  },
  computed: {
  },
  mounted() {
    const title = this.title
    switch (title) {
      case '产品合计':
        this.queryProduct()
        break;
      case '产品新增':
        this.queryAddCount()
        break;
      case '产品搜索':
        this.selHisOverview()
        break;
      default:
        ''
    }
  },
  methods: {
    async nameAndRole() {
      const params = {}
      const { data } = await nameAndRole(params)
      if (data && data.code === 0 && data.vo.sysOrder === "1") {
        this.proOverview = ['产品总数', '生产产品数', '实验产品数']
        delete this.proList.processProduct
      }
    },
    //首页--产品合计
    async queryProduct() {
      const { data } = await queryProduct()
      if (data && data.code === 0) {
        this.proList = data.product
        this.nameAndRole()
      }
    },
    //首页--产品新增
    async queryAddCount() {
      const { data } = await queryAddCount()
      if (data && data.code === 0) {
        this.addList = data.data
      }
    },
    //首页--产品搜索
    async selHisOverview() {
      const { data } = await selHisOverview()
      if (data && data.code === 0) {
        this.serList = data.data
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  div {
    display: flex;
    font-size: 14px;
    color: #666666;
    &:nth-child(2) {
      font-weight: bold;
      font-size: 24px;
      color: #000;
      margin-top: 15px;
    }
    span {
      display: block;
      position: relative;
      &.dev-center {
        text-align: center;
      }
    }
    .tri_right {
      &::before {
        content: "";
        width: 0px;
        height: 0px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #666666;
        position: absolute;
        top: 4px;
        left: 75px;
      }
    }
    .pro:nth-child(1) {
      &::before {
        left: 60px;
      }
    }
    .pro-color:nth-child(4) {
      color: #ff9f24;
    }
    .ser {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        &::before {
          left: 60px;
        }
      }
     
    }
    .ser-color:nth-child(1) {
      color: #4ecb73;
    }
  }
}
</style>

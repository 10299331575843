import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/searchHistory/searchhistory/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/searchHistory/searchhistory/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/searchHistory/searchhistory/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/searchHistory/searchhistory/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/searchHistory/searchhistory/delete',
    method: 'post',
    data: params
  })
}

export function getValueId (params) {
    return request({
        url: '/searchHistory/searchhistory/getValueId',
        method: 'get',
        params: params
    })
}

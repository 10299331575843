<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="编码" prop="no">
        <el-input v-model="dataForm.no" placeholder="编码"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="类别" prop="type">
        <el-select v-model="dataForm.type" size="small" placeholder="请选择类别" >
          <el-option
                  v-for="item in  DictInfoList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--<el-form-item label="类型" prop="type">
        <el-input v-model="dataForm.type" placeholder="类型"  size="small"></el-input>
      </el-form-item>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,getDictInfo} from '@/api/modules/colorbookcategory'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
          DictInfoList:[],
        dataForm: {
          id: 0,
          no: '',
          name: '',
          type: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: ''
        },
        dataRule: {
          no: [
            { required: true, message: '编码不能为空', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '类型不能为空', trigger: 'blur' }
          ],
        }
      }
    },
      created(){
        this.getDictInfo();
      },
    methods: {
        getDictInfo(){
            let params = {};
            getDictInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.DictInfoList = data.list;
                } else {
                    this.DictInfoList = [];
                }
            });
        },
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.no = data.colorBookCategory.no
                this.dataForm.name = data.colorBookCategory.name
                this.dataForm.type = data.colorBookCategory.type
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'no': this.dataForm.no,
              'name': this.dataForm.name,
              'dictId': this.dataForm.type,
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm"  label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="产品id" prop="productId">
        <el-input v-model="dataForm.productId" placeholder="产品id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="性能组id" prop="performanceId">
        <el-input v-model="dataForm.performanceId" placeholder="性能组id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="膜厚" prop="thickness">
        <el-input v-model="dataForm.thickness" placeholder="膜厚"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="弯曲" prop="bend">
        <el-input v-model="dataForm.bend" placeholder="弯曲"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="冲击" prop="attack">
        <el-input v-model="dataForm.attack" placeholder="冲击"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="划格" prop="delimit">
        <el-input v-model="dataForm.delimit" placeholder="划格"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="杯突" prop="cupular">
        <el-input v-model="dataForm.cupular" placeholder="杯突"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="其他" prop="other">
        <el-input v-model="dataForm.other" placeholder="其他"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/productperformance'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          productId: '',
          performanceId: '',
          thickness: '',
          bend: '',
          attack: '',
          delimit: '',
          cupular: '',
          other: ''
        },
        dataRule: {
          productId: [
            { required: true, message: '产品id不能为空', trigger: 'blur' }
          ],
          performanceId: [
            { required: true, message: '性能组id不能为空', trigger: 'blur' }
          ],
          thickness: [
            { required: true, message: '膜厚不能为空', trigger: 'blur' }
          ],
          bend: [
            { required: true, message: '弯曲不能为空', trigger: 'blur' }
          ],
          attack: [
            { required: true, message: '冲击不能为空', trigger: 'blur' }
          ],
          delimit: [
            { required: true, message: '划格不能为空', trigger: 'blur' }
          ],
          cupular: [
            { required: true, message: '杯突不能为空', trigger: 'blur' }
          ],
          other: [
            { required: true, message: '其他不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.productId = data.productperformance.productId
                this.dataForm.performanceId = data.productperformance.performanceId
                this.dataForm.thickness = data.productperformance.thickness
                this.dataForm.bend = data.productperformance.bend
                this.dataForm.attack = data.productperformance.attack
                this.dataForm.delimit = data.productperformance.delimit
                this.dataForm.cupular = data.productperformance.cupular
                this.dataForm.other = data.productperformance.other
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'productId': this.dataForm.productId,
              'performanceId': this.dataForm.performanceId,
              'thickness': this.dataForm.thickness,
              'bend': this.dataForm.bend,
              'attack': this.dataForm.attack,
              'delimit': this.dataForm.delimit,
              'cupular': this.dataForm.cupular,
              'other': this.dataForm.other
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="type">
        <!--<el-input v-model="dataForm.type" placeholder="色号类别" size="small" clearable></el-input>-->
        <el-select v-model="dataForm.type" size="small" placeholder="请选择色号类别"
                  >
          <el-option
                  v-for="item in colorBookDropDownList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="colorName">
        <el-input v-model="dataForm.colorName" placeholder="色号名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="colorNo">
        <el-input v-model="dataForm.colorNo" placeholder="色号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
        <!-- <el-button  type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
        <el-button  type="primary" @click="exportExcelColorBook()"
                    size="small">导出数据
        </el-button>
        <el-button  type="primary" @click="exportExcel()"
                    size="small">导出EXCEL模板
        </el-button>
        <el-button type="success" @click="uploadHandle()" :disabled="dataList.length < 0">导入</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <!--<el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="id">
      </el-table-column>-->
      <el-table-column
        prop="colorNo"
        header-align="center"
        align="center"
        label="色号">
      </el-table-column>
      <el-table-column
        prop="colorName"
        header-align="center"
        align="center"
        label="色号名称">
      </el-table-column>
      <el-table-column
        prop="label"
        header-align="center"
        align="center"
        label="类型">
      </el-table-column>
      <!--<el-table-column
        prop="library"
        header-align="center"
        align="center"
        label="色库">
      </el-table-column>-->
      <el-table-column
        prop="l"
        header-align="center"
        align="center"
        label="LAB值">
        <template slot-scope="scope">
          <span>L:{{scope.row.l|numFilter}}</span><p></p>
          <span>A:{{scope.row.a|numFilter}}</span><p></p>
          <span>B:{{scope.row.b|numFilter}}</span>
        </template>
      </el-table-column>
      <!--<el-table-column-->
        <!--prop="a"-->
        <!--header-align="center"-->
        <!--align="center"-->
        <!--label="A值">-->
      <!--</el-table-column>-->
      <!--<el-table-column-->
        <!--prop="b"-->
        <!--header-align="center"-->
        <!--align="center"-->
        <!--label="B值">-->
      <!--</el-table-column>-->
      <!-- <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
  </div>
</template>

<script>
  import {list,del,colorBookDropDown} from '@/api/modules/colorbook'
  import AddOrUpdate from './add-or-update'
  import {getToken} from "@/utils/auth";
  import Upload from "./oss-upload";
  export default {
    data () {
      return {
        dataForm: {
            type: '',
            colorName: '',
            colorNo: '',
          daterange:[]
        },
        dataList: [],
          colorBookDropDownList:[],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
          uploadVisible : false,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
        Upload,
      AddOrUpdate
    },
    activated () {
      this.getDataList()
    },
      mounted(){
          this.getDataList()
          this.getDictList();
      },
      //写个过滤器，保留小数点后两位
      filters: {
          numFilter (value) {
              let realVal = ''
              if (!isNaN(value) && value!== '') {
                  // 截取当前数据到小数点后两位
                  realVal = parseFloat(value).toFixed(2)
              } else {
                  realVal = '--'
              }
              return realVal
          }
      },
    methods: {
        // 上传文件
        uploadHandle() {
            this.uploadVisible = true;
            this.$nextTick(() => {
                this.$refs.upload.init();
            });
        },
        //导出数据
        exportExcelColorBook(){
            var params = {
                type: this.dataForm.type,
                colorName: this.dataForm.colorName,
                colorNo: this.dataForm.colorNo
            }
            var url =`${process.env.VUE_APP_BASE_API}/member/colorbook/exportExcelColorBook?type=`+params.type+'&colorName='+params.colorName+'&colorNo='+params.colorNo+`&token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
        //下载模板
        exportExcel(){
            var params = {
                type: this.dataForm.type,
                colorName: this.dataForm.colorName,
                colorNo: this.dataForm.colorNo
            }
            var url =`${process.env.VUE_APP_BASE_API}/member/colorbook/exportExcel`+`?token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
          this.getDictList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
        //色号类别下拉
        getDictList() {
            colorBookDropDown().then(({data}) => {
                if (data && data.code === 0) {
                    this.colorBookDropDownList = data.colorBookDropDown
                } else {
                    this.colorBookDropDownList = []
                }
            })
        },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            type: this.dataForm.type,
            colorName: this.dataForm.colorName,
            colorNo: this.dataForm.colorNo
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定该条数据?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

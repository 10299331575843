<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="配方id" prop="recipeId">
        <el-input v-model="dataForm.recipeId" placeholder="配方id"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="材料名称" prop="materialName">
        <el-input v-model="dataForm.materialName" placeholder="材料名称"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="计量值" prop="value">
        <el-input v-model="dataForm.value" placeholder="计量值"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="units">
        <el-input v-model="dataForm.units" placeholder="单位"  size="small"></el-input>
      </el-form-item>
      <el-form-item label="计量设备" prop="dviceNo">
        <el-input v-model="dataForm.dviceNo" placeholder="计量设备"  size="small"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/recipematerial'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          recipeId: '',
          materialName: '',
          value: '',
          units: '',
          dviceNo: ''
        },
        dataRule: {
          recipeId: [
            { required: true, message: '配方id不能为空', trigger: 'blur' }
          ],
          materialName: [
            { required: true, message: '材料名称不能为空', trigger: 'blur' }
          ],
          value: [
            { required: true, message: '计量值不能为空', trigger: 'blur' }
          ],
          units: [
            { required: true, message: '单位不能为空', trigger: 'blur' }
          ],
          dviceNo: [
            { required: true, message: '计量设备不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.recipeId = data.recipematerial.recipeId
                this.dataForm.materialName = data.recipematerial.materialName
                this.dataForm.value = data.recipematerial.value
                this.dataForm.units = data.recipematerial.units
                this.dataForm.dviceNo = data.recipematerial.dviceNo
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'recipeId': this.dataForm.recipeId,
              'materialName': this.dataForm.materialName,
              'value': this.dataForm.value,
              'units': this.dataForm.units,
              'dviceNo': this.dataForm.dviceNo
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

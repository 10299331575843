import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/material/material/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/material/material/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/material/material/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/material/material/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/material/material/delete',
    method: 'post',
    data: params
  })
}
//获取材料类别名称下拉
export function MaterialCategoryInfo () {
    return request({
        url: '/material/material/materialCategoryInfo',
        method: 'get',
      //  params: params
    })
}

//获取所属工厂下拉
export function FactoryInfo () {
    return request({
        url: '/material/material/factoryInfo',
        method: 'get',
        //  params: params
    })
}
// 导入
export function importExcel(params) {
    return request({
        url: '/material/material/importExcel',
        method: 'post',
        data: params
    })
}

// 获取信息
export function infoList (params) {
    return request({
        url: '/material/material/infoList',
        method: 'post',
        data: params
    })
}

<template>
  <el-dialog
          :title="!dataForm.id ? '配置打样流程' : '配置打样流程'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="200px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="所属品牌" prop="brandId">
        <el-select v-model="dataForm.brandId"  size="small" placeholder="请选择品牌" style="width: 80%" disabled="disabled">
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支持新品打样" prop="supportNewProduct">
        <template>
          <el-radio v-model="dataForm.supportNewProduct" label="0">支持</el-radio>
          <el-radio v-model="dataForm.supportNewProduct" label="1">不支持</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="支持修改配方" prop="supportModifyRecipe">
        <template>
          <el-radio v-model="dataForm.supportModifyRecipe" label="0">支持</el-radio>
          <el-radio v-model="dataForm.supportModifyRecipe" label="1">不支持</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="支持样本样粉申请" prop="supportApplyProduct">
        <template>
          <el-radio v-model="dataForm.supportApplyProduct" label="0">支持</el-radio>
          <el-radio v-model="dataForm.supportApplyProduct" label="1">不支持</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="应用称量系统" prop="useWeighSystem">
        <template>
          <el-radio v-model="dataForm.useWeighSystem" label="0">使用</el-radio>
          <el-radio v-model="dataForm.useWeighSystem" label="1">不使用</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="支持自动结单" prop="autoComplete">
        <template>
          <el-radio v-model="dataForm.autoComplete" label="0">支持</el-radio>
          <el-radio v-model="dataForm.autoComplete" label="1">不支持</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="流程图">
        <img src="../../assets/sample.png" style="width:500px;height:500px">
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { sampleConfig, setBrandSample } from "@/api/modules/sampleconfig"
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          brandId: "",
          useWeighSystem: "0",
          autoComplete: "0",
          supportNewProduct: "0",
          supportModifyRecipe: "0",
          supportApplyProduct: "0"
        },
        brandList:[],
        dataRule: {
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur'}
          ],
          useWeighSystem: [
            { required: true, message: '设置是否应用称量系统', trigger: 'blur'}
          ],
          autoComplete: [
            { required: true, message: '设置是否自动结单', trigger: 'blur'}
          ],
          supportNewProduct: [
            { required: true, message: '设置是否支持新品打样流程', trigger: 'blur'}
          ],
          supportModifyRecipe: [
            { required: true, message: '设置是否支持修改配方流程', trigger: 'blur'}
          ],
          supportApplyProduct: [
            { required: true, message: '设置是否支持样本样粉申请流程', trigger: 'blur'}
          ]
        }
      }
    },
    methods: {
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      init(brandId) {
        this.dataForm.brandId = brandId;
        this.getBrandList();
        this.visible = true
        this.dataForm.useWeighSystem = "0";
        this.dataForm.autoComplete = "0";
        this.dataForm.supportNewProduct = "0";
        this.dataForm.supportModifyRecipe = "0";
        this.dataForm.supportApplyProduct = "0";
        this.$nextTick(() => {
          if (this.dataForm.brandId) {
            var params = {
              brandId: this.dataForm.brandId,
            };
            sampleConfig(params).then(({data}) => {
              if (data && data.code === 0 && data.sampleConfig) {
                this.dataForm.id = data.sampleConfig.id;
                this.dataForm.brandId = data.sampleConfig.brandId;
                this.dataForm.useWeighSystem = data.sampleConfig.useWeighSystem;
                this.dataForm.autoComplete = data.sampleConfig.autoComplete;
                this.dataForm.supportNewProduct = data.sampleConfig.supportNewProduct;
                this.dataForm.supportModifyRecipe = data.sampleConfig.supportModifyRecipe;
                this.dataForm.supportApplyProduct = data.sampleConfig.supportApplyProduct;
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              id: this.dataForm.id || undefined,
              brandId: this.dataForm.brandId,
              useWeighSystem: this.dataForm.useWeighSystem,
              autoComplete: this.dataForm.autoComplete,
              supportNewProduct: this.dataForm.supportNewProduct,
              supportModifyRecipe: this.dataForm.supportModifyRecipe,
              supportApplyProduct: this.dataForm.supportApplyProduct
            }
            setBrandSample(params).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

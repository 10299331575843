<template>
  <div class="app-container">
        <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="sampleOrderNo">
        订单编号
        <el-input v-model="dataForm.sampleOrderNo" placeholder="订单编号" size="small" clearable
                  style="width: 60%" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="sampleNo">
        样品编号
        <el-input v-model="dataForm.sampleNo" placeholder="样品编号" size="small" clearable
                  style="width: 60%" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        prop="sampleNo"
        header-align="center"
        align="center"
        label="样品编号">
      </el-table-column>
      <el-table-column
        prop="sampleOrderNo"
        header-align="center"
        align="center"
        label="订单编号">
      </el-table-column>
      <!--<el-table-column
        prop="picUrl"
        header-align="center"
        align="center"
        label="样品色图">
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70"/>
        </template>
      </el-table-column>-->
      <!--<el-table-column
        prop="l"
        header-align="center"
        align="center"
        label="样品L值">
      </el-table-column>
      <el-table-column
        prop="a"
        header-align="center"
        align="center"
        label="样品A值">
      </el-table-column>
      <el-table-column
        prop="b"
        header-align="center"
        align="center"
        label="样品B值">
      </el-table-column>-->
      <!--<el-table-column
        prop="ralColorNo"
        header-align="center"
        align="center"
        label="样品RAL色号">
      </el-table-column>-->
      <el-table-column
        prop="glossNameS"
        header-align="center"
        align="center"
        label="样品光泽">
      </el-table-column>
      <!--<el-table-column
        prop="glossValue"
        header-align="center"
        align="center"
        label="样品光泽值">
      </el-table-column>-->
      <el-table-column
        prop="surfaceNameS"
        header-align="center"
        align="center"
        label="样品表面">
      </el-table-column>
      <el-table-column
              prop="sampleType"
              header-align="center"
              align="center"
              label="打样种类">
      </el-table-column>
      <el-table-column
              prop="cartegryNameS"
              header-align="center"
              align="center"
              label="产品种类">
      </el-table-column>
      <el-table-column
              prop="dosage"
              header-align="center"
              align="center"
              label="预计用量">
      </el-table-column>
      <el-table-column
              prop="price"
              header-align="center"
              align="center"
              label="预计价格">
      </el-table-column>
      <el-table-column
              prop="materialSum"
              header-align="center"
              align="center"
              label="样粉数量">
      </el-table-column>
      <el-table-column
              prop="modelSum"
              header-align="center"
              align="center"
              label="样板数量">
      </el-table-column>
      <el-table-column
              prop="thickness"
              header-align="center"
              align="center"
              label="膜厚">
      </el-table-column>
      <el-table-column
              prop="deliverDate"
              header-align="center"
              align="center"
              label="交付日期">
      </el-table-column>
      <el-table-column
              prop="allocationDate"
              header-align="center"
              align="center"
              label="分配日期">
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="打样员">
      </el-table-column>
      <el-table-column
        prop="laboratory"
        header-align="center"
        align="center"
        label="实验室名称">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="打样状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">打样中</span>
          <span v-if="scope.row.state=='1'">待验证</span>
          <span v-if="scope.row.state=='2'">待结单</span>
          <span v-if="scope.row.state=='3'">已结单</span>
          <span v-if="scope.row.state=='4'">作废</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="detail(scope.row.sampleOrderId)">订单详情</el-button>
          <el-button v-if="(scope.row.state!='2')" type="text" size="small" style="color: green" @click="saveNewHandle(scope.row.id)">调色</el-button>
          <!-- <el-button v-if="(scope.row.state=='1')" type="text" size="small" style="color: green" @click="addOrUpdateHandle(scope.row.id)">验证</el-button>
          <el-button v-if="(scope.row.state=='2')" type="text" size="small" style="color: blue" @click="complateHandle(scope.row.id)">结单</el-button> -->
          <el-button type="text" size="small" @click="recipeListHandle(scope.row.id)">查看配方</el-button>
          <el-button v-if="(scope.row.state!='3' && scope.row.state!='4' )" type="text" size="small" @click="deleteHandle(scope.row.id)">作废</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <productupdate v-if="productupdateVisible" ref="productupdate" @refreshDataList="getDataList"></productupdate>
    <recipeList v-if="recipeListVisible" ref="recipeList" @refreshDataList="getDataList"></recipeList>
    <detail v-if="detailVisible" ref="detail" @refreshDataList="getDataList"></detail>
  </div>
</template>

<script>
  import {listuser,del,choose,saveNewSample} from '@/api/modules/sampleproduct'
  import productupdate from './productupdate'
  import recipeList from './recipeList'
  import detail from './detail'
  export default {
    data () {
      return {
        dataForm: {
          sampleNo: '',
          sampleOrderNo: '',
          daterange:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        recipeListVisible: false,
        detailVisible: false,
        productupdate: false,
        productupdateVisible: false
      }
    },
    components: {
      productupdate,
      detail,
      recipeList
    },
    created(){
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },

      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          sampleNo: this.dataForm.sampleNo,
          sampleOrderNo: this.dataForm.sampleOrderNo
        }
        listuser(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // // 新增 / 修改
      // addOrUpdateHandle (id) {
      //   this.addOrUpdateVisible = true
      //   this.$nextTick(() => {
      //     this.$refs.addOrUpdate.init(id)
      //   })
      // },
      //配方
      recipeListHandle(id) {
        this.recipeListVisible = true
        this.$nextTick(() => {
          this.$refs.recipeList.init(id)
        })
      },
      detail (id) {
        this.detailVisible = true
        this.$nextTick(() => {
          this.$refs.detail.init(id)
        })
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.productupdateVisible = true
        this.$nextTick(() => {
          this.$refs.productupdate.init(id)
        })
      },
      // 调色
      saveNewHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对当前样品调色?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {"id": ids[0]}

          saveNewSample(param).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      // 结单
      complateHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定结单当前样品?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {"id": ids[0]}

          choose(param).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      // 作废
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定当前打样作废?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {"id": ids[0]}

          del(param).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

<template>
    <el-dialog
            :title="'已授权列表'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-divider></el-divider>
        <el-form :model="dataForm"  size="small" ref="dataForm"
                 v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >

            <div style="border-bottom: 1px solid #cfcfcf;margin-bottom: 20px;" >
                <span class="one1">设备编号:{{dataForm.deviceNo}}</span>
                <span class="one1">所在地:{{dataForm.factoryName}}<el-divider direction="vertical"></el-divider>{{dataForm.laboratory}}</span>
                <span class="one1">负责人:{{dataForm.leadMan}}</span>
            </div>
            <el-table
                    :data="dataForm.dataList"
                    border
                    size="small"
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column
                        prop="name"
                        header-align="center"
                        align="center"
                        label="用户名">
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        header-align="center"
                        align="center"
                        label="联系方式">
                </el-table-column>
                <el-table-column
                        prop="roleName"
                        header-align="center"
                        align="center"
                        label="用户类型">
                </el-table-column>
                <el-table-column
                        prop="enterprise"
                        header-align="center"
                        align="center"
                        label="所属企业">
                </el-table-column>
                <el-table-column
                        prop="state"
                        header-align="center"
                        align="center"
                        label="授权">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state=='0'">已启用</span>
                        <span v-if="scope.row.state=='1'">已停用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        header-align="center"
                        align="center"
                        label="授权期限"
                        >
                    <template slot-scope="scope" >
                        <p><span  >开始：{{scope.row.startDate}}</span></p>
                        <span>结束：{{scope.row.endDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                            <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">撤销授权</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    size="small"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="dataForm.pageIndex"
                    :page-sizes="[10,5]"
                    :page-size="dataForm.pageSize"
                    :total="dataForm.totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-form>
        <span slot="footer" class="dialog-footer">
    </span>
    </el-dialog>
</template>

<script>
    import {device,list,delDeviceUser} from '@/api/modules/deviceuser'
    export default {
        data () {
            return {
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    deviceNo: '',
                    factoryName: '',
                    solTime: '',
                    laboratory: '',
                    leadMan: '',
                    dataList: [],
                    pageIndex: 1,
                    pageSize: 10,
                    totalPage: 0,
                    totalCount:0,
                },
                dataListLoading: false,
            }
        },
        methods: {
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            init (id) {
                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        device({id:this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.deviceNo = data.deviceIn.deviceNo
                                this.dataForm.factoryName = data.deviceIn.factoryName
                                this.dataForm.laboratory = data.deviceIn.laboratory
                                this.dataForm.leadMan = data.deviceIn.leadMan


                            }
                        })
                    }
                    this.getDataList (id);
                })
            },
            //获取数据列表
            getDataList (id) {
                if (this.dataForm.id) {
                    let params = {
                        id: this.dataForm.id,
                        page: this.dataForm.pageIndex,
                        limit: this.dataForm.pageSize,
                    }
                    list(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.dataForm.dataList = data.page.list
                            this.dataForm.totalCount = data.page.totalCount
                        } else {
                            this.dataForm.dataList = []
                            this.dataForm.totalCount  = 0
                        }
                        this.dataListLoading = false
                    })
                }
            },
            // 每页数
            sizeChangeHandle (val) {
                this.dataForm.pageSize = val
                this.dataForm.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.dataForm.pageIndex = val
                this.getDataList()
            },
            //撤销授权
            deleteHandle (id) {
                this.$confirm(`是否确认撤销授权'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delDeviceUser({id: id}).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>
<style scoped>
    .one1{
        display: inline-block;
        margin-right: 35px;
        padding: 0 0 10px 0;
    }
</style>
<template>
    <el-dialog
            :title="!dataForm.id ? '发货单' : '发货单'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            append-to-body
    >
        <el-form :model="dataForm" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="200px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
            <div>
                <!--<p>产品编号：{{dataForm.productNo}}</p>-->
                <p>样板数量：{{dataForm.modelSum}}</p>
                <p>样粉数量：{{dataForm.materialSum}} kg</p>
                <p>样板收货人：{{dataForm.personalNameB}}</p>
                <p>样板收货地址：{{dataForm.addressB}}</p>
                <p>样粉收货人：{{dataForm.personalName}}</p>
                <p>样粉收货地址：{{dataForm.address}}</p>
                <el-divider></el-divider>
            </div>



            <el-form-item label="单发样粉" prop="delFlag">
            <el-radio v-model="dataForm.delFlag" label="1">是</el-radio>
            <el-radio v-model="dataForm.delFlag" label="0">否</el-radio>
            </el-form-item>

            <el-form-item label="样粉物流公司" prop="logisticsName">
                <el-input v-model="dataForm.logisticsName" placeholder="" size="small"
                          style="width: 100%" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="样粉物流公司单号" prop="logisticsOrderno">
                <el-input v-model="dataForm.logisticsOrderno" placeholder="" size="small"
                          style="width: 100%" maxlength="30"></el-input>
            </el-form-item>

            <el-form-item label="样板物流公司" prop="logisticsNameB">
                <el-input v-model="dataForm.logisticsNameB" placeholder="" size="small"
                          style="width: 100%" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="样板物流公司单号" prop="logisticsOrdernoB">
                <el-input v-model="dataForm.logisticsOrdernoB" placeholder="" size="small"
                          style="width: 100%" maxlength="30"></el-input>
            </el-form-item>


        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">发货</el-button>
    </span>


    </el-dialog>
</template>

<script>
    import {getToken} from "@/utils/auth";
    
    export default {
        data() {
            return {
                action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
                visible: false,
                dataLoading: false,
                loading: false,
                timer: null, //首先我在data函数里面进行定义定时器名称：
                timerNum: 0, // 设置定时器时间
                device: {
                    deviceId: ''
                },
                dataForm: {
                    id: 0,
                    sampleOrderNo: '',
                    modelSum:'',
                    materialSum:'',
                    delFlag:'1',
                    address:'',//样粉地址
                    personalName:'',//样粉接收人
                    addressB:'',//样板地址
                    personalNameB:'',//样板接收人

                    logisticsOrderno:'',//样粉物流单号
                    logisticsName:'',
                    logisticsOrdernoB:'',//样板物流单号
                    logisticsNameB:'',
                },
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
            this.timer = null
        },
        methods: {
            init(sampleOrderNo) {
                this.dataForm.sampleOrderNo = sampleOrderNo || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.sampleOrderNo) {
                        orderLogisticsInfo({sampleOrderNo: this.dataForm.sampleOrderNo}).then(({data}) => {
                            if(data.sampleOrder!=undefined && data.sampleOrder !=null && data.sampleOrder!=null){
                                this.dataForm.modelSum=data.sampleOrder.modelSum;
                                this.dataForm.materialSum=data.sampleOrder.materialSum;
                            }
                            for( var x in data.sysSampleLogistics){
                                if(data.sysSampleLogistics[x].type=="1"){
                                    this.dataForm.address=data.sysSampleLogistics[x].address;
                                    this.dataForm.personalName=data.sysSampleLogistics[x].personalName;
                                    this.dataForm.logisticsOrderno=data.sysSampleLogistics[x].logisticsOrderno;
                                    this.dataForm.logisticsName=data.sysSampleLogistics[x].logisticsName;
                                }else if(data.sysSampleLogistics[x].type=="0"){
                                    this.dataForm.addressB=data.sysSampleLogistics[x].address;
                                    this.dataForm.personalNameB=data.sysSampleLogistics[x].personalName;
                                    this.dataForm.logisticsOrdernoB=data.sysSampleLogistics[x].logisticsOrderno;
                                    this.dataForm.logisticsNameB=data.sysSampleLogistics[x].logisticsName;
                                }
                            }
                        })
                    }
                })
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            "orderNo":this.dataForm.sampleOrderNo,
                            //'orderNo':"20200821105258000000030",
                            'logisticsOrderno': this.dataForm.logisticsOrderno,
                            'logisticsName': this.dataForm.logisticsName,
                            'logisticsOrdernoB': this.dataForm.logisticsOrdernoB,
                            'logisticsNameB': this.dataForm.logisticsNameB,
                        }
                        syncLogistics(params).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            },


        }
    }
</script>
<style lang="scss">
    .avatar-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            margin-left: 20px;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
    }
</style>
<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 178px;
        text-align: center;
    }

    .ziti {
        font-size: 12px;
        margin: 0px;
        line-height: 25px;
    }

    .avatar {
        width: 178px;
        max-height: 178px;
        display: block;
    }

    .productLAB {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        margin-left: -50px;
        li {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 10px;
            .title {
                display: inline-block;
                width: 100px;
                text-align: center;
                color: #606266;
                font-size: 700;
            }
        }

    }

    .w100 {
        width: 100px;
    }

    .w150 {
        width: 119px;
    }

    .mr10 {
        margin-right: 5px;
    }

    .collapse-title {
        flex: 1 0 90%;
        color: #4AB7BD;
        font-weight: bolder;
        text-align: center;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 18px;
    }
</style>

<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="类别" prop="type">
        <template>
          <el-select v-model="dataForm.type" placeholder="请选择">
            <el-option
                    v-for="item in types"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </template>
<!--        <el-input v-model="dataForm.type" placeholder="类别"  size="small"></el-input>-->
      </el-form-item>
      <el-form-item label="名称" prop="label">
        <el-input v-model="dataForm.label" placeholder="名称"  size="small" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="值" prop="value">
        <el-input v-model="dataForm.value" placeholder="值"  size="small" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="显示顺序" prop="solTime">
        <el-input v-model="dataForm.sort" placeholder="显示顺序"  size="small" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="启动状态">
        <template>
          <el-radio v-model="dataForm.state" label="0">启用</el-radio>
          <el-radio v-model="dataForm.state" label="1">关闭</el-radio>
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/dict'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        types: [{
          value: '0',
          label: '设备型号'
        },{
          value: '1',
          label: '其他性能'
        },{
          value: '2',
          label: '产品表面'
        },{
          value: '3',
          label: '产品光泽'
        }],
        dataForm: {
          id: 0,
          value:'',
          label: '',
          sort: '',
          state: '',
          type:''
        },
        dataRule: {
          label: [
             { required: true, message: '字典名称不能为空', trigger: 'blur' }
           ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.type = data.dict.type
                this.dataForm.label = data.dict.label
                this.dataForm.value =data.dict.value
                this.dataForm.sort = data.dict.sort
                this.dataForm.state = data.dict.state
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'type': this.dataForm.type,
              'label': this.dataForm.label,
              'value':this.dataForm.value,
              'sort': this.dataForm.sort,
              'state': this.dataForm.state,
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

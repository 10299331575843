<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="所属品牌" prop="brandId">
        <el-select v-model="dataForm.brandId"  size="small" placeholder="请选择品牌" style="width: 80%">
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="客户名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="客户等级">
        <el-select v-model="dataForm.level" size="small" placeholder="请选择客户等级" style="width: 80%">
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
          <el-option label="D" value="D"></el-option>
          <el-option label="E" value="E"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人" prop="contactsName">
        <el-input v-model="dataForm.contactsName" placeholder="联系人"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactsPhone">
        <el-input v-model="dataForm.contactsPhone" placeholder="联系电话"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="收件地址" prop="contactsAddress">
        <el-input v-model="dataForm.contactsAddress" placeholder="收件地址"  size="small" style="width: 80%"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/customer'
  import {getBrand} from '@/api/modules/product'

  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          name: '',
          level: '',
          contactsName: '',
          contactsPhone: '',
          contactsAddress: '',
          brandId: ''
        },
        brandList:[],
        dataRule: {
          name: [
            { required: true, message: '客户名称不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '所属品牌不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      //获取品牌列表
      getBrandList() {
        let params = {}
        getBrand(params).then(({data}) => {
          if (data && data.code === 0) {
            this.brandList = data.list
          } else {
            this.brandList = []
          }
        })
      },
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.dataForm.brandId = null
        this.getBrandList();
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.customer.name
                this.dataForm.level = data.customer.level
                this.dataForm.contactsName = data.customer.contactsName
                this.dataForm.contactsPhone = data.customer.contactsPhone
                this.dataForm.contactsAddress = data.customer.contactsAddress
                this.dataForm.brandId = data.customer.brandId
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'level': this.dataForm.level,
              'contactsName': this.dataForm.contactsName,
              'contactsPhone': this.dataForm.contactsPhone,
              'contactsAddress': this.dataForm.contactsAddress,
              'brandId': this.dataForm.brandId,
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>

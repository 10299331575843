import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/factory/factory/list',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/factory/factory/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/factory/factory/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/factory/factory/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/factory/factory/delete',
    method: 'post',
    data: params
  })
}
//品牌下拉列表
export function brandList (params) {
  return request({
    url: '/factory/factory/brandList',
    method: 'post',
    data: params
  })
}

//品牌下拉列表
export function updateFactoryState (data) {
  return request({
    url: '/factory/factory/state',
    method: 'post',
    data: data
  })
}

//品牌下拉列表
export function factoryList () {
  return request({
    url: '/factory/factory/selectFactoryList',
    method: 'get'
  })
}

//品牌下拉列表
export function getDataFactory (params) {
  return request({
    url: '/factory/factory/getDataFactory',
    method: 'get',
    params: params
  })
}





<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
// import "echarts-gl";
// import echarts from "echarts";
// require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";
import {findProductAttrValueDir} from '@/api/modules/record'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "360px",
    },
    chartData: {
      type: Array,
      default: () => []
    },
    chartIsLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    // var NumberDiv =  (arg1,arg2,digit) => {
    //   var t1=0,t2=0,r1,r2;
    //   try{t1=arg1.toString().split(".")[1].length}catch(e){}
    //   try{t2=arg2.toString().split(".")[1].length}catch(e){}
    //   r1=Number(arg1.toString().replace(".",""))
    //   r2=Number(arg2.toString().replace(".",""))
    //   //获取小数点后的计算值
    //   var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
    //   var result2=result.split(".")[1];
    //   result2=result2.substring(0,digit>result2.length?result2.length:digit);
  
    //   return Number(result.split(".")[0]+"."+result2);
    // },
    return {
      chart: null,
    };
  },
  mounted() {
    if (this.chartIsLoad) {
      //this.getProductAttrValueDir();
    } else {
      this.initChart(this.chartData);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart(data) {
      this.chart = echarts.init(this.$el, "macarons");
      var allPie = data;
      let options = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                      type: 'scroll',
                      orient: 'vertical',
                      right: 10,
                      top: 20,
                      bottom: 20,
                      data: data.map((item) => item.name), 
                      width: "100",
                      itemWidth: 10, // 图形宽度。
                      itemHeight: 10, // 图形高度。
                      itemGap: 10, // 间隔
                      // textStyle: {
                      //   color: "#000",
                      // },
                      // formatter: function (name) {
                      //   console.log(name);
                      //   let html = "";
                      //   var total = 0;
                      //   var tarValue;
                      //   for (var i = 0; i < data.length; i++) {
                      //     total += data[i].value;
                      //     if (data[i].name == name) {
                      //       tarValue = data[i].value;
                      //     }
                      //   }
                      //   for (var i = 0, l = data.length; i < l; i++) {
                      //     if (data[i].name === name) {
                      //         html += name + "  |  " + "{ts|" + data[i].value +"}";
                      //       }
                      //     }
                      //     return html;
                      // },
                      formatter: function(name) {
                        var total = 0;
                        var tarValue;
                        for (var i = 0; i < data.length; i++) {
                          total += data[i].value;
                          if (data[i].name == name) {
                            tarValue = data[i].value;
                          }
                        }
                        var v = tarValue;
                        var p = Math.round(((tarValue / total) * 100));
                        return `${name}｜ ${v}(${p}%)`;
                      },
                      textStyle: {
                          color: '#000',
                          rich: {
                            ts: {
                              color: '#999'
                            }
                          }
                      }
                    },
                    series: [
                        {
                            name: "",
                            type: 'pie',
                            radius: ['20%', '60%'],
                            center: ['35%', '50%'],
                            label: {
                                //alignTo: 'edge',
                                formatter: '{b}',
                                minMargin: 5,
                                edgeDistance: 40,
                                lineHeight: 15
                            },
                            itemStyle: {
                                borderRadius: 5
                            },
                            labelLine: {
                              length: 20,
                            },
                            data: allPie
                        }
                    ]
      };

      this.chart.setOption(options);
      this.chart.resize();
    },

    async getProductAttrValueDir(productAttr) {
      const params = {
        productAttr: productAttr,
        'type': "-1"
      }
      // console.log("params值",JSON.stringify(params));
      const { data } = await findProductAttrValueDir(params);
      if (data && data.code === 0) {
        this.$nextTick(() => {
          console.log("value值",JSON.stringify(data.colorSeries));
          this.initChart(data.colorSeries);
        });
        this.$emit('piechartemit',data.colorSeries)
      }
    }
  },
};
</script>

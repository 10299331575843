import request from '@/utils/request'

// 首页产品
export function queryProduct (params) {
    return request({
        url: '/product/product/queryProduct',
        method: 'get',
        params: params
    })
}

// 首页搜索产品统计
export function querySearchStatistics (params) {
    return request({
        url: '/searchHistory/searchhistory/querySearchStatistics',
        method: 'get',
        params: params
    })
}

// 首页实验室打样员
export function querySampleUserNum (params) {
    return request({
        url: '/sample/sampleproduct/querySampleUserNum',
        method: 'get',
        params: params
    })
}

// 材料使用情况
export function queryMaterialNum (params) {
    return request({
        url: '/material/material/queryMaterialNum',
        method: 'get',
        params: params
    })
}


// 订单趋势
export function queryOrderSampleType (params) {
    return request({
        url: '/sample/sampleorder/queryOrderSampleType',
        method: 'get',
        params: params
    })
}


// 订单趋势
export function queryOrderTrendFactory (params) {
    return request({
        url: '/sample/sampleorder/queryOrderTrendFactory',
        method: 'get',
        params: params
    })
}

// 首页打样订单
export function getCountSample (params) {
    return request({
        url: '/sample/sampleorder/getCountSample',
        method: 'get',
        params: params
    })
}
// 品牌打样订单
export function getSampleBrand (params) {
    return request({
        url: '/sample/sampleorder/getSampleBrand',
        method: 'get',
        params: params
    })
}


// 工厂打样订单
export function getSampleFactory (params) {
    return request({
        url: '/sample/sampleorder/getSampleFactory',
        method: 'get',
        params: params
    })
}
// 首页产品每日新增统计数据（三十日和七日）
export function queryProductNum (params) {
    return request({
        url: '/product/product/queryProductNum',
        method: 'get',
        params: params
    })
}
